export default {
    title: {
        text: '参与次数',
        top: 0,
        left: 0,
        textStyle: {
            fontSize: 12,
            color: '#6791FF'
        }
    },
    grid: {
        top: 48,
        left: 10,
        right: 20,
        bottom: 0,
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: ['放生', '斋僧', '建寺'],
        axisTick: {
            show: false,
        },
        axisLabel: {
            margin: 12,
            fontSize: 16,
            color: '#fff',
            lineHeight: 24
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(0, 245, 255, 0.6)'
            }
        }
    },
    yAxis: {
        type: 'value',
        minInterval: 1,
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 12,
            color: '#6791FF'
        },
        axisLine: {
            show: false,
        },
        splitLine: {
            lineStyle: {
                type: 'dashed',
                color: 'rgba(104, 134, 219, 0.5)'
            }
        }
    },
    series: [
        {
            data: [820, 932, 901],
            name: '参与次数',
            type: 'bar',
            barWidth: 50,
            barGap: '-100%',
            silent: true,
            itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#007EC8' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#010C62' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            }
        },
        {
            data: [820, 932, 901],
            type: 'bar',
            barWidth: 50,
            z: 2,
            silent: true,
            itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [{
                        offset: 0, color: 'rgba(1, 119, 193, 0)' // 0% 处的颜色
                    }, {
                        offset: 0.49, color: 'rgba(1, 119, 193, 0)' // 0% 处的颜色
                    }, {
                        offset: 0.49, color: '#0177C1'
                    }, {
                        offset: 0.51, color: '#0177C1'
                    }, {
                        offset: 0.51, color: 'rgba(1, 119, 193, 0)'
                    }, {
                        offset: 1, color: 'rgba(1, 119, 193, 0)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            }
        },
        {
            type: "pictorialBar",
            symbolPosition: "end",
            data: [820, 932, 901],
            symbol: "diamond",
            symbolOffset: ["0%", "-50%"],
            symbolSize: [50, 20],
            z: 3,
            itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#017CC7' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#6CD2FA' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            }
        }
    ]
}
