export const chartLoadingOpt = {
    text: '',
    color: '#75FBFD',
    textColor: '#fff',
    maskColor: 'rgba(255, 255, 255, 0)',
    zlevel: 0,

    // 字体大小。从 `v4.8.0` 开始支持。
    fontSize: 18,
    // 是否显示旋转动画（spinner）。从 `v4.8.0` 开始支持。
    showSpinner: true,
    // 旋转动画（spinner）的半径。从 `v4.8.0` 开始支持。
    spinnerRadius: 16,
    // 旋转动画（spinner）的线宽。从 `v4.8.0` 开始支持。
    lineWidth: 2,
    // 字体粗细。从 `v5.0.1` 开始支持。
    fontWeight: 'normal',
    // 字体风格。从 `v5.0.1` 开始支持。
    fontStyle: 'normal',
    // 字体系列。从 `v5.0.1` 开始支持。
    fontFamily: 'sans-serif'
}
