import {getDoubleText} from "../common/js/tool";

export const mapPoint = [
    {name: '西线工程区', height: 57, top: 62, left: 172, video: ""},
    {name: '鼓楼', height: 40, top: 253, left: 166, video: ""},
    {name: '前门出口', height: 57, top: 248, left: 225, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000009%2468/substream/1.m3u8"},
    {name: '五百罗汉堂', height: 57, top: 130, left: 261, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2427/substream/1.m3u8"},
    {name: '月恒堂', height: 51, top: 96, left: 334, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000007%2488/substream/1.m3u8"},
    {name: '天王殿', height: 51, top: 244, left: 326, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%243/substream/2.m3u8"},
    {name: '大雄宝殿', height: 57, top: 157, left: 407, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000070%2464/substream/1.m3u8"},
    {name: '前门进口', height: 57, top: 298, left: 387, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%244/substream/1.m3u8"},
    {name: '禅堂', height: 40, top: 4, left: 449, video: ""},
    {name: '钟楼', height: 40, top: 344, left: 455, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000009%2445/substream/1.m3u8"},
    {name: '药师殿', height: 51, top: 105, left: 465, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2418/substream/1.m3u8"},
    {name: '直指堂', height: 51, top: 60, left: 509, video: ""},
    {name: '云中轩', height: 51, top: 350, left: 519, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000007%2453/substream/1.m3u8"},
    {name: '华严阁', height: 51, top: 274, left: 528, video: ""},
    {name: '济公殿', height: 51, top: 126, left: 550, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2415/substream/1.m3u8"},
    {name: '联灯阁', height: 51, top: 225, left: 557, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000009%2435/substream/1.m3u8"},
    {name: '华严殿', height: 51, top: 1, left: 564, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000070%2416/substream/1.m3u8"},
    {name: '大悲楼', height: 51, top: 181, left: 585, video: ""},
    {name: '方丈寮', height: 51, top: 113, left: 631},
    {name: '消防队', height: 51, top: 306, left: 712, video: "https://zh.jk.lingyinsi.com/live/cameraid/1000009%2443/substream/1.m3u8"},
]

export const videoList = [
    {
        name: "售票处外厅1",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%240/substream/1.m3u8",
    },
    {
        name: "售票处外厅2",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%241/substream/1.m3u8",
    },
    {
        name: "入口大厅1",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2438/substream/1.m3u8",
    },
    {
        name: "入口大厅2",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2439/substream/1.m3u8",
    },
    {
        name: "入口快球",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%244/substream/1.m3u8",
    },
    {
        name: "入口前门",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%242/substream/1.m3u8",
    },
    {
        name: "出口1",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000009%2467/substream/1.m3u8",
    },
    {
        name: "出口2",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000009%2468/substream/1.m3u8",
    },
    {
        name: "大雄宝殿快球",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2412/substream/1.m3u8",
    },
    {
        name: "大雄宝殿功德箱1",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%249/substream/1.m3u8",
    },
    {
        name: "大雄宝殿功德箱2",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2410/substream/1.m3u8",
    },
    {
        name: "大雄宝殿功德箱3",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2411/substream/1.m3u8",
    },
    {
        name: "观音殿",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%247/substream/1.m3u8",
    },
    {
        name: "大雄宝殿后广场",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%248/substream/1.m3u8",
    },
    {
        name: "大雄宝殿东面",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2414/substream/1.m3u8",
    },
    {
        name: "大雄宝殿西面",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2413/substream/1.m3u8",
    },
    {
        name: "天王殿前广场快球",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%243/substream/1.m3u8",
    },
    {
        name: "天王殿前",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%245/substream/1.m3u8",
    },
    {
        name: "天王殿后",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%246/substream/1.m3u8",
    },
    {
        name: "药师殿快球",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2418/substream/1.m3u8",
    },
    {
        name: "药师殿后西面",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2421/substream/1.m3u8",
    },
    {
        name: "济公殿快球",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2415/substream/1.m3u8",
    },
    {
        name: "济公殿门口",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000009%2474/substream/1.m3u8",
    },
    {
        name: "济公殿左",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2416/substream/1.m3u8",
    },
    {
        name: "济公殿右",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2417/substream/1.m3u8",
    },
    {
        name: "华严殿东南角快球",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000009%2487/substream/1.m3u8",
    },
    {
        name: "华严殿功德箱1",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2422/substream/1.m3u8",
    },
    {
        name: "华严殿功德箱2",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2423/substream/1.m3u8",
    },
    {
        name: "华严殿功德箱3",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2424/substream/1.m3u8",
    },
    {
        name: "罗汉堂快球",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000008%2427/substream/1.m3u8",
    },
    {
        name: "传达室快球",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000009%2442/substream/1.m3u8",
    },
    {
        name: "停车场快球",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000009%2445/substream/1.m3u8",
    },
    {
        name: "锅炉房外",
        url: "https://zh.jk.lingyinsi.com/live/cameraid/1000009%2443/substream/1.m3u8",
    },
];

export const baseOption = {
    autoplay: true, //如果true,浏览器准备好时开始回放。
    muted: true, // 默认情况下将会消除任何音频。
    loop: false, // 导致视频一结束就重新开始。
    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
    language: 'zh-CN',
    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
    sources: [{
        type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
        src: "" //url地址
    }],
    poster: "", //你的封面地址
    width: document.documentElement.clientWidth, //播放器宽度
    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
    controlBar: {
        timeDivider: true,
        durationDisplay: true,
        currentTimeDisplay: true,
        remainingTimeDisplay: true,
        fullscreenToggle: true  //全屏按钮
    }
}

// 随机生成车辆数据
export const generateCarData = function (num = 3, people) {
    let plates = ['浙A·517H1', '浙A·1B102', '浙A·53E5A', '浙A·206MJ', '浙A·58Q06', '浙A·7T9Y7', '浙A·77C3G', '浙A·7A193', '浙A·3L99G', '浙A·53215', '浙A·J011S'];
    let gates = ['第三道岗'];
    let person = ['能量', '朱明生', '心法', '方德', '仁净', '曹杰', '林子恒'];
    let list = [];
    for(let i = 0; i < num; i ++) {
        let num = plates[Math.round(Math.random() * (plates.length - 1))];
        let now = new Date();
        let h = now.getHours();
        let day = now.pattern('yyyy-M-d');
        h = h - Math.round(Math.random() * (i + 1));
        let minute = Math.round(Math.random() * 60);
        let time = `${day} ${getDoubleText(h)}:${getDoubleText(minute)}`;
        const item = {num, time}
        if(people) {
            item.person = person[Math.round(Math.random() * (person.length - 1))];
        } else {
            item.gate = gates[Math.round(Math.random() * (gates.length - 1))];
        }
        list.push(item);
    }
    return list;
}
