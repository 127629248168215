const chartData = {
    type: 'pie',
    radius: ['40%', '80%'],
    center: ['50%', '50%'],
    label: {
        show: true,
        position: 'inside',
        fontSize: 12,
        color: '#fff',
        formatter: '{b}'
    },
    startAngle: -90,
}
export default {
    color: ['#e86964', '#4898bf', '#a1dba8', '#72f5fc', '#fbe78f', '#d5b6fa'],
    title: [
        {
            subtext: '性别',
            left: '16.67%',
            top: '80%',
            textAlign: 'center',
            subtextStyle: {
                color: '#fff',
                fontSize: 12
            }
        },
        {
            subtext: '年龄',
            left: '50%',
            top: '80%',
            textAlign: 'center',
            subtextStyle: {
                color: '#fff',
                fontSize: 12
            }
        },
        {
            subtext: '地区',
            left: '83.33%',
            top: '80%',
            textAlign: 'center',
            subtextStyle: {
                color: '#fff',
                fontSize: 12
            }
        },
    ],
    series: [
        {
            ...chartData,
            name: '性别',
            left: 0,
            right: '66.6667%',
            data: [
                {value: 862, name: '男'},
                {value: 782, name: '女'},
            ]
        },
        {
            ...chartData,
            name: '年龄',
            left: '33.3333%',
            right: '33.3333%',
            data: [
                {value: 324, name: '18-24岁'},
                {value: 234, name: '25-29岁'},
                {value: 135, name: '40-49岁'},
                {value: 89, name: '50岁以上'},
                {value: 109, name: '未知'},
            ]
        },
        {
            ...chartData,
            name: '地区',
            left: '66.6667%',
            right: 0,
            data: [
                {value: 888, name: '浙江'},
                {value: 234, name: '江苏'},
                {value: 131, name: '安徽'},
                {value: 89, name: '福建'},
                {value: 46, name: '山东'},
                {value: 88, name: '其他'},
            ]
        }
    ]
};

export const wechat = [
    {
        area: '云南',
        nickname: '图朵朵',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKRiaY8icrDQctQya7rsTQpqTUwaJhKNnFibe1G9wicicMQt3Kvvoz39RHAAqP4VO7XM7QYHlxEX8xZXpg/132',
        text: '祝福大家事业有成，福德圆满！',
        origin: '微信'
    },
    {
        area: '河南',
        nickname: '爱爱',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLJaB4BQbt1aflkPfl0eiaYyCONMkicz4PhibFEzlOIIvCBSRuhJLsdF9Odb6eFNXO7nazqatibd08sfg/132',
        text: '菩萨保佑所有人：身体健康，万事如意，所愿皆满，六时吉祥。',
        origin: '微信'
    },
    {
        area: '陕西',
        nickname: '张春雷',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIVibVqfusUeOUbH8CxiaiakibLxIUgjtDvibQpQlXuU2j1PmQJerWzZDluTp9keibr9LHx7krOl9UPic3NA/132',
        text: '一愿君得杨枝水，健康无恙身轻松。二愿君得甘露饮，烦恼顿化莲花开。三愿菩萨遂君愿，危难时刻助君渡。',
        origin: '微信'
    },
    {
        area: '河北',
        nickname: '来明海',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLQyJGEdUggZKyLPd12EA1TNSgEW2JuRMSJvwUwdjrJTQBjiasxNwZeC2aiczhib4KHtmvFbGnjsuNEw/132',
        text: '愿爸爸妈妈多福多禄多长寿，好人一生多平安。',
        origin: '微信'
    },
    {
        area: '河南',
        nickname: '王宇',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/tlnRaKic6pfvjJgdutyp32YvdDDzc3QZCAtgFxwsNhDqRKhs7hlGTpz67xJprEgAwISySFvslYkIKicqJU7ib04mA/132',
        text: '千处祈求千处应，苦海常作渡人舟。大慈大悲的菩萨现身.解救人们于烦恼的苦海。愿观音出家日，你有菩萨一样的心肠。',
        origin: '微信'
    },
    {
        area: '山东',
        nickname: '小豆子',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLibLCwx5tyRRZ6dL7LhgvMgjyzEnpmicEGGic5mwsico6hR6FzNQl92q9RV4wcQb9GV6yGSU3qjNAnxw/132',
        text: '一年一次祈福日，来许个愿，祈福万事皆顺。',
        origin: '微信'
    },
    {
        area: '辽宁',
        nickname: '王志弘',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIktCUJLCugtFt3lACqQ3BeISbwI9YfkMPju6VbzK1zFWwlz1MWly8XrBGcvemibS9LibcGCfKySkTg/132',
        text: '愿你好人好报福气到，善始善终佛相助；慈悲为怀心中念，虔诚祈福佛显灵。',
        origin: '微信'
    },
    {
        area: '山西',
        nickname: '彬彬',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIuTHDW074icH4edmZ4mQZYcY88E2o2iaQAdla8skaZceicOsmkDz4v1xxcZIg3ic44VbLxRo96Rrl0ww/132',
        text: '妈妈您辛苦，儿子长大了，终于懂了您的固执和坚持，愿观音保佑善良的你，一生平安，我会倍加努力让您感受到儿子给您回馈。',
        origin: '微信'
    },
    {
        area: '江苏',
        nickname: '海滨',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/tlnRaKic6pfvjJgdutyp32UiajQicXuhbTAyvlC2KyLncV8dtLDNECZ19MffFC2qGNoctMOu5YVYWN2cZmKhXQNDw/132',
        text: '愿老婆愿你广种福田，广结善缘，以豁达的心态接纳幸福与快乐！一路上有我。',
        origin: '微信'
    },
    {
        area: '北京',
        nickname: '刚子',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epfyHB0A423dIibpwSuQnuyaq7Add7UU7EV41req8Or9FXjFjSUDr38Z2KKuhyiafZ82bHBH8RWpH7Q/132',
        text: '佛前许个愿，来个可爱的伴。',
        origin: '微信'
    },
    {
        area: '上海',
        nickname: '心慧',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIGKNrEibvBDrO5hj0clHHVPNp6ib8AChLuhvY4GFzUEz5yX0evwzicO9nByALRxqyOr0vt8dEXRNiang/132',
        text: '观音菩萨，大慈大悲。愿功德回向给我的家人，愿他们生活笑开颜，祝你工作步向前。',
        origin: '微信'
    },
]

export const sina = [

    {
        area: '北京',
        nickname: '胖子机车',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/0iaiauALtEFdUQjSZ8JFot4d2KEFBc1nP29WyenRgtpc4Ahr92ZWHUuZgcstm3dkib6YKsmqFdpShIRhibdf0LfxeA/132',
        text: '愿你一生平平淡淡却和安安乐乐相伴，追求从此平平坦坦却与顺顺安安缠绵！',
        origin: '新浪'
    },
    {
        area: '上海',
        nickname: '苏祈儿',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/l8wlybptEFpMUKfx7Lic8Jr2lqTQK7ibwSCc1j9LUKAlBvA0foeB1DmUTOZicdBucAtppwMXO4y56jzIVbxmsGc4w/132',
        text: '新的一年，身体好！吃什么都香，胃口好；喝什么都甜，味觉好；想去哪都能，腿脚好；几点睡都行，睡眠好！没病没灾，身体棒棒的；无痒无痛，健康杠杠的！',
        origin: '新浪'
    },
    {
        area: '安徽',
        nickname: '杜敏',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DVeEf7ZQiadwFjkxHhhMV9ia0IliaftLibD6OrIVUs4WxNibdo0cibnrW1tEfSXEEO8ELxSBjXl5SWYYLmVPMtmDvcNw/132',
        text: '只要诚心，佛就在心中！抱着一个诚心就行！',
        origin: '新浪'
    },
    {
        area: '安徽',
        nickname: '合肥吴彦祖',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83erWLlVVFpdYbR3X8ooAhovZqkWwicia6N4NGia5q4jW0cp2whlB4LhYvynibUeQxibPm83eVprlbluDXTw/132',
        text: '愿天下太平，世界和平，国泰民安，百姓无忧无虑生活，让世界充满爱。',
        origin: '新浪'
    },
    {
        area: '金华',
        nickname: '一盏青灯',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/hNErUpCTKrA7qA86NTUOR8ib1fibSiaq48r0RWAjHjL4fpAYjiaZwgmibR0akz1sWTxiclWu9VSObvWSp1hoImQKor1g/132',
        text: '以病苦为良药，以患难为解脱，以障碍为逍遥，以群魔为法侣，以事难为安乐，以弊交为资粮，以逆人为园林，以施德为弃屣，以疏利为富贵，以受抑为行门。',
        origin: '新浪'
    },
    {
        area: '内蒙古',
        nickname: '琪琪格',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/INc61dATFSicSDc7LzDAdgUvuK6ll8OibhSduMTLFRTBibO4riaPZDzdD4ChpSJ1hnjQ6YNe7opC80IqocSKMEYxuA/132',
        text: '日行一善，必有后福，愿你一生平安，多多行善。',
        origin: '新浪'
    },
    {
        area: '北京',
        nickname: '墩',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/ymfwCg45P6kLQqjbI7BPCHXF87ypmMGgnulktiacqnoI5bz83XYK1CwjREkr5YQd8ibsrx6ruMknwfPC1qqgqxDw/132',
        text: '小卉愿你福禄多多，快乐多多，平安一生！',
        origin: '新浪'
    },
    {
        area: '吉林',
        nickname: '山海',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eoE776K9fE4tGfwae5n7sw9X1e5U3oJ6Jzqtj5FAvyPtY0RicfvGKz5zsENJnLKCMGwL3Y11ndhxpQ/132',
        text: '愿你明澈的心湖，迎着佛国的晨风吹拂，漾起欢乐吉祥！',
        origin: '新浪'
    },
    {
        area: '北京',
        nickname: '李海',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/fB5FJferHztKkzslcRggiaQOjQUJNyichoqXia2YvqhM0gaPb5k8M3c7pJKEIfJibthYILlX7pFsdItXUwEmNXVQrA/132',
        text: '以真情祈祷逝者，愿亲人住安乐。',
        origin: '新浪'
    },
    {
        area: '上海',
        nickname: '小迪',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/y3iaicFoUBJT5f6sgyibuTuTibqtpmsES90T0LdiaLXm6aPQpLIs4vAfyO5KO248osxqibO0zdhhmKfU3AicAcveCrTTw/132',
        text: '千手千眼观世音，常运慈心拔有情，度尽阿鼻众生苦，所发誓愿永不断。',
        origin: '新浪'
    },
    {
        area: '青海',
        nickname: '阿良',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJWAx8W6iaU1fqtVy7wsWcjvNsn155OcNOApTx1LQ4LVFR5294Y8BbWKgcZLTWicG5Px66wl4XfPicrA/132',
        text: '愿快乐，鲜花，美丽，平安与你同在，祝你幸福一生，快乐一世，平安一生一世。',
        origin: '新浪'
    },
    {
        area: '上海',
        nickname: '陶源',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epouSINWbNa0M3nGUFzCMdd1GLoUyRBmC9RY9CKHHlXHhiaC1MhEQyg8KplBBlVF2fq7Ggib1jnS1QA/132',
        text: '祈愿2020年全家人和我的妈妈亲人平安健康，好运连连，八方来财，心想事成，财运滚滚，万事皆顺！',
        origin: '新浪'
    },
]

export const douyin = [
    {
        area: '浙江',
        nickname: '李栋',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTK4EG6eDwP2RoKw68hf7O290yH34n5ibpLrhyKZ7nLM5eibn01g0KTCMiapAQJUPjE95AQoDuxKxMm0g/132',
        text: '祝福大家学业.道业精进！世出世道咸皆圆满！',
        origin: '抖音'
    },
    {
        area: '黑龙江',
        nickname: '大海',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqd8q3j0eDD7c69fMYeDSbCWUR1cCiafWFKUBBicicyoRQ7ZziciaA55ZTqiczMmJ0ygVOUqI9icdWLluNZg/132',
        text: '大慈大悲的菩萨，请赐福给我和朋友，身体健康，事业有成，合家欢乐。菩萨保佑！菩萨保佑！',
        origin: '抖音'
    },
    {
        area: '杭州',
        nickname: '玲玲',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epKBQcoAkyz2ibKHnpIib3ib29VoH7NGFAHQK6tJhwgktQm8LcyHDE8zdxuweMJvzY5N5XiaYLhgCXXCA/132',
        text: '求个十八子，十八子，十八子。',
        origin: '抖音'
    },
    {
        area: '杭州',
        nickname: '李国良',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epUiaciao6LO7jvxj1GJc9uLZX56TpEb647pK8Fqe0mIZic1mwyiafoPFKYyc4ABrH5YctO0U5sRcgrDg/132',
        text: '阖家幸福吉祥。',
        origin: '抖音'
    },
    {
        area: '湖北',
        nickname: '王家国',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJXNgTribiaoxnia7pQbiceznXPZ1gmp2dSKB7GHgyA9NUYoRGw0pI8AHyjibrzVUbA3VvC0V4Mz2BPoGg/132',
        text: '求财求财，保佑我顺利发财。',
        origin: '抖音'
    },
    {
        area: '福建',
        nickname: '小安溪',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/4uuCiaibGOLhvXmpic6gAPcIjItZHAZJu1SJNJwicv0icdL5yEneZIumz4e62IgLGwvDibAC9yL3ZrRMkYAxTYYjk8vA/132',
        text: '愿每天有个好心情，万事顺利，千事顺心。',
        origin: '抖音'
    },
    {
        area: '上海',
        nickname: '王建',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83ersicdn362iaHHGAdRozbdm18lqBYFyVH6MZpDCe5fWvB1pQxoVvBKkfIPR6b82Hwtnnh5EDcp8t7Lw/132',
        text: '菩萨我可以出个家吗？',
        origin: '抖音'
    },
    {
        area: '陕西',
        nickname: '吴启浩',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIibicIaiaeJJmWIVp72KUFSNqVKicCUDfJiaHI9fjEMjicwtaTqeSRJLGYKCeJ8BtV2K0xAY8PiaQxPoY6Q/132',
        text: '今天是个好日子，拜拜佛许个愿，求子求子。',
        origin: '抖音'
    },
    {
        area: '河南',
        nickname: '安见喜',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJUNkUCIRbWjDJvg5zw03MwnUdntcRpIdlsLOUHOeUNc0vAQ253kYH7aJdnjcUhpyb5R0flWaAFaQ/132',
        text: '愿我跟儿子度过难关，一生平平安安，财源滚滚来。',
        origin: '抖音'
    },
    {
        area: '山东',
        nickname: '李海涛',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLic1bf5z7mSrQNfssogZMX4iarVXNFd1WM5AxZ55bhbtUcnyCv1B2icRkdNUxbgCySgjCvxjEiaAHicOA/132',
        text: '祈福2020小人消失，贵人相助！',
        origin: '抖音'
    },
    {
        area: '上海',
        nickname: '利多喜',
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/lwN7wy2iaibic8UMY8x6ke7DCrhmI8FMxAibt6Cwib73ICcQ46VsJmzYRCy3Xib3IbnGicyDloOSzC480JYBqSVBb3gFA/132',
        text: '我在这里，我为我的家人祈福，祈求他们身体健康.天天开心。',
        origin: '抖音'
    },
]
