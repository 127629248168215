import {clone} from "../../../common/js/tool";
import cry from "../../cry";

export const opinionTypeList = [
    { key: 1, title: "敏感", color: "#FF4800", num: 0 },
    { key: 2, title: "中性", color: "#59BCFB", num: 0 },
    { key: 3, title: "非敏感", color: "#1CD1A1", num: 0 },
]

const opinionOptions = clone(cry)
opinionOptions.title.top = 42;
opinionOptions.title.left = '20%';
opinionOptions.series[0].radius = [48, 52];
opinionOptions.series[0].center = ['20%', '50%']
opinionOptions.series[1].radius = 44;
opinionOptions.series[1].center = ['20%', '50%']
opinionOptions.series[2].radius = 38;
opinionOptions.series[2].center = ['20%', '50%']
export { opinionOptions }

export default {
    color: ['#1CD1A1', '#59BCFB', '#FE5578'],
    legend: {
        bottom: 4,
        left: 'center',
        icon: 'circle',
        itemWidth: 8,
        textStyle: {
            color: '#fff',
            fontSize: 14
        }
    },
    series: [
        {
            type: 'gauge',
            name: '正面',
            startAngle: 90,
            endAngle: -270,
            center: ['50%', '45%'],
            radius: 66,
            progress: {
                show: true,
                width: 7,
                roundCap: true,
                itemStyle: {
                    color: '#1CD1A1'
                }
            },
            pointer: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    width: 7,
                    color: [[1, 'rgba(28, 209, 161, 0.1)']]
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            detail: {
                show: false
            },
            data: []
        },
        {
            type: 'gauge',
            name: '中性',
            startAngle: 90,
            endAngle: -270,
            center: ['50%', '45%'],
            radius: 56,
            progress: {
                show: true,
                width: 7,
                roundCap: true,
                itemStyle: {
                    color: '#59BCFB'
                }
            },
            pointer: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    width: 7,
                    color: [[1, 'rgba(89, 188, 251, 0.1)']]
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            detail: {
                show: false
            },
            data: []
        },
        {
            type: 'gauge',
            name: '负面',
            startAngle: 90,
            endAngle: -270,
            center: ['50%', '45%'],
            radius: 46,
            progress: {
                show: true,
                width: 7,
                roundCap: true,
                itemStyle: {
                    color: '#FE5578'
                }
            },
            pointer: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    width: 7,
                    color: [[1, 'rgba(254, 85, 120, 0.1)']]
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            detail: {
                offsetCenter: [0, 0],
                formatter: function (value) {
                    return `{value|${value}}`;
                },
                rich: {
                    value: {
                        width: 54,
                        height: 54,
                        backgroundColor: {
                            image: require("../image/world.png")
                        },
                        color: 'transparent',
                    },
                }
            },
            data: []
        },
    ]
}
