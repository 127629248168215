const today = new Date();
const months = [];
let num = 6;
do {
    months.push(`${today.getMonth() + 1}月`);
    today.setMonth(today.getMonth() - 1);
    num -=1;
} while (num > 0);
months.reverse();

export default {
    grid: {
        top: 24,
        left: 0,
        right: 40,
        bottom: 0,
        containLabel: true
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: months,
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 14,
            color: '#b1b6ec'
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(255,255,255,0.1)'
            }
        }
    },
    yAxis: {
        type: 'value',
        axisTick: {
            show: false,
            color: '#b1b6ec'
        },
        axisLabel: {
            fontSize: 16,
            color: '#b1b6ec'
        },
        axisLine: {
            show: true,
            lineStyle: {
                color: 'rgba(255,255,255,0.1)'
            }
        },
        splitLine: {
            lineStyle: {
                type: 'dashed',
                color: 'rgba(255,255,255,0.1)'
            }
        }
    },
    series: [
        {
            data: [820, 932, 901, 934, 1290, 1330],
            type: 'line',
            symbol: 'none',
            lineStyle: {
                color: '#8edffe'
            },
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#00d7e9' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(1,7,58,0)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            }
        }
    ]
}
