<template>
  <div class="screen">
    <div class="top-banner"></div>
    <div class="culture-top">
      <div class="culture-top-bar" v-for="(u, i) in uv" :key="i">
        <div class="top-bar-text">{{u.title}}<span class="top-bar-num num-focus">{{u.num}}</span>人次</div>
        <div class="top-bar-text">同比<span class="top-bar-num">{{u.per}}</span>%</div>
        <img :src="require(`../../screen/culture/image/${u.icon}.png`)">
      </div>
    </div>
    <div class="flex-box culture-content">
      <div class="culture-left rotated-watcher">
        <div class="culture-act rotated-area">
          <div class="culture-act-data" v-for="(a, i) in act" :key="i">
            <div class="culture-act-title">{{a.title}}</div>
            <div class="culture-act-line"></div>
            <div class="culture-act-num"><span class="act-num-text">{{a.num}}</span>人次</div>
          </div>
        </div>
        <div class="culture-piece rotated-area" style="padding-bottom: 20px">
          <div class="culture-piece-top">
            <div class="culture-piece-arrow"></div>
            <div class="culture-piece-title">智慧导览</div>
          </div>
          <div class="culture-piece-line"></div>
          <div class="culture-piece-chart" id="guide_data"></div>
        </div>
        <div class="culture-piece culture-piece-large rotated-area">
          <div class="culture-piece-top">
            <div class="culture-piece-arrow"></div>
            <div class="culture-piece-title">自媒体访问分析</div>
          </div>
          <div class="culture-piece-line"></div>
          <div class="culture-piece-chart" id="visit_data"></div>
        </div>
      </div>
      <div class="culture-mid">
        <div class="culture-barrage">
          <div class="culture-barrage-title">
            <div class="culture-piece-arrow"></div>
            <div class="barrage-title-text">许愿弹幕</div>
          </div>
          <div class="culture-barrage-area">
            <transition-group class="culture-barrage-list" name="up" tag="ul">
              <li class="culture-barrage-item" v-for="b in showBullets" :key="b.id">
                <img class="culture-barrage-avatar" :src="b.avatar"/>
                <div class="culture-barrage-text">{{b.origin}}：{{b.area}} {{b.nickname}}：{{b.text}}</div>
              </li>
            </transition-group>
          </div>
        </div>
        <div class="culture-overview">
          <div class="culture-overview-item" :style="{top: `${o.top}px`, left: `${o.left}px`}" v-for="(o, i) in overview" :key="i">
            <div class="culture-overview-title">
              <div class="culture-overview-arrow"></div>
              <div class="overview-arrow-text">{{o.title}}</div>
            </div>
            <div class="culture-overview-text"><span class="culture-overview-num">{{o.num}}</span>{{o.unit}}</div>
          </div>
        </div>
      </div>
      <div class="culture-right rotated-watcher right-watcher">
        <div class="culture-act rotated-area rotated-right">
          <div class="culture-act-data" v-for="(a, i) in act" :key="i">
            <div class="culture-act-title">{{a.title}}</div>
            <div class="culture-act-line"></div>
            <div class="culture-act-num"><span class="act-num-text">{{a.num}}</span>人次</div>
          </div>
        </div>
        <div class="culture-piece rotated-area rotated-right">
          <div class="culture-piece-top">
            <div class="culture-piece-arrow"></div>
            <div class="culture-piece-title">热点使用场景</div>
          </div>
          <div class="culture-piece-line"></div>
          <div class="culture-piece-chart scene-chart" id="scene_data"></div>
        </div>
        <div class="culture-piece culture-piece-large rotated-area rotated-right">
          <div class="culture-piece-top">
            <div class="culture-piece-arrow"></div>
            <div class="culture-piece-title">停留时长分析</div>
          </div>
          <div class="culture-piece-line"></div>
          <div class="culture-piece-chart">
            <div class="culture-stay-duration">
              <div class="culture-stay-time" v-for="(d, i) in duration" :key="i">
                <div class="culture-stay-rank">NO.<span class="stay-rank-num">{{i + 1}}</span></div>
                <div class="culture-stay-info">
                  <div class="culture-stay-location">{{d.location}}</div>
                  <div class="culture-stay-bar">
                    <div class="stay-bar-box">
                      <div class="culture-stay-per" :style="{width: `${d.per}%`}"></div>
                    </div>
                    <div class="culture-duration-time">{{d.timeText}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="culture-msg-wall">
        <div class="msg-wall-title">
          <div class="culture-piece-arrow"></div>
          <div class="wall-title-text">实时留言墙</div>
        </div>
        <div class="wall-msg-tab">
          <div class="msg-tab-item" :class="{'msg-tab-select': msgTab == i}" @click="msgTab = i" v-for="(t, i) in msgTabs" :key="i">{{t}}</div>
        </div>
        <transition-group style="position: relative" name="up" tag="ul">
          <li class="wall-msg-item" v-for="m in showMsg" :key="m.id">{{m.area}} {{m.nickname}}：{{m.text}}</li>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
    import * as echarts from "echarts";
    import "echarts-gl";
    import visit from "../../screen/culture/visit";
    import guide, {wechat, sina, douyin} from "../../screen/culture/guide";
    import {getPie3D} from "../../screen/culture/scene";

    export default{
        name: "Culture",
        props: {
            data: Object
        },
        data() {
            return {
                uv: [
                    {title: '网站在线', num: 800, per: 18, icon: 'up'},
                    {title: '微信公众号', num: 1000, per: 24, icon: 'up'},
                    {title: '新浪微博', num: 2000, per: 6, icon: 'down'}
                ],
                act: [
                    {title: '祝福红包', num: 14898},
                    {title: '线上祈福', num: 1189},
                    {title: '云打卡', num: 3799},
                ],
                culture: [
                    {title: '闻到灵隐', num: 1652},
                    {title: '人文灵隐', num: 920},
                    {title: '我有投稿', num: 868},
                ],
                msgTabs: ['微信', '微博', '抖音'],
                msgTab: 0,
                duration: [],
                showBullets: [],
                bulletList: [],
                overview: [],
                showMsg: [],
                msgList: [],
                guideChart: null,
                visitChart: null,
                sceneChart: null,
                msgTimer: null,
                msgTabTimer: null
            }
        },
        watch: {
            msgTab() {
                this.clearMsgTimer();
                this.setMsgList();
                this.msgTimer = setInterval(this.updateMsgList, 5000);
            }
        },
        mounted() {
            this.setData();
            this.msgTimer = setInterval(this.updateMsgList, 5000);
            this.bulletTimer = setInterval(this.updateBulletList, 2000);
            this.msgTabTimer = setInterval(this.updateMsgTab, 30000);
        },
        beforeDestroy() {
            this.clearTimer();
        },
        methods: {
            clearTimer() {
                this.clearMsgTimer();
                this.clearBulletTimer();
                this.clearMsgTabTimer();
            },
            clearMsgTimer() {
                if(this.msgTimer) {
                    clearInterval(this.msgTimer);
                    this.msgTimer = null;
                }
            },
            clearBulletTimer() {
                if(this.bulletTimer) {
                    clearInterval(this.bulletTimer);
                    this.bulletTimer = null;
                }
            },
            clearMsgTabTimer() {
                if(this.msgTabTimer) {
                    clearInterval(this.msgTabTimer);
                    this.msgTabTimer = null;
                }
            },
            updateMsgTab() {
                this.msgTab = this.msgTab >= this.msgTabs.length - 1 ? 0 : this.msgTab + 1;
            },
            updateBulletList() {
                let list = this.bulletList;
                let showBullets = this.showBullets;
                if(list.length > 3) {
                    showBullets.splice(0, 1);
                    let last = showBullets[showBullets.length - 1];
                    let index = list.findIndex(item => item.nickname == last.nickname);
                    if(index == list.length - 1) {
                        showBullets.push(list[0]);
                    } else {
                        showBullets.push(list[index + 1]);
                    }
                    this.showBullets = showBullets;
                }
            },
            updateMsgList() {
                let list = this.msgList[this.msgTab];
                let showMsg = this.showMsg;
                if(list.length > 3) {
                    showMsg.splice(0, 1);
                    let last = showMsg[showMsg.length - 1];
                    let index = list.findIndex(item => item.id == last.id);
                    if(index == list.length - 1) {
                        showMsg.push(list[0]);
                    } else {
                        showMsg.push(list[index + 1]);
                    }
                    this.showMsg = showMsg;
                }
            },
            getTimeText(time) {
                let text = `${time}分钟`;
                if(time >= 24 * 60) {
                    text = `${Math.round(time / 24 / 60)}天`;
                } else if(time >= 60) {
                    text = `${Math.round(time / 60)}小时`;
                }
                return text;
            },
            setData() {
                this.setVisitData();
                this.setBullets();
                this.setOverview();
                this.setMsgList();
                this.setSceneChart();
                this.setDurationData();
                this.setGuideData();
            },
            setGuideData() {
                if(!this.guideChart) {
                    this.guideChart = echarts.init(document.getElementById('guide_data'));
                }
                this.guideChart.setOption(guide);
            },
            setDurationData() {
              let list = [
                  {location: '大雄宝殿', time: 20},
                  {location: '药师殿', time: 36},
                  {location: '济公殿', time: 120},
                  {location: '直指堂', time: 56},
                  {location: '五百罗汉堂', time: 79},
              ];
              list.sort((a, b) => b.time - a.time);
              const max = list[0].time;
              list.forEach(item => {
                  item.per = (item.time / max * 100).toFixed(2);
                  item.timeText = this.getTimeText(item.time);
              });
              this.duration = list;
            },
            setSceneChart() {
                if(!this.sceneChart) {
                    this.sceneChart = echarts.init(document.getElementById('scene_data'));
                }
                let data = [
                    {name: '导航使用', value: 176},
                    {name: '许愿墙',value: 288},
                    {name: '祈福邮箱',value: 56},
                    {name: '打卡合影',value: 142},
                    {name: '佛事法会',value: 98},
                    {name: '问道灵隐',value: 19},
                ]
                let options = getPie3D(data, 0.75, 180, 24, 30, 0.5);
                this.sceneChart.setOption(options);
            },
            setMsgList() {
                let list = [
                    wechat.map((item, index) => {return {...item, id:`wechat_${index}`}}),
                    sina.map((item, index) => {return {...item, id:`sina_${index}`}}),
                    douyin.map((item, index) => {return {...item, id:`douyin_${index}`}}),
                ];
                this.showMsg = list[this.msgTab].slice(0, 3);
                this.msgList = list;
            },
            setOverview() {
                this.overview = [
                    {title: '游客量', num: 1265, unit: '人次', top: 64, left: 128},
                    {title: '综合访问量', num: 1.53, unit: '万', top: 159, left: 318},
                    {title: '文件下载', num: 656, unit: '人次', top: 64, left: 528},
                    {title: '新增关注', num: 2631, unit: '人', top: 275, left: 28},
                    {title: '互动参与', num: 896, unit: '人次', top: 296, left: 428},
                ]
            },
            setBullets() {
                let list = [...wechat, ...sina, ...douyin];
                list.sort(() => .5 - Math.random());
                list.forEach((item, index) => item.id = index);
                this.showBullets = list.slice(0, 3);
                this.bulletList = list;
            },
            setVisitData() {
                if(!this.visitChart) {
                    this.visitChart = echarts.init(document.getElementById('visit_data'));
                }
                let today = new Date();
                let months = [];
                months.push(today.getMonth() + 1);
                for(let i = 0; i < 11; i++) {
                    today.setMonth(today.getMonth() - 1);
                    months.push(today.getMonth() + 1);
                }
                months.reverse();
                visit.xAxis.data = months;
                for(let i = 0; i < 3; i++) {
                    visit.series[i + 1].data = new Array(12).fill(0).map(() => 1500 + Math.round(Math.random() * 1000));
                }
                visit.series[0].data = visit.series[1].data.map((item, index) => item + visit.series[2].data[index] + visit.series[3].data[index]);
                this.visitChart.setOption(visit);
            }
        }
    }
</script>

<style scoped lang="less">
  @import "../../screen/screen";
  .screen {
    padding: 100px 60px 40px;
    background: url(../../screen/culture/image/back.jpg) center no-repeat;
    background-size: cover;
  }
  .top-banner {
    height: 281px;
    background-image: url(../../screen/culture/image/banner.png);
  }
  .culture-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .culture-top-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 365px;
    height: 40px;
    background: url(../../screen/culture/image/bar.png);
    &:not(:first-of-type) {
      margin-left: 72px;
    }
  }
  .top-bar-text {
    margin-right: 6px;
  }
  .top-bar-num {
    font-size: 20px;
    font-weight: bold;
    &.num-focus {
      color: #70e9f1;
    }
  }
  .culture-right,
  .culture-left {
    padding-top: 70px;
    width: 420px;
  }
  .culture-mid {
    flex: 1;
    position: relative;
    padding: 10px 0;
  }
  .culture-act {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rotated-watcher {
    perspective: 600px;
    perspective-origin: right;
    &.right-watcher {
      perspective-origin: left;
    }
  }
  .rotated-area {
    transform: rotate3d(0, 1, 0, 15deg);
    &.rotated-right {
      transform: rotate3d(0, 1, 0, -15deg);
    }
  }
  .culture-act-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 90px;
    background: url(../../screen/culture/image/box.png) center no-repeat;
    background-size: 100% 100%;
    box-shadow: inset 0 0 18px rgba(57,142,221,.39);
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
  .act-num-text {
    font-size: 20px;
    color: #70e9f1;
    font-weight: bold;
  }
  .culture-act-line {
    margin: 6px 10px;
    width: 100px;
    height: 2px;
    border-radius: 2px;
    background-image: linear-gradient(90deg, rgba(122, 249, 249,.1), rgba(122, 249, 249, 1), rgba(122, 249, 249,.1));
  }
  .culture-piece {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 10px 30px 0;
    width: 415px;
    height: 243px;
    background: url(../../screen/culture/image/border.png) center no-repeat;
    background-size: 100% 100%;
    &.culture-piece-large {
      height: 339px;
      background-image: url(../../screen/culture/image/border-large.png);
    }
    &.rotated-right {
      transform: rotateY(165deg);
      .culture-piece-chart,
      .culture-piece-title {
        transform: rotateY(180deg);
      }
    }
  }
  .culture-piece-top {
    display: flex;
    align-items: center;
    position: relative;
  }
  .culture-piece-arrow {
    margin-left: -12px;
    width: 27px;
    height: 26px;
    background: url(../../screen/culture/image/arr.png) center no-repeat;
    background-size: contain;
  }
  .culture-piece-line {
    position: relative;
    height: 2px;
    background-color: #0c73a3;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 100%;
      background-color: #06d5f5;
    }
  }
  .culture-piece-chart {
    flex: 1;
  }
  .culture-barrage-title {
    display: flex;
    align-items: center;
    position: absolute;
    top: 36px;
    left: 30px;
    font-size: 18px;
    z-index: 1;
  }
  .culture-barrage-area {
    position: relative;
    height: 220px;
    overflow: hidden;
  }
  .culture-barrage-list {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding-left: 22px;
  }
  .culture-barrage-item {
    display: flex;
    align-items: center;
    margin-top: 6px;
    transition: all .3s;
  }
  .culture-barrage-avatar {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    border: 1px solid #b4ffff;
  }
  .culture-barrage-text {
    margin-left: 4px;
    padding: 4px 10px;
    border: 1px solid #8be0ff;
    border-radius: 32px;
    color: #7af9f9;
    line-height: 1.5;
  }
  .culture-overview {
    position: relative;
    margin: 12px auto 0;
    width: 775px;
    height: 362px;
    background: url(../../screen/culture/image/overview.png) center no-repeat;
    background-size: contain;
  }
  .culture-overview-item {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 126px;
  }
  .culture-overview-title {
    display: flex;
    align-items: center;
    padding: 6px;
    font-size: 16px;
  }
  .culture-overview-arrow {
    width: 23px;
    height: 24px;
    background: url(../../screen/culture/image/arrow.png) center no-repeat;
    background-size: contain;
  }
  .culture-overview-text {
    margin-top: 6px;
    padding: 0 4px;
    text-align: center;
  }
  .culture-overview-num {
    font-size: 24px;
    color: #64cdd4;
    font-weight: 600;
  }
  .culture-msg-wall {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    padding: 36px 468px 0 454px;
    width: 1690px;
    height: 290px;
    background: url(../../screen/culture/image/wall.png) center no-repeat;
    background-size: contain;
  }
  .msg-wall-title {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    font-size: 18px;
  }
  .wall-msg-tab {
    display: flex;
  }
  .msg-tab-item {
    flex: 1;
    height: 36px;
    background-color: rgba(0, 156, 255, .3);
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    &.msg-tab-select {
      background-color: rgba(0, 156, 255, .75);
    }
    &:not(:first-of-type) {
      border-left: 1px solid rgba(0, 156, 255, .1);
    }
  }
  .wall-msg-item {
    padding: 0 18px;
    height: 36px;
    background-color: rgba(0, 156, 255, .1);
    transition: all .5s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 36px;
    color: #7af9f9;
    &:not(:first-of-type) {
      border-top: 1px solid rgba(0, 156, 255, .1);
    }
  }
  .wall-msg-table {
    position: relative;
    padding: 0 26px;
  }
  .wall-msg-tr {
    display: flex;
    align-items: center;
    background-color: rgba(0, 156, 255, .1);
    transition: all .5s;
    &:not(:first-of-type) {
      border-top: 1px solid rgba(0, 156, 255, .1);
    }
    text-align: center;
    color: #06d5f5;
    &.wall-msg-title {
      background-color: rgba(0, 156, 255, .3);
      color: #fff;
    }
  }
  .wall-msg-td {
    padding: 0 6px;
    width: 100px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:not(:first-of-type) {
      border-left: 1px solid rgba(0, 156, 255, .5);
    }
  }
  .up-enter {
    opacity: 0;
    transform: translateY(100%);
  }
  .up-leave-to {
    opacity: 0;
    transform: translateY(-100%);
  }
  .up-leave-active {
    position: absolute;
    left: 26px;
    right: 26px;
  }
  .scene-chart {
    background: url(../../screen/culture/image/pie.png) 25px 31px no-repeat;
    background-size: 191px 131px;
  }
  .culture-stay-time {
    display: flex;
    margin-top: 20px;
  }
  .culture-stay-rank {
    width: 48px;
    height: 34px;
    background: url(../../screen/culture/image/corner.png) center no-repeat;
    background-size: contain;
    text-align: center;
    color: #1ed7ff;
    line-height: 34px;
    font-weight: 600;
    font-size: 12px;
    font-style: italic;
    .stay-rank-num {
      font-size: 16px;
    }
  }
  .culture-stay-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 12px;
    font-size: 12px;
  }
  .culture-stay-bar {
    display: flex;
    align-items: center;
  }
  .stay-bar-box {
    flex: 1;
    position: relative;
    margin-right: 20px;
    height: 12px;
    border-radius: 12px;
    background-color: rgba(29,94,241,.1);
  }
  .culture-stay-per {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 12px;
    background-image: linear-gradient(90deg, #1d5ef1, #00d5ff);
    box-shadow: inset 0 0 10px #308df3;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 6px;
      height: 12px;
      border-radius: 6px / 12px;
      background-color: #0fc9e6;
    }
  }
  @keyframes leftSlide {
    0% {
      left: 100%;
      transform: translateX(0);
    }
    100% {
      left: 0;
      transform: translateX(-100%);
    }
  }
</style>
