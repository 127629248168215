<template>
  <div class="screen">
    <div class="top-banner"></div>
    <div class="buddha-area">
      <div class="buddha-piece">
        <div class="screen-piece-title">
          <img src="../../screen/buddha/image/gzpt-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">工作平台</div>
        </div>
        <div class="buddhism-num">
          <div class="buddhism-num-item" v-for="(n, i) in buddhismNumber" :key="i">
            <div class="buddhism-num-title">{{ n.title }}</div>
            <div class="buddhism-num-text">{{ n.number }}<span class="buddhism-num-unit">个</span></div>
          </div>
        </div>
      </div>
      <div class="buddha-piece buddha-act">
        <div class="screen-piece-title">
          <img src="../../screen/buddha/image/dxfh-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">大型法会</div>
        </div>
        <div class="buddha-act-top">
          <div class="buddha-act-title">
            <div class="act-title-text">当前法会</div>
            <div class="act-title-time" v-if="actData.current">{{ `${actData.current.name} ${actData.current.showDate}` }}</div>
            <div class="act-title-time" v-else>当前暂无法会</div>
          </div>
          <div class="buddha-act-title">
            <div class="act-title-text">累计登记排位</div>
            <div class="act-title-num">{{ actData.total }}</div>
          </div>
        </div>
        <div class="buddha-act-chart" id="act_chart"></div>
      </div>
      <div class="buddha-piece buddha-heart">
        <div class="screen-piece-title">
          <img src="../../screen/buddha/image/sxgd-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">随喜功德</div>
        </div>
        <div class="buddha-merit-tab">
          <div
            class="merit-tab-item"
             :class="{'merit-tab-active': meritData.tab === i}"
             @click="switchMeritTab(i)"
             v-for="(t, i) in meritData.tabList"
             :key="i">{{t}}</div>
        </div>
        <div class="buddha-merit-chart" id="merit_chart"></div>
      </div>
    </div>
    <div class="buddha-mid">
      <div class="buddha-piece">
        <div class="screen-piece-title">
          <img src="../../screen/buddha/image/fsdt-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">佛事动态</div>
        </div>
        <div class="buddhism-number">
          <div class="buddhism-number-item buddhism-number-today">
            <div class="buddhism-number-text" v-for="(t, i) in getBuddhismNumberArray(buddhismData.today)" :key="i">{{t}}</div>
          </div>
          <div class="buddhism-number-item buddhism-number-tomorrow">
            <div class="buddhism-number-text" v-for="(t, i) in getBuddhismNumberArray(buddhismData.tomorrow)" :key="i">{{t}}</div>
          </div>
        </div>
        <div class="buddhism-chart" id="buddhism_chart" v-if="orderDataType == 'chart'"></div>
        <div class="buddhism-order" v-else>
          <div class="buddhism-order-bar"></div>
          <div class="buddhism-order-table">
            <div class="buddhism-order-tr buddhism-order-head">
              <div class="buddhism-order-td">日期</div>
              <div class="buddhism-order-td">时间</div>
              <div class="buddhism-order-td">佛事名称</div>
              <div class="buddhism-order-td">牌位</div>
            </div>
            <carousel class="buddhism-order-carousel" :list="orderList" :number="5">
              <template v-slot:item="{item: order, i, current}">
                <div class="buddhism-order-tr" :class="{'buddhism-order-active': i == current + 2}">
                  <div class="buddhism-order-td">{{ order.date }}</div>
                  <div class="buddhism-order-td">{{ order.time }}</div>
                  <div class="buddhism-order-td">{{ order.buddhistName }}</div>
                  <div class="buddhism-order-td">{{ order.tagName || "无" }}</div>
                </div>
              </template>
            </carousel>
          </div>
        </div>
      </div>
      <div class="buddha-piece">
        <div class="screen-piece-title">
          <img src="../../screen/buddha/image/fsrl-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">佛事日历</div>
        </div>
        <calendar ref="buddhismCalendar" border="1px solid #010951" @table="handleCanlendarTable" lunar @month="val => current = val" class="buddhism-calendar">
          <tr class="tr buddhism-calendar-tr" slot="week">
            <th class="td">
              <div class="buddhism-calendar-date">
                <div class="buddhism-date-text">日</div>
              </div>
            </th>
            <th class="td">
              <div class="buddhism-calendar-date">
                <div class="buddhism-date-text">一</div>
              </div>
            </th>
            <th class="td">
              <div class="buddhism-calendar-date">
                <div class="buddhism-date-text">二</div>
              </div>
            </th>
            <th class="td">
              <div class="buddhism-calendar-date">
                <div class="buddhism-date-text">三</div>
              </div>
            </th>
            <th class="td">
              <div class="buddhism-calendar-date">
                <div class="buddhism-date-text">四</div>
              </div>
            </th>
            <th class="td">
              <div class="buddhism-calendar-date">
                <div class="buddhism-date-text">五</div>
              </div>
            </th>
            <th class="td">
              <div class="buddhism-calendar-date">
                <div class="buddhism-date-text">六</div>
              </div>
            </th>
          </tr>
          <template v-slot:month="{month}">
            <div class="buddhism-calendar-month">
              <div class="buddhism-month-icon" @click="operateMonth(-1)"></div>
              <div class="buddhism-month-text">{{monthTextList[new Date(month).getMonth()]}}</div>
              <div class="buddhism-month-icon" @click="operateMonth(1)"></div>
            </div>
          </template>
          <template v-slot:full="{day, index, length}">
            <div class="buddhism-calendar-day" :class="[`buddhism-calendar-${(index + 1) % 2}`, day.inMonth ? '' : 'buddhism-out-month']">
              <div
                class="buddhism-calendar-date"
                :class="{'buddhism-date-long': longLineList.indexOf(index) >= 0}"
                @click="calendarLine = index">
                <div class="buddhism-date-text">{{day.day}}</div>
                <div class="buddha-birth" v-if="birthdayMap[`${day.lunar.lnongMonth}月${day.lunar.lnongDate}`]">{{ birthdayMap[`${day.lunar.lnongMonth}月${day.lunar.lnongDate}`].name }}</div>
              </div>
              <div class="date-buddhism-list" :class="[getDayListClass(index, length)]">
                <template v-if="calendarLine == index">
                  <div class="date-buddhism-other" v-if="lock">
                    <div class="date-buddhism-lock"></div>
                  </div>
                  <carousel class="date-buddhism-box" :number="8 - length" :list="dateMap[day.dateStr]" v-else-if="dateMap[day.dateStr]">
                    <template v-slot:item="{item, i}">
                      <div class="date-buddhism-item" :style="{backgroundColor: colors[i % (colors.length - 1)]}">{{item.name}}</div>
                    </template>
                  </carousel>
                  <div class="date-buddhism-other" v-else-if="day.inMonth">
                    <div class="date-buddhism-empty">无</div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </calendar>
      </div>
    </div>
    <div class="buddha-area">
      <div class="buddha-piece">
        <div class="screen-piece-title">
          <img src="../../screen/buddha/image/tzgg-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">通知公告</div>
        </div>
        <div class="buddha-notice">
          <template v-if="announce">
            <scroll-word :text="announce.content"/>
            <div class="buddha-notice-time">发布时间：{{ announce.time }}</div>
          </template>
          <div class="buddha-notice-empty" v-else>暂无通知</div>
        </div>
      </div>
      <div class="buddha-piece log-piece">
        <div class="screen-piece-title">
          <img src="../../screen/buddha/image/rzlb-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">日志列表</div>
        </div>
        <div class="buddha-log">
          <div class="buddha-log-view">
            <grid-box color="#FF4800" border="1px solid rgba(255,140,0,0.1)" class="log-view-item sensitive-fields" @click="goToPage({name: 'TabletBlacklist'})">
              <img src="../../screen/buddha/image/sensitive-icon.png" class="log-view-icon" />
              <div class="log-view-info">
                <div class="log-view-title">敏感字段</div>
                <div class="log-view-num">{{ sensitiveCount }}</div>
              </div>
            </grid-box>
            <grid-box class="log-view-item buddha-auth" @click="goToPage({name: 'BuddhismAdmin'})">
              <img src="../../screen/buddha/image/auth-icon.png" class="log-view-icon" />
              <div class="log-view-info">
                <div class="log-view-title">权限配置</div>
              </div>
            </grid-box>
          </div>
          <div class="buddha-log-list">
            <div class="buddha-log-tr buddha-log-head">
              <div class="buddha-log-td">时间</div>
              <div class="buddha-log-td">事件</div>
              <div class="buddha-log-td">操作人</div>
            </div>
            <template v-if="logList && logList.length > 0">
              <div class="buddha-log-tr" v-for="(log, i) in logList" :key="i">
                <div class="buddha-log-td">{{ log.time }}</div>
                <div class="buddha-log-td">{{ log.event }}</div>
                <div class="buddha-log-td">{{ log.user }}</div>
              </div>
            </template>
            <div class="buddha-log-empty" v-else>
              <div class="log-empty-text" v-if="logList">暂无记录</div>
            </div>
          </div>
        </div>
      </div>
      <div class="buddha-piece schedule-piece">
        <div class="screen-piece-title">
          <img src="../../screen/buddha/image/fspb-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">法师排班</div>
        </div>
        <grid-box color="#FF4800" border="1px solid rgba(255,140,0,0.1)" class="master-schedule">
          <div class="schedule-type">
            <div class="schedule-type-icon"></div>
            <div class="schedule-type-text">
              <div class="type-text-item"  v-for="(t, i) in buddhism ? buddhism.typeName.split('') : []" :key="i">{{ t }}</div>
            </div>
            <div class="schedule-type-icon"></div>
          </div>
          <div class="schedule-tablet" v-if="buddhism">
            <open-tablet
              :table="false"
              :buddhism="buddhism"
              :box-style="openTabletStyle"></open-tablet>
          </div>
          <div class="schedule-tablet" v-else>
            <div class="schedule-tablet-empty" v-if="buddhism === false">
              <div class="schedule-text-item" v-for="(t, i) in scheduleEmptyText.split('')" :key="i">{{t}}</div>
            </div>
          </div>
          <div class="schedule-date">
            <div class="schedule-date-other">
              <div class="schedule-date-lunar">
                <div class="date-text-item" v-for="(t, i) in buddhismLunar" :key="i">{{t}}</div>
              </div>
              <div class="schedule-time" v-if="buddhism && buddhism.timeRange">
                <div class="date-text-item" v-for="(t, i) in buddhism.timeRange.name.split('')" :key="i">{{t}}</div>
              </div>
            </div>
            <div class="schedule-date-text">
              <div class="date-text-item" v-for="(t, i) in buddhismDate" :key="i">{{t}}</div>
            </div>
          </div>
        </grid-box>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import calendar from "../../components/calendar/calendar";
import gridBox from "../../components/grid-box";
import carousel from "../../components/carousel";
import scrollWord from "../../components/scroll-word";
import OpenTablet from "../../layouts/OpenTablet";
import act from "../../screen/buddha/data/act";
import merit from "../../screen/buddha/data/merit";
import bd from "../../screen/buddha/data/bd";
import {
    buddhismColors,
    dealBuddhism,
    getFhList,
    getMonthBuddhismList,
    getMonthBuddhismMap
} from "../../common/buddha/buddhism";
import {getTemple} from "../../common/js/storage";
import {getLunarDay} from "../../components/calendar/lunar";
import {getDate} from "../../common/js/tool";

export default {
  name: "Buddha",
  components: {
      calendar,
      gridBox,
      carousel,
      OpenTablet,
      scrollWord
  },
  props: {
    data: Object
  },
  data() {
    return {
      monthTextList: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      calendarLine: 0,
      calendarTable: [],
      longLineList: [],
      current: null,
      dateMap: {},
      birthdayMap: {},
      lock: false,
      colors: buddhismColors,
      pujaList: null,
      buddhaList: null,
      buddhismNumber: [
        {title: '待审核', number: 0},
        {title: '待派单', number: 0},
      ],
      actData: {
        current: null,
        total: 0
      },
      meritData: {
        tabList: ['周', '月', '年'],
        tab: 0,
        list: []
      },
      buddhismData: {
        today: 2,
        tomorrow: 4
      },
      sensitiveCount: 0,
      logList: [
        {time: '2023-01-05 09:30', event: '审核', user: '心法'},
        {time: '2023-01-04 14:27', event: '删除登记信息', user: '智慧'},
      ],
      buddhismDate: [],
      buddhismLunar: [],
      buddhismIndex: 0,
      buddhismList: null,
      scheduleEmptyText: '今日暂无排班',
      openTabletStyle: {
        marginTop: '-12px',
        flexShrink: 0,
        color: '#fff',
        transform: 'scale(0.2)'
      },
      announce: null,
      orderList: [],
      orderDataType: "table", // 订单数据展现形式
    }
  },
  computed: {
    buddhism() {
      const buddhismList = this.buddhismList;
      const buddhismIndex = this.buddhismIndex;
      let buddhism = null;
      if(buddhismList && buddhismList.length > 0) {
        buddhism = buddhismList[buddhismIndex];
      } else if(buddhismList) {
        buddhism = false;
      }
      return buddhism;
    }
  },
  watch: {
    data() {
      this.setData();
    },
    current() {
      this.getMonthBuddhismList();
    },
  },
  created() {
    this.getPujaList();
    this.getOpenTabletList();
    this.setBuddhismDate();
    this.getBuddhaBirthdayList();
  },
  mounted() {
    this.setData();
  },
  beforeDestroy() {
    this.clearTimer('buddhism');
    this.clearTimer('merit');
  },
  methods: {
    handleCanlendarTable(table) {
      this.calendarTable = table;
      this.setLongLineList();
    },
    setLongLineList() {
      if(this.calendarTable.length > 0 && Object.keys(this.birthdayMap).length > 0) {
        const list = [];
        this.calendarTable.forEach((row, i) => {
          if(row.some(day => !!this.birthdayMap[`${day.lunar.lnongMonth}月${day.lunar.lnongDate}`])) {
            list.push(i);
          }
        })
        this.longLineList = list;
      } else {
        this.longLineList = [];
      }
    },
    clearTimer(type) {
      const key = `${type}Timer`;
      if(this[key]) {
        clearInterval(this[key]);
        this[key] = null;
      }
    },
    goToPage(param) {
      this.$router.push(param);
    },
    startMeritTabInterval() {
      if(!this.meritTimer) {
        this.updateMeritChart();
        this.meritTimer = setInterval(() => {
          let tab = this.meritData.tab;
          if(tab >= this.meritData.tabList.length - 1) {
            tab = 0;
          } else {
            tab += 1;
          }
          this.$set(this.meritData, 'tab', tab);
          this.updateMeritChart();
        }, 5000)
      }
    },
    startBuddhismInterval() {
      this.clearTimer('buddhism');
      this.buddhismTimer = setInterval(() => {
        if(this.buddhismIndex < this.buddhismList.length - 1) {
          this.buddhismIndex += 1;
        } else {
          this.buddhismIndex = 0;
        }
      }, 5000)
    },
    setBuddhismDate() {
      const today = new Date();
      const year = today.getFullYear() + '', month = today.getMonth() + 1, day = today.getDate();
      this.buddhismDate = [year.substring(0, 2), year.substring(2), '年', month, '月', day, '日'];
      this.buddhismLunar = getLunarDay(today, true).split("");
    },
    getBuddhaBirthdayList() {
      let url = "/admin/buddha-birthday?pageSize=1000";
      this.$axios(url).then(res => {
        const birthdayMap = {};
        res.data.forEach(item => {
          const day = getLunarDay(getDate(item.date), true);
          birthdayMap[day] = item;
        });
        this.birthdayMap = birthdayMap;
        this.setLongLineList();
      })
    },
    getOpenTabletList() {
      let today = new Date().pattern("yyyy-MM-dd");
      const url = `/admin/fs?sort=-id&expand=master,temp,order,tablet,timeRange,palaceInfo&temple_id=${getTemple()}&pageSize=100&fs_type=2,3&status=10,11,6&filter[date]=${today}`;
      this.$axios(url, {noTempleFilter: true}).then(res => {
        const list = res.data.filter(item => item.master && item.master.length > 0);
        list.forEach(item => dealBuddhism(item));
        this.buddhismList = list;
        this.buddhismIndex = 0;
        this.startBuddhismInterval();
      });
    },
    getPujaList() {
      getFhList().then(list => {
          this.pujaList = list;
          this.generateCalendarView();
      })
    },
    getMonthBuddhismList() {
      if(!this.current) return;
      getMonthBuddhismList(this.current).then(list => {
          this.buddhaList = list;
          this.generateCalendarView();
      })
    },
    generateCalendarView() {
      if(this.pujaList && this.buddhaList) {
        this.dateMap = getMonthBuddhismMap(this.current, this.pujaList, this.buddhaList, this.data.calData);
      }
    },
    getDayListClass(index, length) {
      const res = [`date-buddhism-row${length}`];
      if(this.calendarLine === index) {
        res.push('date-buddhism-show');
      }
      return res;
    },
    operateMonth(num) {
      this.calendarLine = 0;
      this.$refs.buddhismCalendar.setMonth(num)
    },
    switchMeritTab(i) {
      this.$set(this.meritData, 'tab', i);
      this.updateMeritChart();
    },
    setData() {
      if(this.data) {
        this.setBuddhismNumber();
        this.setActData();
        this.setMeritData();
        this.setBuddhismData();
        this.sensitiveCount = this.data.fslog.blackListCount;
        const announce = this.data.announce;
        if(announce) {
          announce.time = getDate(announce.update_time).pattern('yyyy年M月d日 HH:mm');
          this.announce = announce;
        }
      }
    },
    setBuddhismData() {
        if(this.data.orderDataType == "chart") {
            this.setBuddhismChart();
        } else {
            const list = this.data.fsOrder || [];
            list.forEach(item => {
                const day = new Date(item.createTimee);
                item.date = day.pattern("yyyy-MM-dd");
                item.time = day.pattern("HH:mm");
            });
            this.orderList = list;
        }
    },
    setBuddhismChart() {
        const data = this.data.buddhism;
        this.$set(this.buddhismData, 'today', data.today * 1);
        this.$set(this.buddhismData, 'tomorrow', data.tomorrow * 1);
        if(!this.buddhismChart) {
            this.buddhismChart = echarts.init(document.getElementById('buddhism_chart'));
        }
        const reservation = data.reservation;
        const list = [
            reservation.ddyspf * 1,
            reservation.ddwspf * 1,
            reservation.zxyspf * 1,
            reservation.zxwspf * 1,
            reservation.lcsjys * 1,
            reservation.lcsjws * 1,
            reservation.ykyds * 1,
            reservation.yksds * 1,
        ];
        bd.series.forEach(item => item.data = [...list]);
        this.buddhismChart.setOption(bd);
    },
    setMeritData() {
      const data = this.data.sxgd;
      this.meritData.list = [
        [data.fs[0], data.zs[0], data.js[0]],
        [data.fs[1], data.zs[1], data.js[1]],
        [data.fs[2], data.zs[2], data.js[2]]
      ]
      if(!this.meritChart) {
        this.meritChart = echarts.init(document.getElementById('merit_chart'));
      }
      this.startMeritTabInterval();
    },
    updateMeritChart() {
      const data = this.meritData;
      const list = data.list[data.tab] || [0, 0, 0];
      merit.series.forEach(item => item.data = [...list]);
      this.meritChart.setOption(merit);
    },
    setBuddhismNumber() {
      const data = this.data.workCount;
      const number = this.buddhismNumber;
      this.$set(number[0], 'number', data.no_process * 1);
      this.$set(number[1], 'number', data.no_dispatch * 1);
    },
    setActData() {
      const data = this.data.bigCalendar;
      const temp = data.list;
      if(temp && temp.other_config) {
        try {
          const otherConfig = JSON.parse(temp.other_config);
          temp.showDate = `${otherConfig.begin}~${otherConfig.end}`;
        } catch (e) {
          console.error(e)
        }
      }
      this.$set(this.actData, 'total', data.allcount * 1);
      this.$set(this.actData, 'current', temp);
      if(data.count) {
        const current = [], total = [];
        const days = [];
        data.count.reverse();
        data.count.forEach(item => {
            current.push(item.num * 1);
            total.push(item.leinum * 1);
            days.push(new Date(item.time).pattern('M-d'));
        });
        if(!this.actChart) {
            this.actChart = echarts.init(document.getElementById('act_chart'));
        }
        act.xAxis.data = days;
        act.series[0].data = current;
        act.series[1].data = total;
        this.actChart.setOption(act);
      }
    },
    getBuddhismNumberArray(num) {
      const str = num > 9 ? '' + num : '0' + num;
      return str.split("");
    }
  }
}
</script>

<style scoped lang="less">
@import "../../screen/screen";
.screen {
  display: flex;
  padding: 110px 30px 30px;
}
.top-banner {
  height: 120px;
  background-image: url(../../screen/buddha/image/top.png);
}
.buddha-area {
  flex-shrink: 0;
  padding: 24px 28px;
  width: 550px;
  background: url(../../screen/buddha/image/border.png) center / 100% 100% no-repeat;
}
.buddha-mid {
  flex: 1;
  padding: 0 30px;
}
.buddhism-num {
  display: flex;
  margin-top: 28px;
  padding-left: 30px;
}
.buddhism-num-item {
  padding: 20px 0 0 27px;
  width: 188px;
  height: 84px;
  text-align: left;
  &:first-child {
    background: url(../../screen/buddha/image/aprrove-back.png) center / 100% 100% no-repeat;
    .buddhism-num-text {
      color: #23FFFC;
    }
  }
  &:last-child {
    margin-left: 45px;
    background: url(../../screen/buddha/image/dispatch-back.png) center / 100% 100% no-repeat;
    .buddhism-num-text {
      color: #23B9FF;
    }
  }
}
.buddhism-num-title {
  font-size: 14px;
  line-height: 17px;
}
.buddhism-num-text {
  margin-top: 4px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.buddhism-num-unit {
  margin-left: .5em;
  font-size: 14px;
  font-weight: normal;
  color: #fff;
}
.buddha-act {
  margin-top: 24px;
}
.buddha-act-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  opacity: .85;
}
.buddha-act-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
}
.act-title-text {
  position: relative;
  margin-right: 12px;
  padding-left: 10px;
  color: #7A9DF7;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto 0;
    width: 4px;
    height: 18px;
    background-color: #18347D;
    border-radius: 2px;
  }
}
.act-title-time {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.85);
}
.act-title-num {
  font-weight: 700;
  color: #00F5FF;
}
.buddha-act-chart {
  margin-top: 16px;
  height: 252px;
}
.buddha-heart {
  position: relative;
  margin-top: 20px;
}
.buddha-merit-tab {
  display: flex;
  align-items: center;
  position: absolute;
  top: 64px;
  right: 0;
  z-index: 1;
}
.merit-tab-item {
  width: 36px;
  height: 24px;
  border: 1px solid #23B9FF;
  line-height: 22px;
  color: #23B9FF;
  font-size: 16px;
  cursor: pointer;
  &.merit-tab-active {
    background-color: #23B9FF;
    color: #010743;
  }
}
.buddha-merit-chart {
  margin-top: 20px;
  height: 274px;
}
.buddhism-number {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 28px;
  padding-top: 64px;
  height: 150px;
  background: url(../../screen/buddha/image/buddha-back.png) center / 100% 100% no-repeat;
}
.buddhism-number-item {
  flex: 1;
  display: flex;
  align-items: center;
}
.buddhism-number-today {
  justify-content: flex-end;
  padding-right: 64px;
}
.buddhism-number-tomorrow {
  padding-left: 64px;
}
.buddhism-number-text {
  width: 52px;
  height: 52px;
  background: url(../../screen/buddha/image/number-box.png) center / 100% 100% no-repeat;
  font-weight: 700;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  &:not(:first-of-type) {
    margin-left: 10px;
  }
}
.buddhism-chart {
  margin-top: 22px;
  height: 300px;
}
.buddhism-calendar {
  margin-top: 20px;
  background-color: rgba(56,128,211,0.1);
}
.buddhism-calendar-month {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  background-image: linear-gradient(90deg, rgba(56, 128, 211, 0) 0%, rgba(24, 201, 208, 0.75) 50%, rgba(56, 128, 211, 0) 100%);
}
.buddhism-month-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: #00F5FF;
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    filter: blur(0.5px);
  }
  &::after {
    content: "";
    margin-left: -1px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25);
  }
}
.buddhism-month-text {
  width: 124px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}
.buddhism-calendar-day {
  &.buddhism-out-month {
    color: rgba(255,255,255,.4);
  }
  .buddhism-calendar-date {
    cursor: pointer;
  }
}
.buddhism-calendar-date {
  position: relative;
  padding: 5px 0;
  height: 35px;
  &.buddhism-date-long {
    height: 46px;
  }
}
.buddhism-date-text {
  line-height: 24px;
}
.buddha-birth {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  font-size: 12px;
}
.date-buddhism-list {
  height: 0;
  overflow: hidden;
  transition: height .3s;
  &.date-buddhism-show {
    height: 96px;
    &.date-buddhism-row5 {
      height: 72px;
    }
    &.date-buddhism-row6 {
      height: 48px;
    }
  }
}
.date-buddhism-item {
  height: 24px;
  line-height: 24px;
  text-align: center;
}
.date-buddhism-other {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.date-buddhism-empty {
  width: 30px;
  height: 30px;
  background-color: #23B9FF;
  border-radius: 100%;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
}
.date-buddhism-lock {
  width: 36px;
  height: 36px;
  background: url(../../screen/buddha/image/lock.png) center / 100% 100% no-repeat;
}
.buddha-notice {
  margin-top: 22px;
  padding: 14px 20px 0 94px;
  width: 479px;
  height: 84px;
  background: url(../../screen/buddha/image/notice-back.png) center / 100% 100% no-repeat;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
}
.buddha-notice-empty {
  margin-top: 16px;
  text-align: center;
}
.buddha-notice-time {
  margin-top: 6px;
  color: #88B1D0;
}
.log-piece {
  margin-top: 20px;
}
.buddha-log {
  margin-top: 24px;
}
.buddha-log-view {
  display: flex;
}
.log-view-item {
  display: flex;
  align-items: center;
  padding-left: 24px;
  width: 224px;
  height: 74px;
  cursor: pointer;
  &.sensitive-fields {
    .log-view-title {
      background: linear-gradient(180deg, #FFF0E8 0%, #FF4800 52.6%, #FFECE0 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  &.buddha-auth {
    margin-left: 20px;
    background: linear-gradient(180deg, #FFF0E8 0%, #23B9FF 52.6%, #FFECE0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
.log-view-info {
  margin-left: 18px;
  text-align: left;
}
.log-view-title {
  font-size: 20px;
  line-height: 30px;
}
.log-view-num {
  margin-top: 4px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.buddha-log-list {
  margin-top: 24px;
}
.buddha-log-tr {
  display: flex;
  align-items: center;
  height: 36px;
  background-color: rgba(20,43,128,0.5);
  font-size: 14px;
  &:not(:first-of-type) {
    margin-top: 2px;
  }
  &.buddha-log-head {
    background-color: #142B80;
    font-size: 16px;
    color: #00F5FF;
  }
}
.buddha-log-td {
  flex: 1;
}
.schedule-piece {
  margin-top: 18px;
}
.master-schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  padding: 0 20px 0 44px;
  height: 368px;
}
.schedule-type {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 315px;
  background: linear-gradient(0, rgba(199, 55, 46, 0) 0%, rgba(199, 55, 46, 0.75) 50%, rgba(199, 55, 46, 0) 100%);
}
.schedule-type-text {
  display: flex;
  flex-direction: column;
  margin: 18px 0;
  height: 120px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.schedule-type-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: #C7372E;
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    filter: blur(0.5px);
  }
  &::after {
    content: "";
    margin-left: -1px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25);
  }
}
.schedule-tablet {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 205px;
  height: 340px;
  font-family: pangmen;
}
.schedule-tablet-empty {
  font-size: 24px;
  line-height: 1.2;
}
.schedule-date {
  display: flex;
  align-items: flex-end;
  height: 340px;
}
.schedule-date-other,
.schedule-date-text {
  width: 14px;
  height: 126px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.schedule-date-other {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 6px;
}
.buddhism-order {
  position: relative;
  margin: 20px 0 10px;
  padding: 17px;
  background: url(../../screen/buddha/image/order-table.png) center / 100% 100% no-repeat;
}
.buddhism-order-table {
  position: relative;
}
.buddhism-order-tr {
  display: flex;
  align-items: center;
  height: 38px;
  border-bottom: 1px solid rgba(35, 255, 252, 0.2);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  &.buddhism-order-head {
    margin-top: 0;
    background: rgba(0, 8, 73, 0.42);
    color: #00F5FF;
    font-size: 16px;
    font-weight: 500;
  }
  &.buddhism-order-active {
    color: #ffffff;
  }
}
.buddhism-order-td {
  flex: 1;
  &:nth-of-type(3) {
    width: 50%;
  }
}
.buddhism-order-carousel {
  height: 190px;
}
.buddhism-order-bar {
  position: absolute;
  top: 0;
  left: 17px;
  right: 17px;
  bottom: 0;
  margin: auto 0;
  height: 38px;
  border: 1px solid rgba(35, 255, 252, 0.5);
  background: rgba(0, 8, 73, 0.42);
  box-shadow: 4px 4px 8px 0 rgba(35, 255, 252, 0.25) inset, -4px -4px 8px 0 rgba(35, 255, 252, 0.25) inset, 2px 2px 4px 0 rgba(35, 255, 252, 0.25), -2px -2px 4px 0 rgba(35, 255, 252, 0.25);
  transform: translateY(19px);
}
</style>
