export default {
    color: ['#f19b06', '#00bdff', '#ff3f1f'],
    legend: {
        top: 10,
        icon: 'circle',
        itemWidth: 8,
        itemHeight: 8,
        itemStyle: {
            borderWidth: 2,
            borderColor: '#fff'
        },
        textStyle: {
            color: '#fff',
            fontSize: 12
        }
    },
    grid: {
        top: 48,
        left: 0,
        right: 10,
        bottom: 10,
        containLabel: true
    },
    xAxis: {
        type: 'category',
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 12,
            color: '#999999',
        },
        splitLine: {
            show: false,
        },
        axisLine: {
            lineStyle: {
                width: 2,
                color: "#999999"
            }
        },
        data: []
    },
    yAxis: {
        axisTick: {
            show: false,
        },
        splitLine: {
            show: false,
        },
        axisLine: {
            show: true,
            lineStyle: {
                width: 1,
                color: "#999999"
            }
        },
        axisLabel: {
            color: '#999999',
        },
        minInterval: 1
    },
    series: [
        {
            data: [],
            type: 'bar',
            itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#0066ff' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#00c6ff' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
                borderRadius: 10
            },
            barWidth: 10
        },
        {
            type: 'line',
            name: '微信',
            data: [],
            symbol: 'none',
            lineStyle: {
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [{
                      offset: 0, color: '#ffb239' // 0% 处的颜色
                    }, {
                      offset: 1, color: '#f19b06' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            },
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#ffb239' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(17,43,70,0)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            },
            smooth: true
        },
        {
            name: '微博',
            type: 'line',
            data: [],
            symbol: 'none',
            lineStyle: {
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [{
                        offset: 0, color: '#46bbdb' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#00bdff' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            },
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#01d0f8' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(17,43,70,0)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            },
            smooth: true
        },
        {
            name: '网站',
            type: 'line',
            data: [],
            symbol: 'none',
            lineStyle: {
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [{
                        offset: 0, color: '#ff512f' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#ff3f1f' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            },
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#ff512f' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(17,43,70,0)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            },
            smooth: true
        },
    ]
}
