export default {
    angleAxis: {
        max: 600,
        startAngle: 0,
        axisLine: {
            show: false
        },
        splitLine: {
            show: false
        },
        axisLabel: {
            show: false
        },
        axisTick: {
            show: false
        },
    },
    radiusAxis: {
        type: 'category',
        axisLine: {
            show: false,
        },
        axisLabel: {
            show: false
        },
        axisTick: {
            show: false
        },
        interval: 12,
        z: 10,
    },
    polar: {
        radius: '90%',
        center: ['50%', '50%']
    },
    series: [{
        type: 'bar',
        label: {
            show: false,
        },
        roundCap: true,
        barWidth: 8,
        data: [
            {value: 0, name: '空'},
            {value: 200, name: '计划', itemStyle: {color: '#1939e5'}},
            {value: 300, name: '实到', itemStyle: {color: '#479ee3'}},
            {value: 400, name: '总数', itemStyle: {color: '#6adfcd'}},
        ],
        coordinateSystem: 'polar',
        name: 'A',
        emphasis: {
            focus: 'series'
        }
    }]
}
