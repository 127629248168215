<template>
    <div class="grid-box" :style="{border}" v-on="$listeners">
        <div class="corner top-left" :style="{...defaultStyle}"></div>
        <div class="corner top-right" :style="{...defaultStyle}"></div>
        <div class="corner bottom-right" :style="{...defaultStyle}"></div>
        <div class="corner bottom-left" :style="{...defaultStyle}"></div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "grid-box",
        props: {
            width: {
                type: String,
                default: '12px'
            },
            height:{
                type: String,
                default: '3px'
            },
            color: {
                type: String,
                default: '#23B9FF'
            },
            border: {
                type: String,
                default: '1px solid rgba(35, 185, 255, 0.1)'
            }
        },
        computed: {
            defaultStyle() {
                return {
                    width: this.width,
                    height: this.width,
                    fontSize: this.height,
                    color: this.color
                }
            },
        }
    }
</script>

<style lang="less">
    .grid-box {
        position: relative;
    }
    .corner {
        position: absolute;
        &.top-left {
            top: -1px;
            left: -1px;
            &::before,
            &::after {
                top: 0;
                left: 0;
            }
        }
        &.top-right {
            top: -1px;
            right: -1px;
            &::before,
            &::after {
                top: 0;
                right: 0;
            }
        }
        &.bottom-right {
            right: -1px;
            bottom: -1px;
            &::before,
            &::after {
                right: 0;
                bottom: 0;
            }
        }
        &.bottom-left {
            left: -1px;
            bottom: -1px;
            &::before,
            &::after {
                left: 0;
                bottom: 0;
            }
        }
        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            background-color: currentColor;
        }
        &::before {
            content: "";
            width: 100%;
            height: 1em;
        }
        &::after {
            content: "";
            width: 1em;
            height: 100%;
        }
    }
</style>
