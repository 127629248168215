export default {
    title: {
        text: '预约次数/次',
        top: 0,
        left: 0,
        textStyle: {
            fontSize: 12,
            color: '#6791FF'
        }
    },
    grid: {
        top: 48,
        left: 10,
        right: 20,
        bottom: 0,
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: ['单独延生\n普佛', '单独往生\n普佛', '众信延生\n普佛', '众信往生\n普佛', '礼忏诵经\n（延生）', '礼忏诵经\n（往生）', '焰口\n一大士', '焰口\n三大士'],
        axisTick: {
            show: false,
        },
        axisLabel: {
            interval: 0,
            margin: 12,
            fontSize: 16,
            color: '#fff',
            lineHeight: 24
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(0, 245, 255, 0.6)'
            }
        }
    },
    yAxis: {
        type: 'value',
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 12,
            color: '#6791FF'
        },
        axisLine: {
            show: false,
        },
        splitLine: {
            lineStyle: {
                type: 'dashed',
                color: 'rgba(104, 134, 219, 0.5)'
            }
        }
    },
    series: [
        {
            data: [],
            name: '预约次数',
            type: 'bar',
            barWidth: 12,
            silent: true,
            itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(57,206,255,1)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(45,72,173,0.1)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            }
        },
        {
            data: [],
            type: 'bar',
            barWidth: 12,
            silent: true,
            barGap: "-4%",
            itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(36,201,255,1)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(20,43,128,0.2)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            }
        },
        {
            type: "pictorialBar",
            symbolPosition: "end",
            data: [],
            symbol: "diamond",
            symbolOffset: ["0%", "-50%"],
            symbolSize: [24, 12],
            z: 3,
            itemStyle: {
                color: "#00DEFF",
            },
            label: {
                show: true,
                position: "top",
                distance: 8,
                fontSize: 14,
                color: '#00DEFF',
                lineHeight: 20
            }
        }
    ]
}
