<template>
  <div class="opinion-chart-box" :class="[`opinion-chart-${type}`]">
    <div class="opinion-chart"></div>
    <div class="opinion-chart-legend">
      <div class="opinion-chart-tab">
        <div
          class="opinion-tab-item"
          :class="{'opinion-tab-active': i === tab}"
          @click="tab = i"
          v-for="(item, i) in list || []"
          :key="i">
          <div class="opinion-tab-title">{{ item.name }}</div>
        </div>
      </div>
      <div class="opinion-data-list">
        <div class="opinion-data-item" v-for="(item, i) in opinionData" :key="i">{{ item.name }} {{item.num}}</div>
      </div>
    </div>
  </div>
</template>

<script>

    import * as echarts from "echarts";
    import cry from "../../../screen/cry";
    import {clone} from "../../../common/js/tool";

    export default {
        name: "opinion-data",
        props: {
            list: Array,
            total: Number,
            type: {
                type: String,
                default: "normal"
            }
        },
        data() {
            return {
                tab: 0,
                chartData: [],
                chart: null,
                timer: 0
            }
        },
        computed: {
            baseOptions() {
                const res = clone(cry);
                if(this.type == "compact") {
                    res.title.top = 42;
                    res.title.left = '20%';
                    res.series[0].radius = [48, 52];
                    res.series[0].center = ['20%', '50%']
                    res.series[1].radius = 44;
                    res.series[1].center = ['20%', '50%']
                    res.series[2].radius = 38;
                    res.series[2].center = ['20%', '50%']
                }
                return res;
            },
            opinionData() {
                const d = this.list[this.tab];
                return d && d.data ? d.data : [];
            }
        },
        watch: {
            list() {
                this.updateChart();
            }
        },
        mounted() {
            this.updateChart();
            this.startPoll();
        },
        beforeDestroy() {
            this.timer && clearInterval(this.timer);
        },
        methods: {
            startPoll() {
                this.timer = setInterval(() => {
                    const tab = this.tab;
                    const list = this.list || [];
                    this.tab = tab == list.length - 1 ? 0 : tab + 1;
                }, 5000);
            },
            updateChart() {
                const list = this.list;
                if(!list) return;
                const data = [];
                let total = 0;
                list.forEach((item) => {
                    item.data.forEach(c => {
                        total += c.num || 0;
                        data.push({ value: c.num, name: c.name });
                    });
                });
                const options = { ...this.baseOptions }
                options.series[0].data = data;
                options.title.text = this.total || total;
                if(!this.chart) {
                    this.chart = echarts.init(this.$el.querySelector('.opinion-chart'));
                }
                this.chart.setOption(options);
            }
        }
    }
</script>

<style lang="less">
  .opinion-chart-box {
    position: relative;
    height: 210px;
    &.opinion-chart-compact {
      height: 107px;
      .opinion-chart-legend {
        top: -6px;
        bottom: -6px;
        flex-direction: row;
        width: 300px;
      }
      .opinion-chart-tab {
        flex-direction: column;
        justify-content: center;
      }
      .opinion-tab-item {
        width: 74px;
        &:not(:first-of-type) {
          margin-top: 4px;
        }
      }
      .opinion-data-list {
        margin-left: 16px;
        min-width: 0;
      }
    }
    &.opinion-chart-normal {
      .opinion-tab-item {
        flex: 1;
        &:not(:first-of-type) {
          margin-left: 4px;
        }
      }
      .opinion-data-list {
        margin-top: 8px;
      }
    }
  }
  .opinion-chart {
    height: 100%;
  }
  .opinion-chart-legend {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 12px;
    right: 4px;
    bottom: 0;
    width: 224px;
  }
  .opinion-chart-tab {
    display: flex;
  }
  .opinion-tab-item {
    position: relative;
    min-width: 0;
    opacity: 0.5;
    cursor: pointer;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid #66FFFF;
      transform: skewX(-15deg);
    }
    &.opinion-tab-active {
      opacity: 1;
    }
  }
  .opinion-tab-title {
    height: 26px;
    font-size: 12px;
    text-align: center;
    line-height: 26px;
    color: #6FFFFF;
    font-weight: 700;
  }
  .opinion-data-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 3px 12px;
    min-height: 0;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(2px);
    overflow-y: scroll;
  }
  .opinion-data-item {
    padding: 3px 0;
    width: 50%;
    height: 18px;
    font-size: 12px;
  }
</style>
