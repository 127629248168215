<template>
  <div class="cones" :style="{width: `${width}px`, height: `${maxHeight}px`}" v-if="height">
    <svg :width="`${width}px`" :height="`${maxHeight}px`" version="1.1" xmlns="http://www.w3.org/2000/svg" v-if="height">
      <defs>
        <linearGradient :id="`cones_${index}_left`" x1="0%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" :stop-color="color[0].top" stop-opacity="1"/>
          <stop offset="100%" :stop-color="color[0].bottom" stop-opacity="1"/>
        </linearGradient>
        <linearGradient :id="`cones_${index}_right`" x1="0%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" :stop-color="color[1].top" stop-opacity="1"/>
          <stop offset="100%" :stop-color="color[1].bottom" stop-opacity="1"/>
        </linearGradient>
        <filter :id="`cones_${index}_filter`">
          <!-- 投影偏移 -->
          <feOffset dx="0" dy="0"/>
          <!-- 投影模糊 -->
          <feGaussianBlur stdDeviation="6" result="offset-blur"/>
          <!-- 反转投影使其变成内投影 -->
          <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
          <!-- 内投影附加黑色 -->
          <feFlood flood-color="black" flood-opacity=".3" result="color"/>
          <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
          <!-- 把内投影显示在图像上 -->
          <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
        </filter>
      </defs>
      <polygon
        fill-rule="evenodd"
        :points="leftPoints"
        :filter="`url(#cones_${index}_filter)`"
        :fill="`url(#cones_${index}_left)`"></polygon>
      <polygon
        fill-rule="evenodd"
        :points="rightPoints"
        :filter="`url(#cones_${index}_filter)`"
        :fill="`url(#cones_${index}_left)`"></polygon>
    </svg>
  </div>
</template>

<script>
    let index = 0;
    export default {
        name: "cones",
        props: {
            height: Number,
            width: Number,
            angle: {
                type: Number,
                default: 15
            },
            color: Array
        },
        data() {
            return {
                index
            }
        },
        computed: {
            maxHeight() {
                return this.height + this.width / 2 * Math.atan(this.angle * Math.PI / 180);
            },
            leftPoints() {
                const middle = this.width / 2;
                const y = this.height;
                const by = this.maxHeight;
                return `0 ${y}, ${middle} 0, ${middle} ${by}`;
            },
            rightPoints() {
                const middle = this.width / 2;
                const y = this.height;
                const by = this.maxHeight;
                return `${this.width} ${y}, ${middle} 0, ${middle} ${by}`;
            }
        },
        created() {
            index += 1;
        }
    }
</script>

<style scoped>

</style>
