export default {
    color: ["#6DFBFC", "#579DEE", "#5B72BA", "#22AFE0", "#BCBE62", "#A98A70", "#6BD274"],
    title: {
        top: 105,
        left: "18%",
        text: 0,
        subtext: "热点总数",
        textAlign: "center",
        textVerticalAlign: "middle",
        padding: 0,
        itemGap: 0,
        textStyle: {
            fontSize: 16,
            color: "#ffffff",
            lineHeight: 24
        },
        subtextStyle: {
            color: "#3F8AFF",
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 18
        }
    },
    series: [
        {
            name: '热点关注',
            type: 'pie',
            radius: [54, 60],
            center: ['18%', '55%'],
            avoidLabelOverlap: false,
            labelLine: {show: false},
            label: {show: false},
            data: [],
        },
        {
            type: 'pie',
            radius: 48,
            center: ['18%', '55%'],
            avoidLabelOverlap: false,
            labelLine: {show: false},
            label: {show: false},
            data: [{value: 100, itemStyle: {color: "rgba(7, 69, 138, 0.2)"}}],
        },
        {
            type: 'pie',
            radius: 42,
            center: ['18%', '55%'],
            avoidLabelOverlap: false,
            labelLine: {show: false},
            label: {show: false},
            data: [{value: 100, itemStyle: {color: "rgba(7, 69, 138, 0.5)"}}],
        }
    ]
};
