export default {
    series: [
        {
            name: '志工性别',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: false,
            startAngle: 180,
            label: {
                show: true,
                formatter: '{b}：\n{d}%',
                color: '#fff'
            },
            labelLine: {
                show: false,
                length2: 2
            },
            data: [
                { value: 235, name: '男' },
                { value: 189, name: '女' },
            ],
            color: [
                {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#c897ff' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#a388ff' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
                {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#00ddeb' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#008ad9' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
            ]
        }
    ]
};
