const day = new Date();
const months = [];
let num = 6;
do {
    months.push(`${day.getMonth() + 1}月`);
    day.setMonth(day.getMonth() - 1);
    num -=1;
} while (num > 0);
months.reverse();

export default {
    color: ['#3fd1fd', '#24cfb5', '#ffab06'],
    legend: {
        top: 10,
        right: 40,
        icon: 'roundRect',
        itemWidth: 8,
        itemHeight: 8,
        lineStyle: {
            width: 8,
        },
        itemStyle: {
            borderWidth: 1,
            borderColor: '#fff'
        },
        textStyle: {
            color: '#fff',
            fontSize: 14
        }
    },
    grid: {
        top: 80,
        left: 0,
        right: 40,
        bottom: 0,
        containLabel: true
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: months,
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 14,
            color: '#b1b6ec'
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(0,185,228,0.3)'
            }
        }
    },
    yAxis: {
        type: 'value',
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 16,
            color: '#b1b6ec'
        },
        axisLine: {
            show: true,
            lineStyle: {
                color: 'rgba(0,185,228,0.3)'
            }
        },
        splitLine: {
            lineStyle: {
                color: 'rgba(0,185,228,0.6)'
            }
        }
    },
    series: [
        {
            name: '口罩',
            data: [820, 932, 901, 934, 1290, 1330],
            type: 'line',
            symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAYAAAArzdW1AAAAMUlEQVQYlWNkgIL/////Z0ADjIyMYHkWZGHGWz1w9n+1EjibCV03NjAYFREOAgYGBgC35gsR5AUO4AAAAABJRU5ErkJggg==",
            symbolSize: 8,
            lineStyle: {
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [{
                        offset: 0, color: '#00fff6' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#40d0fd' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            },
            smooth: true
        },
        {
            name: '酒精',
            data: [525, 695, 756, 956, 796, 652],
            type: 'line',
            symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAYAAAArzdW1AAAAMUlEQVQYlWNkgIL/////Z0ADjIyMYHkWZGHlCQh1dwvg+hmY0HVjA4NREeEgYGBgAAC7agsRqXw5QwAAAABJRU5ErkJggg==",
            symbolSize: 8,
            lineStyle: {
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [{
                        offset: 0, color: '#34dca3' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#1bc7c0' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            },
            smooth: true
        },
        {
            name: '消毒液',
            data: [656, 452, 896, 791, 992, 1023],
            type: 'line',
            symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAYAAAArzdW1AAAAMUlEQVQYlWNkgIL/////Z0ADjIyMYHkWZOGv8SpwNvfCO3A2E7pubGAwKiIcBAwMDAC4sgsRNjU38wAAAABJRU5ErkJggg==",
            symbolSize: 8,
            lineStyle: {
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [{
                        offset: 0, color: '#fff7a0' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#ffa800' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            },
            smooth: true
        }
    ]
};
