const pieConfig = {
    type: 'pie',
    radius: ['60%', '70%'],
    startAngle: -45,
    clockwise: false,
    label: {
        padding: [0, -36],
        fontSize: 10,
        lineHeight : 16,
        color: '#fff',
        formatter: '{b}{c}\n',
        textShadowColor: 'rgba(0,0,0,0.5)',
        textShadowBlur: 10,
        distanceToLabelLine: 0,
    },
    labelLine: {
        length: 8,
        length2: 36,
    },
}

const options = {
    color: ['#6EFFA9', '#109345', '#40639A'],
    series: [
        {
            ...pieConfig,
            center: ['25%', '50%'],
            data: [
                {value: 0, name: '在寺', itemStyle: {color: '#6EFFA9'}},
                {value: 0, name: '请假', itemStyle: {color: '#109345'}},
                {value: 0, name: '外派', itemStyle: {color: '#40639A'}},
            ]
        },
        {
            ...pieConfig,
            center: ['50%', '50%'],
            data: [
                {value: 0, name: '在寺', itemStyle: {color: '#FADE81'}},
                {value: 0, name: '请假', itemStyle: {color: '#AC8400'}},
                {value: 0, name: '外派', itemStyle: {color: '#40639A'}},
            ]
        },
        {
            type: 'pie',
            radius: ['60%', '70%'],
            startAngle: 0,
            clockwise: false,
            label: {
                show: false
            },
            center: ['75%', '50%'],
            data: [
                {value: 0, name: '在寺', itemStyle: {color: '#a2dff3'}},
            ]
        }
    ]
}

export default options;
