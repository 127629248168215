<template>
  <span class="roll-num">{{showNum.toFixed(digit)}}</span>
</template>

<script>
    import gsap from "gsap";
    export default {
        name: "RollNumber",
        props: {
            num: [String, Number],
            digit: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                showNum: 0,
            }
        },
        watch: {
            num() {
                this.roll();
            }
        },
        mounted() {
            this.roll();
        },
        methods: {
            roll() {
                gsap.to(this.$data, { duration: 1.5, showNum: this.num * 1 });
            }
        }
    }
</script>

<style>
  .roll-num {
  }
</style>
