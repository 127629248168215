<template>
    <div class="screen-area">
        <div class="screen-box" v-if="key">
            <div class="screen-top">
                <div class="top-date" v-if="date">
                    <div>{{date}}</div>
                    <div>{{lunar}}</div>
                </div>
                <div class="top-remind" v-if="false">
                    <img src="../screen/image/remind.png" class="top-remind-icon">
                    <div class="top-remind-text">{{remind}}</div>
                </div>
                <div class="flex-box sub-screen-left">
                    <div class="sub-screen" :class="{select: key == s.component}" v-for="s in subScreen.left" :key="s.component" @click="handleSubClick(s)">{{s.name}}</div>
                </div>
                <div class="flex-box sub-screen-right">
                    <div class="sub-screen" :class="{select: key == s.component}" v-for="s in subScreen.right" :key="s.component" @click="handleSubClick(s)">{{s.name}}</div>
                </div>
            </div>
            <Complex
                :data="data"
                :count="touristCount"
                @remind="handleRemind"
                @waring="handleWaring"
                @notice="handleNotice"
                @safety="handleSafetyPop"
                v-if="key == 'complex'"></Complex>
            <OA :data="data" v-else-if="key == 'oa'"></OA>
            <HR :data="data" v-else-if="key == 'hr'"></HR>
            <buddha :data="data" v-else-if="key == 'buddha'"></buddha>
            <safety :data="data" :count="touristCount" @waiting="handleWaiting" @safety="handleSafetyPop" v-else-if="key == 'safe'"></safety>
            <Asset :data="data" v-else-if="key == 'asset'"></Asset>
            <Culture :data="data" v-else-if="key == 'culture'"></Culture>
            <school-complex :data="data" v-else-if="key == 'schoolComplex'"></school-complex>
            <Data :type="key" @remind="handleRemind" v-else></Data>
            <div class="screen-data-time" v-if="serveTime"><span>数据截止时间：</span>{{serveTime}}</div>
        </div>
        <div class="screen-warning-notice" :class="getNoticeClass()" v-if="notice">
          <div class="icon-notice warning-notice-icon"></div>
          <div class="warning-notice-info">
            <div class="warning-notice-title">{{ notice.title }}</div>
            <div class="warning-notice-text">{{ notice.text }}</div>
          </div>
        </div>
        <div class="screen-warning" v-show="safetyPopShow">
            <div class="screen-warning-back" @click="hideSafetyPop"></div>
            <safety-data :type="safetyPopType" @hide="hideSafetyPop" v-if="safetyPopType > 0"></safety-data>
            <div class="screen-warning-waiting" v-else></div>
        </div>
        <div class="screen-warning" v-show="warning !== null">
            <div class="screen-warning-box" :class="getWaringClass()" v-if="warning">
                <div class="screen-warning-border">
                    <div class="border-left-top"></div>
                    <div class="border-right-top"></div>
                    <div class="border-right-bottom"></div>
                    <div class="border-left-bottom"></div>
                </div>
                <div class="screen-warning-title">{{ warning.title }}</div>
                <div class="screen-warning-content">{{ warning.text }}</div>
                <div class="icon-warning screen-warning-icon"></div>
                <div class="icon-close screen-warning-close" @click="hideWaring"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import config from "../common/js/config";
    import Data from "./screen/Data";
    import Complex from "./screen/Complex";
    import OA from "./screen/OA";
    import HR from "./screen/HR";
    import Buddha from "./screen/Buddha";
    import Safety from "./screen/Safety";
    import Asset from "./screen/Asset";
    import Culture from "./screen/Culture";
    import schoolComplex from "./screen/school/Complex";
    import safetyData from "./screen/graph/safety-data";
    import {getStorageInfo} from "../common/js/storage";

    const WARNING_GAP = 60 * 60 * 1000; // 提示时间间隔
    const time = {}

    export default {
        name: "Screen",
        components: {
            Complex,
            Data,
            OA,
            HR,
            Buddha,
            Safety,
            Asset,
            Culture,
            schoolComplex,
            safetyData
        },
        data() {
            return {
                date: null,
                lunar: null,
                remind: null,
                data: null,
                touristCount: null,
                power: null,
                warning: null,
                notice: null,
                safetyPopType: 0,
                safetyPopShow: false,
                timer: 0,
                countTimer: 0,
                serveTime: "",
                templeId: getStorageInfo().temple.crop_id
            }
        },
        computed: {
            //是否是佛学院
            isSchool() {
                return this.templeId === "wpLnbtCQAAIAZrNVQl2p3Zw1SwYEDz0Q";
            },
            defaultKey() {
                let res = "complex";
                if(this.isSchool) {
                    res = "schoolComplex";
                }
                return res;
            },
            subScreen() {
                let res = {
                    left: [
                        {component: 'complex', name: '综合大屏'},
                        {component: 'oa', name: '和合共住'},
                        {component: 'hr', name: '学修评价'},
                        {component: 'buddha', name: '佛事活动'},
                    ],
                    right: [
                        {component: 'safe', name: '平安寺院'},
                        {component: 'asset', name: '丛林库房'},
                        {component: 'culture', name: '走进灵隐'},
                    ]
                };
                if(this.isSchool) {
                    res = {
                        left: [
                            {component: 'schoolComplex', name: '综合大屏'},
                            {component: 'lesson', name: '智慧课堂'},
                            {component: 'hr', name: '学修评价'},
                        ],
                        right: [
                            {component: 'safe', name: '平安校园'},
                            {component: 'oa', name: '和合共住'},
                            {component: 'culture', name: '走进杭佛'},
                        ]
                    }
                }
                return res;
            },
            key() {
                return this.$route.query.sub || this.defaultKey;
            },
        },
        created() {
            this.interval();
            // 游客人数统计单独调用
            this.getTouristCount();
        },
        beforeDestroy() {
            this.clearTimer();
        },
        methods: {
            handleWaiting() {
                this.safetyPopType = 0;
                this.safetyPopShow = true;
            },
            handleSafetyPop(type) {
                this.safetyPopType = type;
                this.safetyPopShow = true;
            },
            hideSafetyPop() {
                this.safetyPopShow = false;
            },
            handleNotice(notice) {
                if(notice) {
                  const t = time[notice.id] || 0;
                  if(Date.now() - t > WARNING_GAP) {
                    if(notice.audio) {
                      const url = `https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/assets/oa/${notice.audio}.mp3`;
                      const audio = new Audio(url);
                      audio.play();
                      time[notice.id] = Date.now();
                    }
                  }
                }
                this.notice = notice;
            },
            getNoticeClass() {
                let notice = this.notice;
                let res = [];
                if(notice.classes) {
                    res.push(...notice.classes)
                } else if(notice.type) {
                    res.push(`warning-notice-${notice.type}`);
                }
                return res;
            },
            handleWaring(warning) {
                if(warning) {
                    const t = time[warning.id] || 0;
                    if(Date.now() - t > WARNING_GAP) {
                        if(warning.audio) {
                          const url = `https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/assets/oa/${warning.audio}.mp3`;
                          const audio = new Audio(url);
                          audio.play();
                          time[warning.id] = Date.now();
                        }
                        this.warning = warning;
                    }
                } else if(this.warning && this.warning.id == warning.id){
                    this.warning = warning;
                }
            },
            getWaringClass() {
                let waring = this.warning;
                let res = [];
                if(waring.classes) {
                    res.push(...waring.classes)
                } else if(waring.type) {
                    res.push(`screen-waring-${waring.type}`);
                }
                return res;
            },
            hideWaring() {
                this.warning = null;
            },
            handleSubClick(s) {
                if(this.isSchool) {
                    if(s.component == 'lesson') {
                        location.href = "https://onlineroom.csp.chinamcloud.com?tenant_code=1131";
                    }
                } else {
                    this.warning = null;
                    this.notice = null;
                    this.$router.push({name: 'Screen', query: {sub: s.component}});
                }
            },
            setDate(lunar) {
                if(lunar) {
                    let date = new Date(lunar.date)
                    this.date = date.pattern("yyyy年M月d日 EEE");
                    this.lunar = `农历 ${lunar.lunar_month_name + lunar.lunar_day_name}`
                }
            },
            handleRemind(remind) {
                this.remind = remind;
            },
            interval() {
                this.getData().then(() => {
                    this.timer = setTimeout(this.interval, 20000)
                }).catch(() => {
                    this.timer = setTimeout(this.interval, 20000)
                });
            },
            getTouristCount() {
                // 综合 安全 轮询游客统计数据
                if(["complex", "safe"].indexOf(this.key) === -1) {
                    this.countTimer = setTimeout(() => this.getTouristCount(), 3000);
                    return Promise.resolve();
                } else {
                    return axios.get(config.host + "/common/get-rs").then(res => {
                        this.touristCount = res.data;
                    }).finally(() => {
                        this.countTimer = setTimeout(() => this.getTouristCount(), 3000);
                    });
                }
            },
            getData() {
                // 资产数据有额外接口 切换到资产大屏时且已经加载过所有数据 不再去请求数据
                if(this.key == 'asset' && !!this.data) {
                    return Promise.resolve();
                } else {
                    return Promise.all([
                        axios.get(config.host + "/common/get-data").then(res => {
                            const data = res.data;
                            if(this.power) {
                                data.power = this.power;
                            }
                            this.date = new Date().pattern("yyyy年M月d日 EEE")
                            this.data = data;
                            this.serveTime = data.server_time;
                            return data;
                        }),
                        axios(config.host + "/live/get-data").then(res => {
                            const data = res.data;
                            const power = data.elec;
                            if(this.data) {
                                this.data.power = power;
                            } else {
                                this.power = power;
                            }
                            return data;
                        })
                    ])
                }
            },
            clearTimer() {
                if(this.timer) {
                    clearTimeout(this.timer);
                    this.timer = 0;
                }
                if(this.countTimer) {
                    clearTimeout(this.countTimer);
                    this.countTimer = 0;
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .screen-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../screen/image/back.jpg) center no-repeat;
        background-size: cover;
        z-index: 11;
        overflow: auto;
        color: #fff;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        &::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }
    }
    .screen-box {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        width: 1920px;
        height: 1080px;
        white-space: normal;
    }
    .screen-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 125px;
        z-index: 1;
        font-size: 18px;
        font-weight: bold;
        color: #7af9f9;
        line-height: 28px;
    }

    .top-date {
        position: absolute;
        top: 36px;
        right: 40px;
        line-height: 28px;
        text-align: center;
        font-size: 16px;
    }

    .top-remind {
        display: flex;
        align-items: center;
        position: absolute;
        top: 36px;
        right: 40px;
    }

    .top-remind-text {
        margin-left: 20px;
    }
    .sub-screen-left,
    .sub-screen-right {
        position: absolute;
        top: 36px;
    }
    .sub-screen-left {
        left: 116px;
    }
    .sub-screen-right {
        right: 240px;
    }
    .sub-screen {
        width: 100px;
        height: 28px;
        border: 1px solid #7af9f9;
        border-radius: 28px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        line-height: 26px;
        color: #7af9f9;
        cursor: pointer;
        &.select,
        &:hover {
            background-color: #7af9f9;
            color: #2a519a;
        }
        &:not(:first-child) {
            margin-left: 24px;
        }
    }
    .screen-warning {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.5);
        z-index: 1000;
    }
    .screen-warning-back {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.5);
    }
    .screen-warning-box {
        position: relative;
        padding: 32px 0;
        width: 440px;
        background: rgba(15, 28, 49, 0.9);
        border: 1px solid #FF4800;
        box-shadow: 0 4px 20px rgba(0, 245, 255, 0.3), inset 0 4px 20px rgba(255, 72, 0, 0.3), inset 0 -4px 20px rgba(255, 72, 0, 0.3);
        backdrop-filter: blur(4px);
        &.screen-waring-tip {
            border-color: #fee28f;
            box-shadow: 0 4px 20px rgba(254,226,143,0.3), inset 0 4px 20px rgba(254,226,143,0.3), inset 0 -4px 20px rgba(254,226,143,254,226,143,0.3);
            .screen-warning-icon,
            .screen-warning-content {
              color: #fee28f;
            }
        }
        &.screen-waring-warning {
            border-color: #f87141;
            box-shadow: 0 4px 20px rgba(248,113,65,0.3), inset 0 4px 20px rgba(248,113,65,0.3), inset 0 -4px 20px rgba(248,113,65,0.3);
            .screen-warning-icon,
            .screen-warning-content {
                color: #f87141;
            }
        }
        &.screen-waring-danger {
            border-color: #d93327;
            box-shadow: 0 4px 20px rgba(217,51,39,0.3), inset 0 4px 20px rgba(217,51,39,0.3), inset 0 -4px 20px rgba(217,51,39,0.3);
            .screen-warning-icon,
            .screen-warning-content {
                color: #d93327;
            }
        }
    }
    .screen-warning-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 33px;
    }
    .screen-warning-content {
        margin-top: 8px;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #F34139;
    }
    .screen-warning-icon {
        margin: 18px auto 0;
        width: 134px;
        height: 134px;
        font-size: 100px;
        line-height: 134px;
        color: #FF4800;
    }
    .screen-warning-close {
        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 28px;
        cursor: pointer;
    }

    .screen-warning-notice {
      display: flex;
      align-items: center;
      position: absolute;
      top: 88px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 600px;
      color: #03A9FF;
      font-size: 14px;
      line-height: 21px;
      z-index: 2;
      &.warning-notice-danger {
        color: #d93327;
      }
      &.warning-notice-warning {
        color: #f87141;
      }
      &.tip {
        color: #fee28f;
      }
    }
    .warning-notice-icon {
      width: 12px;
      height: 12px;
      font-size: 12px;
      line-height: 12px;
    }
    .warning-notice-info {
      flex: 1;
      display: flex;
      align-items: center;
      margin-left: 4px;
      white-space: nowrap;
    }
    .screen-warning-waiting {
        position: relative;
        width: 466px;
        height: 286px;
        background: url(../screen/image/waiting.png) center / contain no-repeat;
    }
    .screen-data-time {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 12px;
        margin: 0 auto;
        width: fit-content;
        font-size: 14px;
        line-height: 21px;
        span {
            color: #2BFDFA;
        }
    }
</style>
