<template>
  <div class="carousel-container">
    <div class="carousel-list" :class="{'carousel-list-animation': animation}" :style="{transform: `translateY(${offset}px)`}">
      <div class="carousel-item" v-for="(item, i) in showList" :key="i">
        <slot name="item" v-bind="{item, i: item.index, current}"></slot>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "carousel",
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                }
            },
            // 轮播时间间隔 毫秒
            duration: {
                type: Number,
                default: 5000
            },
            // 显示的条目数
            number: {
                type: Number,
                default: 1
            },
            draggable: {
                type: Boolean
            }
        },
        data() {
            return {
                boxHeight: 0,
                itemHeight: 0,
                animation: false,
                index: 0,
                offset: 0,
                timer: null
            }
        },
        computed: {
            arr() {
                const list = [...this.list];
                list.forEach((item, i) => item.index = i);
                return list;
            },
            over() {
                return this.list.length > this.number;
            },
            showList() {
                const index = this.index;
                const list = this.arr;
                let res = [...list];
                if(list.length > this.number) {
                    const number = this.number + 1;
                    res = list.slice(index, index + number);
                    if(res.length < number) {
                        res.push(...list.slice(0, number - res.length));
                    }
                }
                return res;
            },
            current() {
                const arr = this.list;
                let res = this.index;
                if(res > arr.length - 1) {
                    res = res - arr.length + 1;
                }
                return res;
            }
        },
        watch: {
            list() {
                this.$nextTick(() => {
                    this.setItemHeight();
                });
            },
            over() {
                this.setAni();
            },
            index() {
                this.dispatchActive();
            }
        },
        created() {
            this.dispatchActive();
        },
        mounted() {
            const box = this.$el;
            this.boxHeight = box.clientHeight;
            this.setItemHeight();
            this.setAni();
        },
        beforeDestroy() {
            this.clearTimer();
        },
        methods: {
            dispatchActive() {
                this.$emit("active", this.index);
            },
            clearTimer() {
                if(this.timer) {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            },
            setItemHeight() {
                if(this.itemHeight == 0) {
                    const item = this.$el.querySelector(".carousel-item");
                    if(item) {
                        this.itemHeight = item.clientHeight;
                    }
                }
            },
            setAni() {
                this.animation = false;
                this.index = 0;
                this.offset = 0;
                this.clearTimer();
                if(this.over) {
                    this.timer = setInterval(() => {
                        const len = this.arr.length;
                        this.animation = true;
                        this.offset = -1 * this.itemHeight;
                        this.$nextTick(() => {
                            setTimeout(() => {
                                this.animation = false;
                                this.offset = 0;
                                // 轮播完一轮
                                if(this.index == len - 1) {
                                    this.index = 0
                                } else {
                                    this.index += 1;
                                }
                            }, 300)
                        })
                    }, this.duration)
                }
            }
        }
    }
</script>

<style lang="less">
  .carousel-container {
    height: 100%;
    overflow: hidden;
  }
  .carousel-list-animation {
    transition: transform .3s;
  }
</style>
