<template>
  <div class="time-clock">
    <slot name="time" v-bind="{h, m, s}" v-if="$scopedSlots.time"></slot>
    <span v-else>{{showTime}}</span>
  </div>
</template>

<script>

    import {paddingZero} from "../../../common/js/tool";

    export default {
        name: "time-clock",
        props: {
            showSeconds: Boolean
        },
        data() {
            return {
                h: 0,
                m: 0,
                s: 0,
                timer: 0
            }
        },
        computed: {
            showTime() {
                const h = this.h, m = this.m, s = this.s, showSeconds = this.showSeconds;
                return `${paddingZero(h, 2)}:${paddingZero(m, 2)}${showSeconds ? ":" + paddingZero(s, 2) : ""}`;
            }
        },
        created() {
            this.startTimer();
        },
        beforeDestroy() {
            this.clearTimer();
        },
        methods: {
            startTimer() {
                this.timer = setInterval(() => {
                    const date = new Date();
                    this.h = date.getHours();
                    this.m = date.getMinutes();
                    this.s = date.getSeconds();
                }, 1000);
            },
            clearTimer() {
                if(this.timer) {
                    clearInterval(this.timer);
                    this.timer = 0;
                }
            }
        }
    }
</script>

<style scoped>

</style>
