<template>
  <div class="scroll-text">
    <div class="scroll-text-content" :style="{transform: `translateX(${width - left}px)`}">{{text}}</div>
  </div>
</template>

<script>

    export default {
        name: "scroll-text",
        props: {
            text: String,
        },
        data() {
            return {
                width: 0, // 容器宽度
                contentWidth: 0, // 内容宽度
                active: false,
                start: 0,
                interval: 0,
                speed: 0.112, // 1ms 移动的像素
            }
        },
        computed: {
            left() {
                return this.interval * this.speed;
            },
        },
        watch: {
            text() {
                this.init();
            }
        },
        mounted() {
            this.init();
        },
        beforeMount() {
            this.observer && this.observer.disconnect();
        },
        methods: {
            init() {
                this.setBoxWidth();
                this.startScroll();
            },
            setBoxWidth() {
                const dom = this.$el;
                this.width = dom.clientWidth;
                this.contentWidth = dom.scrollWidth;
                this.active = this.contentWidth > this.width;
            },
            scroll(current) {
                if(!this.active) return;
                if (!this.start) {
                    this.start = current;
                }
                this.interval = current - this.start;
                if (this.interval * this.speed < this.contentWidth + this.width) {
                    window.requestAnimationFrame(current => this.scroll(current));
                } else {
                    this.interval = 0;
                    this.start = 0;
                    this.startScroll();
                }
            },
            startScroll() {
                if(!this.active) return;
                window.requestAnimationFrame(current => this.scroll(current));
            }
        }
    }
</script>

<style lang="less">
.scroll-text {
  white-space: nowrap;
  overflow: hidden;
}
</style>
