import { render, staticRenderFns } from "./Safety.vue?vue&type=template&id=695d5b3b&scoped=true&"
import script from "./Safety.vue?vue&type=script&lang=js&"
export * from "./Safety.vue?vue&type=script&lang=js&"
import style0 from "./Safety.vue?vue&type=style&index=0&id=695d5b3b&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "695d5b3b",
  null
  
)

export default component.exports