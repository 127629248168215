<template>
  <div class="scroll-word">
    <div class="scroll-word-box">
      <div class="scroll-word-item">{{ text }}</div>
      <div class="scroll-word-item" v-if="scroll">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "scroll-word",
  props: {
    text: String
  },
  data() {
    return {
      width: 0,
      w: 0,
      offset: 0,
      start: 0,
      scroll: false
    }
  },
  watch: {
    text() {
      this.$nextTick(this.setWidth);
    }
  },
  mounted() {
    this.setWidth();
  },
  methods: {
    setWidth() {
      if(this.width == 0) {
        this.width = this.$el.clientWidth;
      }
      const dom = this.$el.querySelector(".scroll-word-item");
      this.w = dom.clientWidth;
      if(this.w > this.width) {
        this.startScroll();
      }
    },
    startScroll() {
      this.scroll = true;
      requestAnimationFrame(this.step)
    },
    step(timestamp) {
      if(this.start == 0) {
        this.start = timestamp;
      }
      const elapsed = timestamp - this.start;
      const length = 0.05 * elapsed;
      if(length >= this.w) {
        this.offset = 0;
        this.start = timestamp;
      } else {
        this.offset = length;
      }
      this.$el.querySelector(".scroll-word-box").style.transform = `translateX(${-1 * this.offset}px)`;
      requestAnimationFrame(this.step);
    }
  }
}
</script>

<style lang="less">
.scroll-word {
  overflow: hidden;
}
.scroll-word-box {
  white-space: nowrap;
}
.scroll-word-item {
  display: inline-block;
}
</style>