<template>
    <div class="flex-box screen">
        <div class="top-banner"></div>
        <div class="hr-left">
            <div class="screen-piece">
                <div class="screen-piece-title">
                    <img src="../../screen/hr/image/ryzt-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">人员状态</div>
                </div>
                <div class="hr-member">
                    <div class="hr-member-item" v-for="(m, i) in memberData" :key="i">
                      <div class="hr-member-total">应到<span class="hr-total-num" :style="{color: m.color}">{{m.num}}</span></div>
                      <div class="hr-member-label">
                          <div class="hr-member-icon" :style="{backgroundColor: m.color}"></div>
                          <div class="hr-member-text">{{m.name}}</div>
                        </div>
                      <div class="hr-member-other" v-if="i == 2">今日志工到岗</div>
                    </div>
                  <div class="hr-member-chart" id="hr_member_chart"></div>
                </div>
            </div>
            <div class="screen-piece" style="margin-top: 20px;">
                <div class="screen-piece-title">
                    <img src="../../screen/hr/image/xwfx-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">行为分析</div>
                </div>
                <div class="hr-action">
                    <div class="hr-action-row">
                        <div class="hr-action-item" :class="{'hr-action-long': a.percent !== undefined}" v-for="(a, i) in action" :key="i">
                            <div class="hr-action-title">{{a.title}}</div>
                            <div class="hr-action-detail">
                                <div class="hr-action-chart">
                                    <div class="action-chart-item" v-for="(b, j) in a.list" :key="j">
                                        <div class="action-chart-num">NO.{{j + 1}}</div>
                                        <div class="action-chart-label">{{b.name}}</div>
                                        <div class="action-chart-bar">
                                            <div class="chart-bar-box" :style="{width: `${b.per}%`}"></div>
                                            <div class="chart-bar-num" v-if="a.unit">{{b.count + a.unit}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="hr-action-ball" v-if="a.wave">
                                    <svg width="72px" height="72px" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient :id="`wg_${i}`" x1="0%" x2="0%" y1="100%" y2="0%">
                                                <stop offset="0%" :stop-color="a.wave[0]" stop-opacity="1"/>
                                                <stop offset="100%" :stop-color="a.wave[1]" stop-opacity="1"/>
                                            </linearGradient>
                                        </defs>
                                        <!-- Wave -->
                                        <g :id="`wave_${i}`">
                                            <path :id="`wave_${i}`"
                                                  fill-rule="evenodd"
                                                  :fill="`url(#wg_${i})`"
                                                  :d="a.path">
                                            </path>
                                        </g>
                                        <text class="reception-num-text" alignment-baseline="middle" text-anchor="middle" font-size="18px" x="36" y="36" style="fill: #fff">{{a.percent}}%</text>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="screen-piece">
                <div class="screen-piece-title title-line-long">
                    <img src="../../screen/hr/image/bdtj-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">人员变动统计</div>
                </div>
                <div class="hr-change">
                    <div class="hr-change-piece">
                        <div class="hr-change-all" id="all_change_chart"></div>
                        <div class="change-all-legend">
                            <div class="all-legend-item" v-for="(l, i) in change" :key="i">
                                <div class="all-legend-icon"></div>
                                <div class="all-legend-text">{{l.name + l.value}}人</div>
                            </div>
                        </div>
                    </div>
                    <div class="hr-change-piece">
                        <div class="hr-change-chart" id="sz_change_chart"></div>
                        <div class="hr-change-legend">
                            <div class="change-legend-icon"></div>
                            <div class="change-legend-text">僧众变动统计</div>
                        </div>
                    </div>
                    <div class="hr-change-piece">
                        <div class="hr-change-chart" id="zg_change_chart"></div>
                        <div class="hr-change-legend">
                            <div class="change-legend-icon"></div>
                            <div class="change-legend-text">职工变动统计</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hr-right">
            <div class="screen-piece">
                <div class="screen-piece-title">
                    <img src="../../screen/hr/image/kptj-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">评价统计</div>
                </div>
                <div class="hr-ass">
                    <div class="hr-ass-title">
                        <div class="hr-ass-th">开始时间</div>
                        <div class="hr-ass-th">评价名称</div>
                        <div class="hr-ass-th">评价类型</div>
                        <div class="hr-ass-th">评价状态</div>
                        <div class="hr-ass-th">结束时间</div>
                        <div class="hr-ass-th">评价人数</div>
                    </div>
                    <div class="hr-ass-list" v-if="assList && assList.length > 0">
                        <div class="hr-ass-item" :class="[`hr-ass-${i % 2}`]" v-for="(n, i) in assList" :key="i">
                            <div class="hr-ass-td">{{n.start}}</div>
                            <div class="hr-ass-td">{{n.title}}</div>
                            <div class="hr-ass-td">{{n.typeText}}</div>
                            <div class="hr-ass-td">{{n.statusText}}</div>
                            <div class="hr-ass-td">{{n.end}}</div>
                            <div class="hr-ass-td">{{n.numText}}</div>
                        </div>
                    </div>
                    <div class="hr-ass-list" v-else-if="assList">
                        <div class="hr-ass-item hr-ass-0 hr-ass-full">
                            <div class="hr-ass-td">暂无数据</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="screen-piece">
                <div class="screen-piece-title title-line-long">
                    <img src="../../screen/hr/image/kh-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">评价 TOP5</div>
                </div>
                <div class="hr-kh-rank">
                    <div class="kh-rank-area" v-for="(r, i) in rankData" :key="i">
                        <div class="rank-area-top">
                            <a-icon class="rank-top-icon" type="left" @click="khChange(r, -1)" v-if="r.list.length > 1"/>
                            <div class="rank-area-title ellipsis">{{r.list.length > 0 ? r.list[r.index].title : r.title}}</div>
                            <a-icon class="rank-top-icon" type="right" @click="khChange(r, 1)" v-if="r.list.length > 1"/>
                        </div>
                        <div class="kh-rank-list" v-if="r.list.length > 0">
                            <div class="kh-rank-item" v-for="(item, j) in r.list[r.index].score" :key="j">
                                <div class="kh-rank-info">
                                    <div class="kh-rank-num">
                                        <img :src="require(`../../screen/hr/image/rank-${j + 1}.png`)" class="kh-rank-img" v-if="j < 3">
                                        <span v-else>NO.{{j + 1}}</span>
                                    </div>
                                    <open-data type="userName" :openid="item.user_id" v-if="r.list[r.index].program_type == 1"/>
                                    <open-data type="departmentName" :openid="item.dept_id" v-if="r.list[r.index].program_type == 2"/>
                                </div>
                                <div class="kh-rank-score" :class="[`rank-bar-${j}`]">
                                    <div class="rank-score-bar">
                                        <div class="score-bar-box" :style="{width: `${item.per}%`}"><div class="score-bar-dot"></div></div>
                                    </div>
                                    <div class="rank-score-num">{{item.score}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="kh-rank-list" v-else>
                            <div class="kh-rank-empty">暂无数据</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-box" style='padding-bottom: 20px;'>
                <div class="flex-grow screen-piece">
                    <div class="screen-piece-title">
                        <img src="../../screen/hr/image/pxjh-icon.png" class="piece-title-icon"/>
                        <div class="piece-title-text">培训计划</div>
                    </div>
                    <div class="flex-box">
                        <div class="hr-piece-top">
                            <div class="hr-piece-item">
                                <div class="hr-piece-label">计划</div>
                                <div class="hr-piece-num">300</div>
                            </div>
                            <div class="hr-piece-item">
                                <div class="hr-piece-label">实到</div>
                                <div class="hr-piece-num">240</div>
                            </div>
                        </div>
                        <div class="hr-train">
                            <div class="hr-train-chart" id="hr_train_chart"></div>
                            <div class="hr-train-num">80%</div>
                        </div>
                    </div>
                </div>
                <div class="flex-grow screen-piece">
                    <div class="screen-piece-title">
                        <img src="../../screen/hr/image/srgh-icon.png" class="piece-title-icon"/>
                        <div class="piece-title-text">生日关怀</div>
                    </div>
                    <div class="flex-box hr-birth">
                        <div class="hr-piece-top">
                            <div class="hr-piece-item">
                                <div class="hr-piece-label">本月</div>
                                <div class="hr-piece-num">3</div>
                            </div>
                            <div class="hr-piece-item">
                                <div class="hr-piece-label">下月</div>
                                <div class="hr-piece-num">5</div>
                            </div>
                        </div>
                        <div class="hr-birth-list">
                            <div class="hr-birth-item" v-for="(b, i) in birthList" :key="i">
                                <div class="hr-birth-name">{{b.name}}</div>
                                <div class="hr-birth-date">{{b.date}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import member from "../../screen/hr/member";
    import {all, bar} from "../../screen/hr/change";
    import {clone, getDate} from "../../common/js/tool";
    import {getAssListStatusText, getAssTypeName} from "../../common/hr/ass";
    import train from "../../screen/hr/train";
    import {getMonthDays} from "../../components/calendar/date";

    export default {
        name: "Hr",
        props: {
            data: Object
        },
        data() {
            return {
                member: [0,0,0],
                memberData: [
                  {name: '僧众', color: '#5d42f3', num: 0},
                  {name: '职工', color: '#008cd9', num: 0},
                  {name: '志工', color: '#b08eff', num: 0},
                ],
                action: [
                    {
                        title: '累计交办',
                        unit: '次',
                        list: [
                            // {name: '光海', num: 60},
                            // {name: '静幻', num: 40},
                            // {name: '道一', num: 20},
                        ]
                    },
                    {
                        title: '汇报排行',
                        unit: '次',
                        list: [
                            // {name: '3次', num: 0},
                            // {name: '2次', num: 0},
                            // {name: '1次', num: 0},
                        ],
                        percent: 0,
                        // wave: ['rgb(29,67,243)', 'rgb(67,102,243)']
                    },
                    {
                        title: '响应效率',
                        list: [
                            // {name: '法印', num: 90},
                            // {name: '镇法', num: 80},
                            // {name: '智光', num: 70},
                        ]
                    },
                    {
                        title: '请假排行',
                        unit: '人',
                        list: [
                            // {name: '1天', num: 8},
                            // {name: '2天', num: 3},
                            // {name: '3天', num: 2},
                        ],
                        percent: 51,
                        // wave: ['rgb(56,160,214)', 'rgb(109,205,230)']
                    }
                ],
                assList: null,
                rankData: [
                    {
                        title: '个人评价',
                        index: 0,
                        list: []
                    },
                    {
                        title: '部门评价',
                        index: 0,
                        list: []
                    }
                ],
                change: [],
                birthList: [
                    {name: '心法', date: ''},
                    {name: '自愚', date: ''},
                    {name: '见闻', date: ''},
                ]
            }
        },
        watch: {
            data() {
                this.setData();
            }
        },
        mounted() {
            this.setData();
        },
        methods: {
            setData() {
                let data = this.data;
                if(data) {
                    this.setMemberData();
                    this.setActionData();
                    this.setChangeData(data);
                    this.setAssData();
                    this.setTrainData();
                    this.setBirthData();
                }
            },
            setBirthData() {
                const now = new Date();
                const y = now.getFullYear(), m = now.getMonth();
                const data = localStorage.getItem('birth_data');
                let days;
                if(data) {
                    try {
                        const temp = JSON.parse(data);
                        days = temp[`${y}_${m}`];
                    } catch (e) {
                        console.error(e)
                    }
                }
                const num = getMonthDays(y)[m];
                days = days || new Array(3).fill(0).map(() => Math.round(Math.random() * num));
                days.sort((a, b) => a - b);
                days = [2, 16, 22]
                const store = {};
                store[`${y}_${m}`] = days;
                localStorage.setItem("birth_data", JSON.stringify(store));
                this.birthList = this.birthList.map((item, i) => {
                    return {
                        name: item.name,
                        date: new Date(y, m, days[i]).pattern("M月d日")
                    }
                })
            },
            setMemberData() {
                const res = this.data
                let memberData = [...this.memberData];
                const tj = res.kqtjxz;
                const sz = tj[0].count;
                const zg = tj[1].count;
                if(sz) {
                    const data = member.series[0].data;
                    memberData[0].num = sz[0] || 0;
                    data.forEach((item, i) => item.value = sz[i + 1] && sz[i + 1] > 0 ? sz[i + 1] : 0)
                }
                if(zg) {
                    const data = member.series[1].data;
                    memberData[1].num = zg[0] || 0;
                    data.forEach((item, i) => item.value = zg[i + 1] && zg[i + 1] > 0 ? zg[i + 1] : 0)
                }
                if(res.zg && res.zg.total) {
                    memberData[2].num = res.zg.total;
                    member.series[2].data[0].value = res.zg.total;
                }
                if(this.memberChart) {
                    this.memberChart.setOption(member)
                } else {
                    this.memberChart = echarts.init(document.getElementById('hr_member_chart'));
                    this.memberChart.setOption(member)
                }
            },
            setActionData() {
                let data = [];
                if(this.data && this.data.xxkp) {
                    const kp = this.data.xxkp;
                    data = [kp.ljjb, kp.hbph, kp.xyxl, kp.qjph];
                }
                const action = [...this.action];
                action.forEach((a, i) => {
                    let list = data[i];
                    if(list) {
                        a.list = list;
                        const temp = [...list]
                        temp.sort((a, b) => b.count - a.count);
                        let max = temp[0].count * 1;
                        a.list.forEach(item => {
                            item.per = max == 0 ? 0 : Math.round(item.count * 1 / max * 10000) / 100;
                        });
                    }
                    if(a.wave) {
                        let percent = a.percent || 0;
                        let r = 36, lr = 48;
                        let y = r * 2 * (100 - percent) / 100;
                        let ry = y < r ? r - y : y - r;
                        let rx = Math.sqrt(Math.pow(r,2) - Math.pow(ry,2));
                        let p1 = {x: r - rx, y}, p4 = {x: r + rx, y};
                        let ly = lr - (Math.sqrt(Math.pow(lr,2) - Math.pow(rx,2)));
                        let p2 = {x: r - rx / 2, y: y + ly}, p3 = {x: r + rx / 2, y: y - ly};
                        a.path = `M${p1.x} ${p1.y} C${p2.x} ${p2.y},${p3.x} ${p3.y}, ${p4.x} ${p4.y} A${r} ${r} 0 ${percent > 50 ? 1 : 0} 1 ${p1.x} ${p1.y} Z`
                    }
                });
                this.action = action;
            },
            setChangeData(data) {
                const kq = data.kqtjxz;
                if(kq) {
                    const zs = kq.reduce((acc, val) => acc + (val.count && val.count[1] ? val.count[1] : 0), 0);
                    const wp = kq.reduce((acc, val) => acc + (val.count && val.count[3] && val.count[3] > 0 ? val.count[3] : 0), 0);
                    all.series[0].data[0].value = zs;
                    all.series[0].data[1].value = wp;
                }
                this.change = [...all.series[0].data];
                if(!this.allChangeChart) {
                    this.allChangeChart = echarts.init(document.getElementById('all_change_chart'));
                }
                this.allChangeChart.setOption(all);
                let szOptions = clone(bar);
                if(!this.scChangeChart) {
                    this.scChangeChart = echarts.init(document.getElementById('sz_change_chart'));
                }
                this.scChangeChart.setOption(szOptions);
                let zgOptions = clone(bar);
                zgOptions.xAxis.data[0] = '入职';
                zgOptions.xAxis.data[1] = '离职';
                zgOptions.series[0].data = [10,8,4,2,2];
                if(!this.zgChangeChart) {
                    this.zgChangeChart = echarts.init(document.getElementById('zg_change_chart'));
                }
                this.zgChangeChart.setOption(zgOptions);
            },
            setAssData() {
                let assData = this.data.rsdpData;
                let assList = assData.kp_list.slice(0, 6);
                assList.forEach(ass => {
                    ass.start = getDate(ass.begin_time).pattern("yyyy-M-d");
                    ass.end = getDate(ass.end_time).pattern("yyyy-M-d");
                    ass.typeText = getAssTypeName(ass.program_type);
                    ass.statusText = getAssListStatusText(ass.status);
                    if(ass.item && ass.item.length > 0) {
                        let done = 0;
                        ass.item.forEach(a => {
                            if(a.status == 1) {
                                done += 1;
                            }
                        });
                        ass.numText = `${done}/${ass.item.length}`;
                    } else {
                        ass.numText = '0/0'
                    }
                });
                this.assList = assList;
                let rankData = this.rankData;
                let userRank = (assData.kp_rank_1 || []).filter(ass => ass.score && ass.score.length > 0).slice(0,5);
                let deptRank = (assData.kp_rank_2 || []).filter(ass => ass.score && ass.score.length > 0).slice(0,5);
                userRank.forEach(ass => {
                    let list = [...ass.score];
                    list.sort((a, b) => b.score - a.score);
                    let max = list[0].score;
                    ass.score.forEach(a => {
                        a.per = Math.round(a.score / max * 10000) / 100;
                    });
                });
                deptRank.forEach(ass => {
                    ass.score.forEach(a => {
                        a.per = Math.round(a.score / 100 * 10000) / 100;
                    });
                });
                rankData[0].list = userRank;
                rankData[1].list = deptRank;
                this.rankData = [...rankData];
            },
            khChange(r, num) {
                let index = r.index;
                let len = r.list.length;
                if(len > 1) {
                    if(index == 0 && num < 0) {
                        index = len - 1;
                    } else if(index == len - 1 && num > 0) {
                        index = 0;
                    } else {
                        index += num;
                    }
                    this.$set(r, 'index', index);
                }
            },
            setTrainData() {
                if(!this.trainChart) {
                    this.trainChart = echarts.init(document.getElementById('hr_train_chart'));
                }
                this.trainChart.setOption(train);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../screen/screen";
    .screen {
        padding: 90px 16px 16px;
    }
    .top-banner {
        background-image: url(../../screen/hr/image/banner.png);
    }
    .hr-left,
    .hr-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 24px;
        background: url(../../screen/hr/image/border.png) center no-repeat;
        background-size: 100% 100%;
    }
    .hr-right {
        margin-left: 72px;
    }
    .hr-member {
        display: flex;
        position: relative;
    }
    .hr-member-item {
        flex: 1;
    }
    .hr-member-total {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 210px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      .hr-total-num {
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.035em
      }
    }
    .hr-member-label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
    }

    .hr-member-icon {
      margin-right: 6px;
      width: 13px;
      height: 8px;
      border-radius: 2px;
    }
    .hr-member-other {
      margin-top: 6px;
      text-align: center;
      font-size: 12px;
    }

    .hr-member-chart {
        position: absolute;
        top: 0;
        left: -40px;
        right: -40px;
        height: 210px;
    }
    .member-label-text {
        flex: 1;
        text-align: center;
    }
    .hr-action-row {
        display: flex;
        flex-wrap: wrap;
    }
    .hr-action-item {
        padding: 12px 0 20px;
        width: 48%;
        &.hr-action-long {
            width: 52%;
        }
    }
    .hr-action-title {
        width: 82px;
        height: 24px;
        background: url(../../screen/hr/image/span-back.png) center no-repeat;
        line-height: 24px;
        text-align: center;
        color: #92fcfd;
    }
    .hr-action-detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
    }
    .action-chart-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 12px;
    }
    .action-chart-label {
        margin-left: 12px;
        width: 3em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .action-chart-bar {
        position: relative;
        margin-left: 12px;
        width: 210px;
        border-radius: 6px;
        background-color: #18266a;
        height: 16px;
    }
    .chart-bar-box {
        height: 100%;
        border-radius: 6px;
        background-image: linear-gradient(90deg, #243172, #48f5f8);
    }
    .chart-bar-num {
        position: absolute;
        top: 0;
        right: 2em;
        bottom: 0;
        line-height: 16px;
    }
    .hr-action-ball {
        width: 72px;
        height: 72px;
        border-radius: 100%;
        background-color: #172a60;
        box-shadow: inset 0 0 11px rgba(179,218,255,.15);
    }
    .hr-change {
        display: flex;
        margin-top: 20px;
    }
    .hr-change-piece {
        flex: 1;
        position: relative;
        height: 240px;
    }
    .hr-change-all {
        width: 100%;
        height: 100%;
    }
    .change-all-legend {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8px;
        font-size: 20px;
    }
    .all-legend-item {
        display: flex;
        align-items: center;
        color: #00b9e4;
        &:last-child {
            margin-left: 12px;
            color: #b890ff;
            .all-legend-icon {
                background-color: #b890ff;
            }
        }
    }
    .all-legend-icon {
        margin-right: 6px;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: #00b9e4;
    }
    .hr-change-chart {
        width: 100%;
        height: 100%;
    }
    .hr-change-legend {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 24px;
        font-size: 12px;
        font-weight: 600;
    }
    .change-legend-icon {
        margin-right: 4px;
        width: 10px;
        height: 10px;
        background-color: #7ecef4;
    }
    .hr-ass {
        position: relative;
        margin-top: 30px;
        text-align: center;
        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url(../../screen/hr/image/table.png) center no-repeat;
            background-size: 100% 100%;
        }
    }
    .hr-ass-title {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        height: 38px;
        background-color: rgba(56,128,211,.5);
        color: #92fcfd;
        font-weight: bold;
    }
    .hr-ass-th {
        flex: 1;
    }
    .hr-ass-list {
        height: 156px;
    }
    .hr-ass-item {
        display: flex;
        align-items: center;
        height: 26px;
        &.hr-ass-0 {
            background-color: rgba(56,128,211,.25);
        }
        &.hr-ass-1 {
            background-color: rgba(56,128,211,.1);
        }
        &.hr-ass-full {
            height: 156px;
            line-height: 156px;
        }
    }
    .hr-ass-td {
        flex: 1;
        padding: 0 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
    }
    .hr-kh-rank {
        display: flex;
        margin-top: 36px;
    }
    .kh-rank-area {
        flex: 1;
        min-width: 0;
        padding: 0 30px;
    }
    .rank-area-top {
        display: flex;
        align-items: center;
    }
    .rank-area-title {
        min-width: 0;
        margin: 0 6px;
        font-size: 18px;
        font-weight: bold;
    }
    .rank-top-icon {
        cursor: pointer;
    }
    .kh-rank-list {
        margin-top: 10px;
        height: 218px;
        font-size: 12px;
    }
    .kh-rank-info {
        display: flex;
        align-items: center;
    }
    .kh-rank-num {
        display: flex;
        width: 48px;
        height: 32px;
        line-height: 32px;
    }
    .kh-rank-img {
        margin: 2px 4px;
        width: 21px;
        height: 28px;
    }
    .kh-rank-score {
        position: relative;
        margin: 2px 0;
        padding-right: 64px;
        height: 8px;
        border-radius: 2px;
        &.rank-bar-0 {
            background-image: linear-gradient(-90deg, rgba(70,234,145,.3),rgba(70,234,145,.1));
            .score-bar-box {
                background-image: linear-gradient(-90deg, rgba(70,234,145,1),rgba(70,234,145,.3));
            }
            .score-bar-dot {
                border-color: rgba(68,228,141,.5);
                &::before {
                    content: "";
                    background-color: #44e48d;
                    box-shadow: 0 0 6px #44e48d;
                }
            }
        }
        &.rank-bar-1 {
            background-image: linear-gradient(-90deg, rgba(43,160,255,.3),rgba(43,160,255,.1));
            .score-bar-box {
                background-image: linear-gradient(-90deg, rgba(132,255,248,1),rgba(43,160,255,.3));
            }
            .score-bar-dot {
                border-color: rgba(132,255,248,.5);
                &::before {
                    content: "";
                    background-color: #84fff8;
                    box-shadow: 0 0 6px #84fff8;
                }
            }
        }
        &.rank-bar-2 {
            background-image: linear-gradient(-90deg, rgba(236,177,59,.3),rgba(236,177,59,.1));
            .score-bar-box {
                background-image: linear-gradient(-90deg, rgba(233,225,98,1),rgba(236,177,59,.3));
            }
            .score-bar-dot {
                border-color: rgba(230,221,96,.5);
                &::before {
                    content: "";
                    background-color: #e7dc60;
                    box-shadow: 0 0 6px #e7dc60;
                }
            }
        }
        &.rank-bar-3 {
            background-image: linear-gradient(-90deg, rgba(2,133,223,.3),rgba(2,133,223,.1));
            .score-bar-box {
                background-image: linear-gradient(-90deg, rgba(0,98,223,1),rgba(2,133,223,.3));
            }
            .score-bar-dot {
                border-color: rgba(2,131,222,.5);
                &::before {
                    content: "";
                    background-color: #0062df;
                    box-shadow: 0 0 6px #0062df;
                }
            }
        }
        &.rank-bar-4 {
            background-image: linear-gradient(-90deg, rgba(2,133,223,.3),rgba(2,133,223,.1));
            .score-bar-box {
                background-image: linear-gradient(-90deg, rgba(142,131,247,1),rgba(44,44,118,.3));
            }
            .score-bar-dot {
                border-color: rgba(142,131,247,.5);
                &::before {
                    content: "";
                    background-color: #8e83f7;
                    box-shadow: 0 0 6px #8e83f7;
                }
            }
        }
    }
    .rank-score-bar {
        height: 100%;
    }
    .score-bar-box {
        position: relative;
        height: 100%;
        border-radius: 2px;
    }
    .score-bar-dot {
        position: absolute;
        top: -5px;
        right: 0;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        border: 1px solid;
        transform: translateX(50%);
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 6px;
            height: 6px;
            border-radius: 100%;
        }
    }
    .rank-score-num {
        position: absolute;
        top: -2px;
        right: 12px;
    }
    .kh-rank-empty {
        height: 218px;
        line-height: 218px;
        text-align: center;
        font-size: 14px;
    }
    .hr-piece-top {
        font-size: 18px;
        color: #2f6a99;
        font-weight: 600;
    }
    .hr-piece-item {
        display: flex;
        align-items: center;
        margin-top: 30px;
    }
    .hr-piece-num {
        margin-left: 20px;
    }
    .hr-train {
        position: relative;
        margin: 24px 0 0 48px;
        width: 200px;
        height: 200px;
    }
    .hr-train-chart {
        width: 100%;
        height: 100%;
    }
    .hr-train-num {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 24px;
    }
    .hr-birth-list {
        margin: 48px 0 0 24px;
    }
    .hr-birth-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 18px;
        width: 277px;
        height: 48px;
        background: url(../../screen/hr/image/hr-span.png) center no-repeat;
        background-size: 100% 100%;
        line-height: 48px;
        font-size: 18px;
        color: #1ed7fe;
        font-weight: 600;
        &:not(:first-child) {
            margin-top: 16px;
        }
    }
</style>
