import member from "@/screen/member";
import {clone} from "../../common/js/tool";

const options = clone(member);
options.series.forEach((item, i) => {
    item.radius = ['50%', '65%'];
    item.label.fontSize = 12;
    item.label.padding = [0, -40];
    item.label.labelLine = {
        length2: 40,
    }
    switch (i){
        case 0:
            item.center = ['20%', '50%'];
            break;
        case 1:
            item.center = ['50%', '50%'];
            break;
        case 2:
            item.center = ['80%', '50%'];
            break;
    }
});

export default options;