export default {
    grid: {
        top: 24,
        left: 0,
        right: 40,
        bottom: 0,
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: [],
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 14,
            color: '#fff'
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(0,185,228,0.3)'
            }
        }
    },
    yAxis: {
        type: 'value',
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 16,
            color: '#fff'
        },
        axisLine: {
            show: true,
            lineStyle: {
                color: 'rgba(0,185,228,0.3)'
            }
        },
        splitLine: {
            lineStyle: {
                color: 'rgba(0,185,228,0.6)'
            }
        }
    },
    series: [
        {
            data: [120, 325, 150, 80, 70],
            type: 'bar',
            itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#8fdffe' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#00a8ff' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
                borderColor: '#00b9e4',
                borderWidth: 1,
            },
            barWidth: 40
        }
    ]
};
