import {getDoubleText} from "../../../common/js/tool";

const hours = [], xData = [];
for(let i = 6; i <= 18; i ++) {
    hours.push(i);
    xData.push(`${getDoubleText(i)}:00`);
}

export default {
    grid: {
        top: 48,
        left: 0,
        right: 0,
        bottom: 0,
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: xData,
        axisTick: {
            show: false,
        },
        axisLabel: {
            margin: 12,
            fontSize: 16,
            color: '#fff',
            lineHeight: 24
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(0, 245, 255, 0.6)'
            }
        }
    },
    yAxis: {
        type: 'value',
        name: '人',
        nameTextStyle: {
            padding: [0, 16],
            fontSize: 12,
            color: '#6791FF',
            align: 'right'
        },
        minInterval: 1,
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 12,
            color: '#6791FF'
        },
        axisLine: {
            show: false,
        },
        splitLine: {
            lineStyle: {
                type: 'dashed',
                color: 'rgba(104, 134, 219, 0.5)'
            }
        }
    },
    series: [
        {
            data: [],
            type: 'line',
            symbol: 'none',
            smooth: true,
            lineStyle: {
                color: '#32B7E9'
            },
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(50,183,233,1)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(50,183,233,0)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            }
        },
    ]
}
export {hours, xData};
