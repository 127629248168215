export default {
    title: {
        text: '登记排位数量/个',
        top: 0,
        left: 0,
        textStyle: {
            fontSize: 12,
            color: '#6791FF'
        }
    },
    grid: {
        top: 40,
        left: 10,
        right: 20,
        bottom: 0,
        containLabel: true
    },
    legend: {
        top: 0,
        right: 20,
        icon: 'roundRect',
        itemWidth: 14,
        itemHeight: 8,
        textStyle: {
            color: '#fff',
            fontSize: 14,
        },
        data: [
            { name: '当前登记牌位', itemStyle: { color: '#32B7E9' } },
            { name: '累计登记牌位', itemStyle: { color: '#00D69C' } },
        ]
    },
    xAxis: {
        type: 'category',
        data: [],
        axisTick: {
            show: false,
        },
        axisLabel: {
            margin: 12,
            fontSize: 16,
            color: '#fff',
            lineHeight: 24
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(0, 245, 255, 0.6)'
            }
        }
    },
    yAxis: {
        type: 'value',
        minInterval: 1,
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 12,
            color: '#6791FF'
        },
        axisLine: {
            show: false,
        },
        splitLine: {
            lineStyle: {
                type: 'dashed',
                color: 'rgba(104, 134, 219, 0.5)'
            }
        }
    },
    series: [
        {
            data: [],
            name: '当前登记牌位',
            type: 'line',
            symbol: 'none',
            smooth: true,
            lineStyle: {
                color: '#32B7E9'
            },
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(50,183,233,1)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(50,183,233,0)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            }
        },
        {
            data: [],
            name: '累计登记牌位',
            type: 'line',
            symbol: 'none',
            smooth: true,
            lineStyle: {
                color: '#00D69C'
            },
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(2,244,255,1)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(2,244,255,0)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            }
        }
    ]
}
