<template>
  <div class="temple-video">
    <img :src="url" class="temple-map-img">
    <div
      class="temple-map-point"
      @mouseenter="pointEnter($event, m)"
      @mouseleave="pointLeave($event, m)"
      :style="{top: `${mapTop + m.top}px`, left: `${mapLeft + m.left}px`}"
      v-for="(m, i) in points"
      :key="i">
      <slot name="point" v-bind:point="m" v-if="$scopedSlots.point"></slot>
      <template v-else>
        <div class="map-point-line">
          <div class="point-line-ctx" :style="{height: `${m.height}px`}"></div>
        </div>
        <div class="map-point-title">{{m.name}}</div>
      </template>
    </div>
    <div class="map-video" :class="{'map-video-down': down}" :style="videoStyle" v-show="vs" @mouseenter="videoEnter" @click.stop>
      <video-player
        class="video-player-box"
        :options="options"
        :playsinline="true"
        v-if="options && vs"></video-player>
    </div>
  </div>
</template>

<script>
    import 'videojs-contrib-hls'
    import 'video.js/dist/video-js.css'
    import {videoPlayer} from 'vue-video-player'
    import config from "../../common/js/config";
    import {baseOption} from "../../screen/templeVideo";
    export default {
        name: "TempleVideo",
        components: {videoPlayer},
        props: {
            url: String,
            points: Array,
            mapTop: {
                type: Number,
                default: 0
            },
            mapLeft: {
                type: Number,
                default: 0
            },
        },
        data() {
            return {
                host: config.host,
                options: null,
                videoStyle: null,
                vs: false,
                videoTimer: null,
                down: false
            }
        },
        created() {
            document.addEventListener("click", this.hideVideo, false);
        },
        beforeDestroy() {
            document.removeEventListener("click", this.hideVideo, false)
        },
        methods: {
            videoEnter() {
                this.videoTimer && clearTimeout(this.videoTimer)
            },
            clearShowTimer() {
                if(this.showTimer) {
                    clearTimeout(this.showTimer);
                    this.showTimer = null;
                }
            },
            pointEnter(e, m) {
                const dom = e.currentTarget;
                const rec = dom.getBoundingClientRect();
                this.showTimer = setTimeout(() => {
                    const videoStyle = {
                        top: this.mapTop + m.top + "px",
                        left: this.mapLeft + m.left + "px"
                    }
                    if(m.video){
                        if(!this.options || this.options.sources[0].src != m.video) {
                            this.options = Object.assign(baseOption, {
                                sources: [{
                                    type: "application/x-mpegURL",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                                    src: m.video//url地址
                                }],
                            });
                            this.videoStyle = videoStyle;
                        }
                        this.down = rec.top < 240;
                        this.vs = true;
                    }
                }, 300)
            },
            pointLeave() {
                this.clearShowTimer();
                // this.videoTimer = setTimeout(() => {
                //   this.vs = false;
                // }, 300)
            },
            hideVideo() {
                this.vs = false;
            },
        }
    }
</script>

<style lang="less">
  .temple-video {
    position: relative;
  }
  .temple-map-img {
    width: 100%;
    object-fit: cover;
  }
  .temple-map-point {
    display: flex;
    position: absolute;
    font-size: 12px;
    cursor: pointer;
  }
  .map-video {
    position: absolute;
    top: 0;
    left: 0;
    padding-bottom: 10px;
    transform: translateX(-50%) translateY(-100%);
    z-index: 10;
    &.map-video-down {
      padding: 0;
      transform: translateX(-50%) translateY(70px);
    }
  }
  .video-player-box {
    position: relative;
    width: 400px;
    height: 225px;
    background-color: #000;
    border: 2px solid #00c2ff;
    box-sizing: content-box;
  }

  .map-point-line {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid #f8f09e;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: #f8f09e;
    }

    .point-line-ctx {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      width: 1px;
      height: 12px;
      border-radius: 100%;
      background-color: #f8f09e;
      transform: translateY(100%);
    }
  }

  .map-point-title {
    margin-top: 18px;
    padding: 6px 4px 6px 2px;
    width: 1em;
    border-radius: 18px;
    background-color: #f8f09e;
    box-sizing: content-box;
    font-style: italic;
    color: #306ba9;
    font-weight: bold;
  }
</style>
