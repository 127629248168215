<template>
  <div class="tourist-chart-area" v-show="show">
    <div class="tourist-chart-tab">
      <div class="tourist-tab-item" :class="{'tourist-tab-active': tab == t.value}" @click="tab = t.value" v-for="t in tabList" :key="t.value">{{t.title}}</div>
    </div>
    <div class="tourist-chart-box" id="tourist_chart"></div>
  </div>
</template>

<script>
    import {getTouristChartAxisData} from "../../../screen/tourist";
    import * as echarts from "echarts";
    import {chartLoadingOpt} from "../../../screen/chart"
    import line from "../../../screen/line";
    import {clone} from "../../../common/js/tool";
    export default {
        name: "tourist-chart",
        props: {
            rs: Object,
            show: Boolean
        },
        data() {
            return {
                tab: "week",
                tabList: [
                    { value: "week", title: "日" },
                    { value: "month", title: "月" },
                ],
                yearList: [],
            }
        },
        computed: {
            touristData() {
                let res = null;
                const rs = this.rs;
                if(rs) {
                    const { months, days } = getTouristChartAxisData();
                    res = {
                        month: {
                            list: months,
                            data: [
                                rs.month_data_last_year ? rs.month_data_last_year.reverse() : new Array(months.length).fill(0),
                                rs.month_data ? rs.month_data.reverse() : new Array(months.length).fill(0),
                            ]
                        },
                        week: {
                            list: days,
                            data: [
                                rs.week_data_last_year ? rs.week_data_last_year.reverse() : new Array(days.length).fill(0),
                                rs.week_data ? rs.week_data.reverse() : new Array(days.length).fill(0),
                            ]
                        }
                    }
                }
                return res;
            },
            options() {
                let res = null;
                if(this.touristData) {
                    res = clone(line);
                    const tab = this.tab;
                    res.xAxis.data = this.touristData[tab].list;
                    res.series[0].data = this.touristData[tab].data[0];
                    res.series[1].data = this.touristData[tab].data[1];
                }
                return res;
            }
        },
        watch: {
            options() {
                this.updateTouristChart();
            },
            show() {
                this.$nextTick(() => {
                    this.updateTouristChart();
                })
            }
        },
        created() {
            this.setYearList();
        },
        mounted() {
            this.updateTouristChart();
        },
        methods: {
            setYearList() {
                const today = new Date();
                const year = today.getFullYear();
                this.year = 1;
                this.yearList = [year - 1, year];
            },
            updateTouristChart() {
                if(!this.show) return;
                const options = this.options;
                if (!this.lineChart) {
                    this.lineChart = echarts.init(this.$el.querySelector("#tourist_chart"));
                }
                if (options) {
                    this.lineChart.setOption(options);
                    this.lineChart.hideLoading();
                } else {
                    this.lineChart.showLoading('default', chartLoadingOpt);
                }
            },
        }
    }
</script>

<style lang="less">
  .tourist-chart-area {
    position: absolute;
    top: 38px;
    right: -9px;
    width: 586px;
    height: 375px;
    background: url(../../../screen/image/chart-border.png) center no-repeat;
    background-size: contain;
  }
  .tourist-chart-tab {
    display: flex;
    position: absolute;
    top: 96px;
    right: 36px;
    z-index: 1;
  }
  .tourist-tab-item {
    width: 20px;
    height: 20px;
    background-color: #fff;
    text-align: center;
    line-height: 20px;
    color: #222e5e;
    font-weight: 600;
    cursor: pointer;
  }
  .tourist-tab-active {
    background-color: #ffe895;
  }

  .tourist-chart-box {
    position: absolute;
    top: 80px;
    left: 50px;
    right: 50px;
    bottom: 30px;
  }
</style>
