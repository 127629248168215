export default {
    series: [
        {
            type: 'gauge',
            startAngle: 210,
            endAngle: -30,
            center: ['25%', '50%'],
            radius: 70,
            splitNumber: 1,
            max: 74,
            progress: {
                show: false,
            },
            pointer: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    width: 2,
                    color: [[1, '#00A5F0']],
                    shadowBlur: 10,
                    shadowColor: '#00A5F0',
                }
            },
            axisTick: {
                distance: 1,
                splitNumber: 28,
                lineStyle: {
                    color: 'rgba(255,255,255,.6)',
                    width: 2,
                    cap: 'round'
                }
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            title: {
                show: true,
                color: '#fff',
                offsetCenter: [0, '82%']
            },
            detail: {
                offsetCenter: [0, 0],
                valueAnimation: true,
                formatter: function (value) {
                    return `{value|${value}/74}`;
                },
                rich: {
                    value: {
                        width: 83,
                        height: 83,
                        backgroundColor: {
                            image: require("../image/car-border.png")
                        },
                        fontSize: 20,
                        fontWeight: 700,
                        lineHeight: 30,
                        color: '#fff',
                    },
                }
            },
            data: [{value: 20, name: '车辆余位比'}]
        },
        {
            type: 'gauge',
            startAngle: 60,
            endAngle: -30,
            center: ['25%', '50%'],
            radius: 70,
            splitNumber: 1,
            progress: {
                show: false,
            },
            pointer: {
                icon: 'triangle',
                length: 32,
                offsetCenter: [0, -37],
                itemStyle: {
                    color: 'rgba(255, 255, 255, 0.9)'
                }
            },
            axisLine: {
                lineStyle: {
                    width: 28,
                    color: [[1, {
                        type: 'linear',
                        x: 1,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            { offset: 0, color: 'rgba(0,185,255,1)' },
                            { offset: 1, color: 'rgba(0,185,255,0)' },
                        ],
                        global: false // 缺省为 false
                    }]],
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            title: {
                show: false
            },
            detail: {
                show: false,
            },
            data: [0]
        },
        {
            type: 'gauge',
            startAngle: 210,
            endAngle: -30,
            center: ['75%', '50%'],
            radius: 70,
            splitNumber: 1,
            max: 20,
            progress: {
                show: false,
            },
            pointer: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    width: 2,
                    color: [[1, '#00A5F0']],
                    shadowBlur: 10,
                    shadowColor: '#00A5F0',
                }
            },
            axisTick: {
                distance: 1,
                splitNumber: 28,
                lineStyle: {
                    color: 'rgba(255,255,255,.6)',
                    width: 2,
                    cap: 'round'
                }
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            title: {
                show: true,
                color: '#fff',
                offsetCenter: [0, '82%']
            },
            detail: {
                offsetCenter: [0, 0],
                valueAnimation: true,
                formatter: function (value) {
                    return `{value|${value}/20}`;
                },
                rich: {
                    value: {
                        width: 83,
                        height: 83,
                        backgroundColor: {
                            image: require("../image/car-border.png")
                        },
                        fontSize: 20,
                        fontWeight: 700,
                        lineHeight: 30,
                        color: '#fff',
                    },
                }
            },
            data: [{value: 0, name: '报备到达比'}]
        },
        {
            type: 'gauge',
            startAngle: 0,
            endAngle: -30,
            center: ['75%', '50%'],
            radius: 70,
            splitNumber: 1,
            progress: {
                show: false,
            },
            pointer: {
                icon: 'triangle',
                length: 32,
                offsetCenter: [0, -37],
                itemStyle: {
                    color: 'rgba(255, 255, 255, 0.9)'
                }
            },
            axisLine: {
                lineStyle: {
                    width: 28,
                    color: [[1, {
                        type: 'linear',
                        x: 1,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            { offset: 0, color: 'rgba(0,185,255,1)' },
                            { offset: 1, color: 'rgba(0,185,255,0)' },
                        ],
                        global: false // 缺省为 false
                    }]],
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            title: {
                show: false
            },
            detail: {
                show: false,
            },
            data: [0]
        },
    ]
};
