export const all = {
    series: [
        {
            name: '人员变动',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '40%'],
            avoidLabelOverlap: false,
            selectedMode: 'single',
            selectedOffset: 0,
            label: {
                show: false,
                position: 'center',
                formatter: '{b}\n{c}',
                color: '#fff',
                fontSize: 20,
                lineHeight: 26
            },
            select: {
                label: {
                    show: true
                }
            },
            data: [
                { value: 0, name: '在寺', selected: true},
                { value: 0, name: '外派' },
            ],
            color: [
                {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#00ddeb' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#008ad9' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
                {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#c897ff' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#a388ff' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            ]
        }
    ]
};


export const bar = {
    grid: {
        top: 36,
        left: 0,
        right: 10,
        bottom: 10,
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: ['进单', '离单', '转正', '调岗', '外派'],
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 12,
            color: '#fff'
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(0,185,228,0.3)'
            }
        }
    },
    yAxis: {
        type: 'value',
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 12,
            color: '#fff'
        },
        axisLine: {
            show: true,
            lineStyle: {
                color: 'rgba(0,185,228,0.3)'
            }
        },
        splitLine: {
            show: false
        }
    },
    series: [
        {
            data: [3, 1, 1, 2, 4],
            type: 'bar',
            itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#8fdffe' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#00a8ff' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
                borderColor: '#00b9e4',
                borderWidth: 1,
            },
            barWidth: 10
        }
    ]
};
