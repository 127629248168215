export const getTouristChartAxisData = function () {
    let today = new Date();
    let days = [];
    days.push(today.pattern("M月d日"));
    for(let i = 0; i < 6; i++) {
        today.setDate(today.getDate() - 1);
        days.push(today.pattern("M月d日"));
    }
    days.reverse();
    today = new Date();
    today.setDate(1);
    let months = [];
    months.push(today.pattern("M月"));
    for(let i = 0; i < 5; i++) {
        today.setMonth(today.getMonth() - 1);
        months.push(today.pattern("M月"));
    }
    months.reverse();
    return {months, days};
}

export const getTouristChartData = function (data) {
    let monthData = [...data.month_data].reverse();
    let weekData = [...data.week_data].reverse();
    const {months, days} = getTouristChartAxisData();
    return {
        month: {
            list: months,
            data: monthData
        },
        week: {
            list: days,
            data: weekData
        }
    }
}
