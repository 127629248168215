<template>
  <div class="mountain-data">
    <div class="mountain-data-top">
      <div class="mountain-data-title">
        <img :src="require(`../../../screen/safety/image/warning-${type}.png`)" class="mountain-title-text" />
      </div>
      <div class="mountain-data-query">
        <div class="mountain-query-label">选择时间</div>
        <div class="mountain-query-input">
          <a-range-picker
            ref="rangePicker"
            v-model="date"
            size="large"
            separator="-"
            :open="open"
            :placeholder="['起始时间', '结束时间']"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @openChange="handleOpenChange"
            class="mountain-query-date">
          </a-range-picker>
          <img src="../../../screen/safety/image/down.png" class="mountain-query-down" @click="showPicker" />
        </div>
      </div>
    </div>
    <div class="mountain-data-box">
      <div class="mountain-data-table">
        <div class="mountain-data-tr mountain-data-head">
          <div class="mountain-data-td" v-for="col in columns" :key="col.dataIndex">{{ col.title }}</div>
        </div>
        <div class="mountain-data-body" v-if="list && list.length > 0">
          <div class="mountain-data-tr" v-for="item in list" :key="item.id">
            <div class="mountain-data-td" v-for="col in columns" :key="col.dataIndex">
              <a-icon type="search" class="mountain-data-icon" @click="goWarningDetail(item)" v-if="col.key == 'action'"/>
              <vnodes :vnodes="col.customRender(item[col.dataIndex], item)" v-if="col.customRender"/>
              <span v-else>{{ item[col.dataIndex] }}</span>
            </div>
          </div>
        </div>
        <div class="mountain-data-empty" v-else-if="list">暂无数据</div>
      </div>
      <a-pagination
        v-model="page"
        :total="total"
        :page-size="pageSize"
        class="mountain-data-pagination" />
    </div>
    <img src="../../../screen/safety/image/close.png" class="mountain-data-close" @click="close" />
  </div>
</template>

<script>

    import {getDate} from "../../../common/js/tool";
    import {getSafetyRecordColumns} from "../../../common/constant/safe";

    export default {
        name: "safetyData",
        props: {
            type: Number
        },
        data() {
            return {
                date: [],
                list: null,
                total: 0,
                page: 1,
                pageSize: 10,
                open: false
            }
        },
        computed: {
            columns() {
                return getSafetyRecordColumns.call(this, this.type);
            }
        },
        watch: {
            date() {
                this.getSafetyData();
            },
            page() {
                this.getSafetyData();
            },
            type() {
                this.refresh();
            }
        },
        created() {
            this.getSafetyData();
        },
        methods: {
            refresh() {
                this.page = 1;
                this.total = 0;
                this.list = null;
                this.getSafetyData();
            },
            goWarningDetail(item) {
                this.$router.push({ name: "SafeDetail", params: { id: item.id } });
            },
            handleOpenChange(e) {
                this.open = e;
            },
            showPicker() {
                this.open = true;
            },
            close() {
                this.$emit("hide");
            },
            getSafetyData() {
                if(!this.type) return;
                if(this.type == 7) {
                    this.getMountainData();
                } else {
                    this.getCommonSafetyData();
                }
            },
            getCommonSafetyData() {
                const page = this.page, pageSize = this.pageSize;
                let url = `/admin/security-work-order?sort=-id&filter[type]=${this.type}&page=${page}&pageSize=${pageSize}`;
                const date = this.date;
                if(date && date.length == 2) {
                    let start = date[0] + ' 00:00:00'
                    let end = date[1] + ' 23:59:59'
                    url += `&filter[alarm_time][gte]=${start}&filter[alarm_time][lte]=${end}`;
                }
                this.$axios(url).then(res => {
                    const list = res.data;
                    // list.push({
                    //     alarm_time: "2024-01-01 12:00",
                    //     type: this.type,
                    //     alarm_level: 1,
                    //     device_name: "测试",
                    //     device_type: "测试",
                    //     device_location: "测试",
                    //     status: 1
                    // });
                    this.list = list;
                    if(res.page) {
                        this.total = res.page.totalCount;
                    }
                })
            },
            getMountainData() {
                const page = this.page, pageSize = this.pageSize;
                let url = `/admin/displacement?page=${page}&pageSize=${pageSize}&sort=-date`;
                const date = this.date;
                if(date && date.length == 2) {
                    let start = date[0] + ' 00:00:00'
                    let end = date[1] + ' 23:59:59'
                    url += `&filter[date][gte]=${start}&filter[date][lt]=${end}`;
                }
                this.$axios({
                    url,
                    noTempleFilter: true
                }).then(res => {
                    const list = res.data;
                    list.forEach(item => {
                        item.time = getDate(item.date).pattern("yyyy-MM-dd HH:mm");
                        item.status = "正常";
                    })
                    this.list = list;
                    if(res.page) {
                        this.total = res.page.totalCount;
                    }
                })
            }
        }
    }
</script>

<style lang="less">
.mountain-data {
  position: relative;
  padding: 27px 40px 0;
  width: 1289px;
  height: 777px;
  background: url(../../../screen/safety/image/mountain-box.png) center / 100% 100% no-repeat;
}
.mountain-data-close {
  position: absolute;
  top: 40px;
  right: 15px;
  cursor: pointer;
}
.mountain-data-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 60px;
  background: linear-gradient(90deg, #2D4EA6 0%, rgba(45, 78, 166, 0.00) 100%);
}
.mountain-data-title {
  padding: 9px 18px;
  border-radius: 8px;
  border: 1px solid #8CF0FF;
  background: #2D4EA6;
}
.mountain-title-text {
  width: 137px;
  height: 17px;
  object-fit: cover;
}
.mountain-data-query {
  display: flex;
  align-items: center;
}
.mountain-query-label {
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 2px;
}
.mountain-query-input {
  position: relative;
  margin-left: 12px;
  padding: 3px 36px 3px 0;
  border-radius: 8px;
  border: 1px solid #8CF0FF;
}
.mountain-query-down {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
}
.mountain-query-date {
  width: 300px;
}
.mountain-query-date {
  .ant-input {
    background: linear-gradient(180deg, #FFF 0%, #91C7FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: none;
    font-size: 20px;
    line-height: 28px;
  }
  .ant-calendar-picker-clear {
    background-color: transparent;
    color: #8CF0FF;
    font-size: 18px;
  }
}
.mountain-data-box {
  margin-top: 27px;
  padding: 30px 30px 0;
  height: 634px;
  border-radius: 12px;
  border: 1px solid #8AD4FF;
  background: rgba(34, 62, 136, 0.68);
}
.mountain-data-table {
  height: 544px;
}
.mountain-data-tr {
  display: flex;
  align-items: center;
  margin-top: 1px;
  height: 48px;
  background: rgba(0, 0, 0, 0.2);
  font-size: 16px;
  text-align: center;
  &.mountain-data-head {
    margin-top: 0;
    background: rgba(0, 0, 0, 0.4);
    font-size: 20px;
    font-weight: 500;
  }
}
.mountain-data-td {
  flex: 1;
}
.mountain-data-icon {
  color: #23B9FF;
  font-size: 20px;
  cursor: pointer;
}
.mountain-data-empty {
  margin-top: 40px;
}
.mountain-data-pagination {
  margin-top: 14px;
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-item {
    border-radius: 2px;
    border: 1px solid rgba(117, 251, 253, 0.4);
    background: rgba(255, 255, 255, 0.10);
    color: rgba(117, 251, 253, 0.4);
    text-align: center;
    font-size: 14px;
    a {
      color: rgba(117, 251, 253, 0.4);
    }
    &.ant-pagination-item-active {
      border-radius: 2px;
      border-color: #75FBFD;
      background: rgba(255, 255, 255, 0.10);
      a {
        color: #75FBFD;
      }
    }
  }

  .ant-pagination-jump-prev .ant-pagination-item-container,
  .ant-pagination-jump-next .ant-pagination-item-container {
    .ant-pagination-item-ellipsis,
    .ant-pagination-item-link-icon {
      color: rgba(117, 251, 253, 0.4);
    }
  }
}
</style>
