export default {
    grid: {
        top: 54,
        left: 0,
        right: 10,
        bottom: 10,
        containLabel: true
    },
    legend: {
        top: 24,
        right: 80,
        itemWidth: 8,
        itemHeight: 8,
        itemGap: 20,
        padding: 0,
        inactiveBorderColor: "transparent",
        formatter: name => `{name|${name}}`,
        textStyle: {
            height: 10,
            rich: {
                name: {
                    verticalAlign: "bottom"
                }
            }
        },
        icon: "circle",
        data: [ {name: '2023', textStyle: { color: '#03FFFF'}}, {name: '2024', textStyle: { color: '#ffe895'}}]
    },
    xAxis: {
        type: 'category',
        axisTick: {
            color: "#75fbfd"
        },
        axisLabel: {
            fontSize: 12,
            fontWeight: 600,
            color: '#fff',
        },
        splitLine: {
            show: false,
        },
        axisLine: {
            lineStyle: {
                width: 2,
                color: "#75fbfd"
            }
        },
        data: []
    },
    yAxis: {
        axisTick: {
            color: "#75fbfd"
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: "rgba(117, 251, 253, .4)"
            }
        },
        axisLine: {
            show: true,
            lineStyle: {
                width: 2,
                color: "#75fbfd"
            }
        },
        axisLabel: {
            fontSize: 14,
            fontWeight: 600,
            color: '#fff',
        },
        minInterval: 1
    },
    series: [
        {
            name: '2023',
            data: [],
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,
            itemStyle: {
                borderWidth: 8,
                borderColor: 'rgba(3, 255, 255, 0.3)',
                color: '#03FFFF',
            },
            label: {
                show: true,
                color: '#03FFFF'
            },
            lineStyle: {
                color: '#03FFFF',
                width: 1
            }
        },
        {
            name: '2024',
            data: [],
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,
            itemStyle: {
                borderWidth: 8,
                borderColor: 'rgba(255,232,149,.3)',
                color: '#ffe895',
            },
            label: {
                show: true,
                color: '#ffe895'
            },
            lineStyle: {
                color: '#ffe895',
                width: 1
            }
        }
    ]
};
