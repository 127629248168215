<template>
  <div class="screen">
    <div class="top-banner"></div>
    <div class="asset-area">
      <div class="asset-area-piece">
        <div class="screen-piece-title">
          <img src="../../screen/asset/image/zc-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">资产盘点</div>
        </div>
        <div class="asset-title">
          <div class="asset-title-text">固定资产</div>
          <div class="asset-title-num">{{fixedData.total}}</div>
        </div>
        <div class="asset-fixed-chart" id="asset_fixed_chart"></div>
      </div>
      <div class="asset-area-piece">
        <div class="screen-piece-title">
          <img src="../../screen/asset/image/zc-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">库存盘点</div>
        </div>
        <div class="ball-data">
          <div class="ball-data-box">
            <div class="data-ball">
              <svg width="160px" height="160px" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="stock_ball" x1="0%" x2="100%" y1="0%" y2="0%">
                    <stop offset="0%" :stop-color="stockData.wave.color[0]" stop-opacity="1"/>
                    <stop offset="100%" :stop-color="stockData.wave.color[1]" stop-opacity="1"/>
                  </linearGradient>
                </defs>
                <!-- Wave -->
                <g id="stock_ball_wave">
                  <path id="stock_wave_path"
                        fill-rule="evenodd"
                        fill="url(#stock_ball)"
                        :d="stockData.wave.path">
                  </path>
                </g>
                <text class="data-ball-title" alignment-baseline="middle" text-anchor="middle" font-size="16px" x="80" y="52" style="fill: #fff">警戒值{{stockData.alert}}%</text>
                <text class="data-ball-num" alignment-baseline="middle" text-anchor="middle" font-weight="600" font-size="30px" x="80" y="116" :style="stockData.percent > 42 ? 'fill: #000' : 'fill: #fff'">{{stockData.percent}}%</text>
              </svg>
            </div>
          </div>
          <div class="ball-data-title">剩余库存</div>
        </div>
      </div>
      <div class="asset-area-piece">
        <div class="screen-piece-title">
          <img src="../../screen/asset/image/bfjc-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">报废检测</div>
        </div>
        <div class="asset-title">
          <div class="asset-title-text">报废累计</div>
          <div class="asset-title-num">{{scrappedData.total}}</div>
        </div>
        <div class="ball-data">
          <div class="ball-data-box">
            <div class="data-ball">
              <svg width="160px" height="160px" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="scrapped_ball" x1="0%" x2="100%" y1="0%" y2="0%">
                    <stop offset="0%" :stop-color="scrappedData.wave.color[0]" stop-opacity="1"/>
                    <stop offset="100%" :stop-color="scrappedData.wave.color[1]" stop-opacity="1"/>
                  </linearGradient>
                </defs>
                <!-- Wave -->
                <g id="scrapped_ball_wave">
                  <path id="scrapped_wave_path"
                        fill-rule="evenodd"
                        fill="url(#scrapped_ball)"
                        :d="scrappedData.wave.path">
                  </path>
                </g>
                <text class="data-ball-title" alignment-baseline="middle" text-anchor="middle" font-size="16px" x="80" y="52" style="fill: #fff">相较上月</text>
                <text class="data-ball-num" alignment-baseline="middle" text-anchor="middle" font-weight="600" font-size="30px" x="80" y="116" :style="stockData.percent > 42 ? 'fill: #000' : 'fill: #fff'">{{scrappedData.percent}}%</text>
              </svg>
            </div>
          </div>
          <div class="ball-data-title">本月报废</div>
        </div>
      </div>
      <div class="asset-area-piece">
        <div class="screen-piece-title">
          <img src="../../screen/asset/image/bfjc-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">闲置资产</div>
        </div>
        <div class="asset-title">
          <div class="asset-title-text">当前闲置</div>
          <div class="asset-title-num">{{idleData.total}}</div>
        </div>
        <div class="asset-idle">
          <div class="asset-idle-base">
            <div class="idle-base-oval"></div>
            <div class="idle-base-oval"></div>
            <div class="idle-base-oval"></div>
          </div>
          <div class="asset-idle-data">
            <div class="idle-data-item" :style="{top: `${c.top}px`, left: `${c.left}px`, zIndex: c.zIndex}" v-for="(c, i) in idleData.list" :key="i">
              <cones
                class="asset-idle-cones"
                :width="c.width"
                :height="c.percent"
                :color="c.color"></cones>
              <div class="asset-idle-line" :style="{height: `${c.lh}px`}"></div>
              <div class="asset-idle-title">{{c.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="asset-area">
      <div class="flex-box vertical asset-area-piece" style="width: 50%">
        <div class="screen-piece-title">
          <img src="../../screen/asset/image/lyxh-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">领用消耗</div>
        </div>
        <div class="flex-grow flex-box consume-data">
          <div class="consume-data-piece" v-for="c in consumeData.list" :key="c.key">
            <div class="consume-data-title">
              <div class="consume-title-text">{{c.title}}</div>
              <div class="consume-time-range">
                <span class="consume-time-item" :class="{'consume-time-select': t.value == consumeData.current}" v-for="t in consumeData.timeList" :key="t.value">{{t.text}}</span>
              </div>
            </div>
            <div class="consume-data-chart" :id="`${c.key}_consume_chart`"></div>
          </div>
        </div>
      </div>
      <div class="flex-box vertical asset-area-piece">
        <div class="screen-piece-title">
          <img src="../../screen/asset/image/xzsg-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">新增申购</div>
        </div>
        <div class="flex-grow consume-data-piece">
          <div class="consume-data-title">
            <div class="consume-title-text">申请量</div>
            <div class="consume-time-range">
              <span class="consume-time-item" :class="{'consume-time-select': t.value == purchaseData.current}" v-for="t in purchaseData.timeList" :key="t.value">{{t.text}}</span>
            </div>
          </div>
          <div class="consume-data-chart" id="purchase_chart"></div>
        </div>
      </div>
      <div class="flex-box vertical asset-area-piece">
        <div class="screen-piece-title">
          <img src="../../screen/asset/image/bfjc-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">防疫物资</div>
        </div>
        <div class="flex-grow consume-data-piece supplies-data">
          <div class="consume-data-title">
            <div class="consume-title-text">申请量</div>
            <div class="consume-time-range">
              <span class="consume-time-item" :class="{'consume-time-select': t.value == suppliesData.current}" v-for="t in suppliesData.timeList" :key="t.value">{{t.text}}</span>
            </div>
          </div>
          <div class="consume-data-chart" id="supplies_chart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import * as echarts from "echarts";
    import fixed from "../../screen/asset/data/fixed";
    import consume from "../../screen/asset/data/consume";
    import purchase from "../../screen/asset/data/purchase";
    import supplies from "../../screen/asset/data/supplies";
    import cones from "./graph/cones";
    import {clone} from "../../common/js/tool";

    export default {
        name: "Asset",
        props: {
            data: Object
        },
        components: {cones},
        data() {
            return {
                fixedData: {
                    total: "10,865"
                },
                stockData: {
                    alert: 6,
                    percent: 45,
                    wave: {
                        color: ['#83dfff', '#3277ff'],
                        path: ''
                    }
                },
                scrappedData: {
                    total: "6,865",
                    percent: 45,
                    wave: {
                        color: ['#83dfff', '#b38eff'],
                        path: ''
                    }
                },
                idleData: {
                    total: '6,865',
                    list: [
                        {title: '家具', width: 60, percent: 54, lh: 40, top: 36, left: 20, zIndex: 2, color: [{top: '#313691', bottom: "#5d66d8"}, {top: '#313691', bottom: "#7885e2"}]},
                        {title: '电子电器', width: 116, percent: 78, lh: 60, top: 0, left: 50, zIndex: 1, color: [{top: '#193e66', bottom: "#255e9a"}, {top: '#214d8f', bottom: "#3b8cc8"}]},
                        {title: '专用设备', width: 84, percent: 70, lh: 30, top: 26, left: 130, zIndex: 2, color: [{top: '#579ba5', bottom: "#9ddde1"}, {top: '#579ba5', bottom: "#c1e7eb"}]},
                        {title: '佛教用品', width: 48, percent: 48, lh: 80, top: 30, left: 204, zIndex: 1, color: [{top: '#4e4993', bottom: "#8c80dc"}, {top: '#5b569d', bottom: "#ada3d6"}]},
                        {title: '交通工具', width: 44, percent: 40, lh: 32, top: 58, left: 245, zIndex: 2, color: [{top: '#c9bca7', bottom: "#e7e0d5"}, {top: '#eee4d2', bottom: "#f8f4ec"}]},
                        {title: '文物', width: 74, percent: 60, lh: 60, top: 26, left: 274, zIndex: 1, color: [{top: '#574784', bottom: "#bf94da"}, {top: '#6b5e8e', bottom: "#cac2d9"}]},
                        {title: '其他', width: 36, percent: 30, lh: 32, top: 67, left: 324, zIndex: 2, color: [{top: '#78a1d3', bottom: "#cfe1f3"}, {top: '#86b9fb', bottom: "#c9e3ff"}]},
                    ]
                },
                consumeData: {
                    current: 1,
                    timeList: [
                        {value: 1, text: '周'},
                        {value: 2, text: '月'},
                        {value: 3, text: '年'},
                    ],
                    list: [
                        {title: "物品消耗Top5", key: "goods", xData: ['盒装抽纸', 'A4纸', '笔记本', '胶带', '订书钉']},
                        {title: "部门消耗Top5", key: "dept", xData: ['客堂', '殿堂', '工程部', '办公室', '都监察']},
                    ]
                },
                purchaseData: {
                    current: 1,
                    timeList: [
                        {value: 1, text: '周'},
                        {value: 2, text: '月'},
                        {value: 3, text: '年'},
                    ],
                },
                suppliesData: {
                    current: 1,
                    timeList: [
                        {value: 1, text: '周'},
                        {value: 2, text: '月'},
                        {value: 3, text: '年'},
                    ],
                }
            }
        },
        watch: {
            data() {
                this.setData();
            }
        },
        mounted() {
            this.setData();
        },
        methods: {
            setData() {
                let data = this.data;
                if(data) {
                    this.setFixedData();
                    this.setStockData();
                    this.setScrappedData();
                    this.setConsumeData();
                    this.setPurchaseData();
                    this.setSuppliesData();
                }
            },
            setSuppliesData() {
                if(!this.suppliesChart) {
                    this.suppliesChart = echarts.init(document.getElementById('supplies_chart'));
                }
                this.suppliesChart.setOption(supplies);
            },
            setPurchaseData() {
                if(!this.purchaseChart) {
                    this.purchaseChart = echarts.init(document.getElementById('purchase_chart'));
                }
                this.purchaseChart.setOption(purchase);
            },
            setConsumeData() {
                const list = this.consumeData.list;
                list.forEach(item => {
                    if(!this[`${item.key}ConsumeChart`]) {
                        this[`${item.key}ConsumeChart`] = echarts.init(document.getElementById(`${item.key}_consume_chart`));
                    }
                    const option = clone(consume);
                    option.xAxis.data = item.xData;
                    this[`${item.key}ConsumeChart`].setOption(option);
                });
            },
            setFixedData() {
                if(!this.fixedChart) {
                    this.fixedChart = echarts.init(document.getElementById('asset_fixed_chart'));
                }
                this.fixedChart.setOption(fixed);
            },
            setStockData() {
                const stockData = this.stockData;
                const percent = stockData.percent || 0;
                this.$set(stockData.wave, 'path', this.getPath(percent));
            },
            setScrappedData() {
                const scrappedData = this.scrappedData;
                const percent = scrappedData.percent || 0;
                this.$set(scrappedData.wave, 'path', this.getPath(percent));
            },
            getPath(percent, r = 80) {
                let path;
                if(percent == 100) {
                    path = `M${r} ${r * 2} A${r} ${r} 0 1 0 ${r} 0 A${r} ${r} 0 1 0 ${r} ${r * 2} Z`;
                } else {
                    const y = r * 2 * (100 - percent) / 100;
                    const ry = y < r ? r - y : y - r;
                    const rx = Math.sqrt(Math.pow(r,2) - Math.pow(ry,2));
                    const p1 = {x: r - rx, y}, p4 = {x: r + rx, y};
                    const bezier = this.getBezierControlPoints(p1, p4, rx * 2, 5);
                    path = `M${p1.x} ${p1.y} ${bezier} A${r} ${r} 0 ${percent > 50 ? 1 : 0} 1 ${p1.x} ${p1.y} Z`;
                }
                return path
            },
            getBezierControlPoints(start, end, w, num) {
                let path;
                const sx = start.x, sy = start.y;
                const len = w / num;
                const h = len / 3;
                const ty = sy - h, by = sy + h;
                const list = [];
                for(let i = 0; i < num; i ++) {
                    const px = sx + i * len + len / 2;
                    const py = i % 2 == 0 ? ty : by;
                    list.push(`Q${px} ${py}, ${sx + (i + 1) * len} ${sy}`);
                }
                path = list.join("")
                return path;
            }
        }
    }
</script>

<style scoped lang="less">
  @import "../../screen/screen";
  .screen {
    padding: 100px 50px 40px;
  }
  .top-banner {
    background-image: url(../../screen/asset/image/banner.png);
  }
  .asset-area {
    display: flex;
    height: 470px;
  }
  .asset-area-piece {
    width: 25%;
    .ball-data {
      margin: 106px auto 0;
      width: 200px;
    }
    .asset-title ~ .ball-data {
      margin-top: 32px;
    }
  }
  .asset-title {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .asset-title-text {
    padding: 4px 20px;
    background-image: linear-gradient(0, rgba(55,211,255,.4), rgba(55,211,255,0));
    border-bottom: 2px solid #37d3ff;
    font-size: 20px;
    text-shadow: #218bee 0 0 10px;
  }
  .asset-title-num {
    margin-left: 80px;
    padding: 0 16px;
    height: 54px;
    border-radius: 10px;
    background-color: rgba(110,169,233,.2);
    border: 1px solid #6ea9e9;
    font-size: 40px;
    font-weight: 700;
    color: #92fcfd;
    line-height: 52px;
  }
  .asset-fixed-chart {
    height: 320px;
  }
  .ball-data-box {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    border: 10px solid rgba(240,240,240,.1);
  }
  .data-ball {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    border: 10px solid rgba(240,240,240,.2);
    background-color: #040a3c;
  }
  .ball-data-title {
    margin-top: 16px;
    font-size: 22px;
    font-weight: 600;
  }
  .asset-idle {
    position: relative;
    margin-top: 84px;
    width: 390px;
    height: 200px;
  }
  .idle-base-oval {
    position: absolute;
    left: 0;
    width: 100%;
    &:first-of-type {
      top: 44px;
      height: 96px;
      background-color: rgba(30,75,135,.2);
      border: 1px solid rgba(35,77,151,.2);
      border-radius: 100% / 100%;
    }
    &:nth-of-type(2) {
      top: 34px;
      height: 96px;
      background-color: rgba(30,75,135,.3);
      border: 1px solid rgba(35,77,151,.3);
      border-radius: 100% / 100%;
    }
    &:last-of-type {
      top: 60px;
      height: 58px;
      background-color: rgba(30,75,135,.5);
      border: 1px solid rgba(44,103,219,.5);
      border-radius: 100% / 100%;
    }
  }
  .idle-data-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
  }
  .asset-idle-line {
    width: 1px;
    background-image: linear-gradient(0, transparent 0%, transparent 50%, #579dd6 50%, #579dd6 100%);
    background-size: 1px 4px;
    background-repeat: repeat-y;
  }
  .asset-idle-title {
    margin-top: 6px;
    color: #579dd6;
    font-size: 14px;
  }
  .consume-data-piece {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
  }
  .consume-data-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0 40px;
  }
  .consume-title-text {
    font-size: 16px;
    &::before {
      content: "";
      display: inline-block;
      margin-right: 6px;
      width: 12px;
      height: 12px;
      background-color: #7ecef4;
    }
  }
  .consume-time-item {
    margin-left: 8px;
    cursor: pointer;
    &.consume-time-select {
      color: #7ecef4;
    }
  }
  .consume-data-chart {
    flex: 1;
  }
  .supplies-data {
    position: relative;
    .consume-data-title {
      margin-top: 0;
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
</style>
