export default {
    grid: {
        top: 0,
        left: 0,
        right: 20,
        bottom: 0,
        containLabel: true
    },
    xAxis: {
        type: 'value',
        splitLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            margin: 1,
            fontSize: 12,
            color: '#fff',
            lineHeight: 18
        },
        axisLine: {
            show: true,
            lineStyle: {
                color: '#00F5FF'
            }
        }
    },
    yAxis: {
        type: 'category',
        data: ['山体', '舆情', '牌匾', '安防', '消防'],
        axisTick: {
            show: false,
        },
        axisLabel: {
            margin: 15,
            fontSize: 14,
            color: '#fff',
            lineHeight: 24
        },
        axisLine: {
            lineStyle: {
                color: '#00F5FF'
            }
        }
    },
    series: [
        {
            type: 'bar',
            data: [
                {
                    name: '山体',
                    value: 1,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 1,
                            y2: 0,
                            colorStops: [{
                                offset: 0, color: '#0066FF' // 0% 处的颜色
                            }, {
                                offset: 1, color: '#FF4800' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                    }
                },
                {
                    name: '舆情',
                    value: 2,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 1,
                            y2: 0,
                            colorStops: [{
                                offset: 0, color: '#0066FF' // 0% 处的颜色
                            }, {
                                offset: 1, color: '#00FF29' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                    }
                },
                {
                    name: '牌匾',
                    value: 6,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 1,
                            y2: 0,
                            colorStops: [{
                                offset: 0, color: '#0066FF' // 0% 处的颜色
                            }, {
                                offset: 1, color: '#274AFF' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                    }
                },
                {
                    name: '安防',
                    value: 4,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 1,
                            y2: 0,
                            colorStops: [{
                                offset: 0, color: '#0066FF' // 0% 处的颜色
                            }, {
                                offset: 1, color: '#FFA800' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                    }
                },
                {
                    name: '消防',
                    value: 9,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 1,
                            y2: 0,
                            colorStops: [{
                                offset: 0, color: '#0066FF' // 0% 处的颜色
                            }, {
                                offset: 1, color: '#00F5FF' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                    }
                },
            ],
            barWidth: 12,
            itemStyle: {
                borderRadius: 4
            }
        },
    ]
}
