import {clone} from "../common/js/tool";

const barConfig = {
    type: 'bar',
    barWidth: 24,
    label: {
        show: true,
        position: "top",
        distance: 2,
        color: "#fff",
        fontSize: 12
    }
};

const xAxisConfig =  {
    type: 'category',
    position: "bottom",
    offset: 0,
    axisTick: {
        show: false,
    },
    axisLabel: {
        margin: 4,
        fontSize: 12,
        lineHeight: 18,
        rich: {
            a: {
                color: '#fff',
            },
            b: {
                color: "rgba(255,255,255,0.8)",
            }
        }
    },
    axisLine: {
        lineStyle: {
            color: "#004590"
        }
    }
}
const xAxis = [
    {
        ...clone(xAxisConfig),
        data: ['在寺', '', ''],
    },
    {
        ...clone(xAxisConfig),
        data: ['', '请假', ''],
    },
    {
        ...clone(xAxisConfig),
        data: ['', '', '外派'],
    }
]

const colors = [
    {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
            offset: 0, color: '#8fdffe' // 0% 处的颜色
        }, {
            offset: 1, color: '#00BB97' // 100% 处的颜色
        }],
        global: false // 缺省为 false
    },
    {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
            offset: 0, color: '#FDFFE1' // 0% 处的颜色
        }, {
            offset: 1, color: '#F5D56A' // 100% 处的颜色
        }],
        global: false // 缺省为 false
    },
    {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
            offset: 0, color: '#90E2F7' // 0% 处的颜色
        }, {
            offset: 1, color: '#478CFF' // 100% 处的颜色
        }],
        global: false // 缺省为 false
    }
]

/**
 * 获取x坐标轴配置
 * @param t0 僧众应到数
 * @param t1 职工应到数
 */
export const getXAxis = function (t0, t1) {
    xAxis[0].axisLabel.formatter = function (value, index) {
        return index == 0 ? ["{a|僧众}", `{b|应到${t0 || 0}}`].join('\n') : "";
    };
    xAxis[1].axisLabel.formatter = function (value, index) {
        return index == 1 ? ["{a|职工}", `{b|应到${t1 || 0}}`].join('\n') : "";
    };
    xAxis[2].axisLabel.formatter = function (value, index) {
        return index == 2 ? "{a|志工}" : "";
    };
    return xAxis;
}

/**
 * 获取人员签到图表 series数据配置
 * @param tj 接口僧众、志工数据（kqtjxz节点）
 * @param total 志工总人数（zg节点 total 字段）
 */
export const getSeriesData = function (tj, total) {
    const res = [];
    xAxis.forEach((item, index) => {
        const count = tj[index] && tj[index].count ? tj[index].count : [];
        const s = {
            ...barConfig,
            name: item.data[index],
            xAxisIndex: index,
        }
        // 志工只有总数
        if (index == 2) {
            res.push({
                ...s,
                data: ["", "", total],
                itemStyle: {
                    color: colors[0]
                }
            });
        } else {
            item.data.forEach((d, i) => {
                res.push({
                    ...s,
                    data: new Array(index).fill("").concat(count[i + 1] || 0),
                    itemStyle: {
                        color: colors[i]
                    }
                });
            });
        }
    });
    return res;
}


const option = {
    color: ['#00BB97', '#F5D66C', '#4D93FE'],
    grid: {
        top: 40,
        left: 40,
        right: 0,
        bottom: 40,
    },
    legend: {
        top: 6,
        right: 0,
        data: [
            {name: "在寺", itemStyle: {color: "#00BB97"}},
            {name: "请假", itemStyle: {color: "#F5D66C"}},
            {name: "外派", itemStyle: {color: "#4D93FE"}},
        ],
        itemWidth: 10,
        itemHeight: 6,
        itemGap: 8,
        padding: 0,
        selectedMode: false,
        textStyle: {
            color: "rgba(255,255,255,0.8)",
            fontSize: 12,
        }
    },
    xAxis: [],
    yAxis: {
        type: 'value',
        name: '人',
        nameTextStyle: {
            padding: [0, 16],
            fontSize: 12,
            color: "rgba(255,255,255,0.8)",
            align: 'right'
        },
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 12,
            color: "rgba(255,255,255,0.8)",
        },
        axisLine: {
            show: false,
        },
        splitLine: {
            lineStyle: {
                type: [6, 6],
                width: 2,
                cap: "round",
                color: "#014690"
            }
        }
    },
    series: []
};

/**
 * @param data 接口 data 数据
 */

const getAttendanceOptions = function (data) {
    const tj = data.kqtjxz;
    const sz = tj[0].count;
    const zg = tj[1].count;
    option.series = getSeriesData(data.kqtjxz, data.zg && data.zg.total ? data.zg.total : 0);
    option.xAxis = getXAxis(sz[0], zg[0]);
    return option;
}

export default getAttendanceOptions;

