export default {
    series: [
        {
            name: '人员变动',
            type: 'pie',
            radius: ['45%', '60%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            selectedMode: 'single',
            selectedOffset: 0,
            label: {
                formatter: '{b} {d}%\n{c}',
                color: '#fff',
                fontSize: 18,
                lineHeight: 22
            },
            labelLine: {show: false},
            data: [
                { value: 3500, name: '在用', label: {color: '#aa8bff'}},
                { value: 3500, name: '闲置', label: {color: '#00d9e8'}},
                { value: 1750, name: '报废', label: {color: '#82fba9'}},
            ],
            color: [
                {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#a89ff8' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#7a6df6' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
                {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#00ddeb' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#008ad9' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
                {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#b4fdd4' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#95fcaf' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
            ]
        }
    ]
};
