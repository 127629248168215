<template>
    <div class="data-screen-area">
        <img :src="img" class="data-screen-img" alt="" v-if="img">
    </div>
</template>

<script>
    const typeMap = {
        safe: {
            title: '灵隐寺安全管理平台',
            img: require('../../screen/data/safety-screen.png')
        },
    }
    export default {
        name: "Data",
        props: {
            type: String
        },
        data() {
            return {
            }
        },
        computed: {
            img() {
                let temp = typeMap[this.type];
                if(temp) {
                    document.title = temp.title;
                    return temp.img
                } else {
                    return  null;
                }
            }
        },
        created() {
            this.$emit("remind", null);
        }
    }
</script>

<style scoped lang="less">
    .data-screen-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .data-screen-img {
        width: 100%;
        height: 100%;
    }
</style>
