<template>
  <div class="screen">
    <div class="top-banner"></div>
    <div class="safety-area">
      <div class="safety-piece">
        <router-link :to="{name: 'SafeTraffic'}" class="screen-piece-title">
          <img src="../../screen/safety/image/rstj-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">入寺统计</div>
        </router-link>
        <div class="tourists-num">
          <div class="tourists-num-icon"></div>
          <div class="tourists-num-name">{{ touristsNumber[0].title }}</div>
          <div class="tourists-num-box">
            <div class="tourists-num-letter" v-for="(l, i) in String(touristsNumber[0].number)" :key="i">{{ l }}</div>
          </div>
        </div>
        <div class="tourists-chart-box">
          <div class="tourists-chart" id="tourists_chart"></div>
          <div class="tourists-calendar-box" v-show="calendarShow">
            <calendar
              ref="touristsCalendar"
              border="1px solid rgba(35, 185, 255, 0.1)"
              @month="handleMonth"
              class="tourists-chart-calendar"
              :weekdays="weekdays"
              :week-style="{paddingBottom: '3px', height: '18px', lineHeight: '18px', fontSize: '12px', color: 'rgba(255, 255, 255, 0.3)', fontWeight: 400}">
              <template v-slot:month="{month}">
                <div class="tourists-calendar-month">
                  <div class="tourists-month-text">
                    {{new Date(month).pattern("yyyy年M月")}}
                  </div>
                  <div class="tourists-calendar-operate">
                    <div class="tourists-operate-btn tourists-month-prev" @click="operateMonth(-1)">上月</div>
                    <div class="tourists-operate-btn tourists-month-today" :class="{'operate-btn-active': monthOffset == 0}" @click="goToday">今日</div>
                    <div class="tourists-operate-btn tourists-month-prev" @click="operateMonth(1)">下月</div>
                  </div>
                </div>
              </template>
              <template v-slot:full="{day}">
                <div class="tourists-calendar-day" :class="{'is-today': day.isToday}">
                  <div class="tourists-day-text">{{ day.day }}</div>
                  <div class="tourists-day-num">{{ dateMap[day.dateStr] || '' }}</div>
                </div>
              </template>
            </calendar>
            <div class="tourists-data-loading" v-show="loading">
              <a-spin>
                <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
              </a-spin>
            </div>
          </div>
          <div class="tourists-chart-tab">
            <div
              class="tourists-tab-item"
              :class="{'tourists-tab-active': touristsTab == tab.value}"
              @click="changeTab(tab.value)"
              v-for="tab in touristsTabs"
              :key="tab.value">{{ tab.title }}</div>
          </div>
        </div>
      </div>
      <div class="safety-piece safety-video">
        <router-link :to="{name: 'SafeMonitor'}" class="screen-piece-title">
          <img src="../../screen/safety/image/syjk-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">寺院监控</div>
        </router-link>
        <div class="safety-temple-video">
          <div class="safety-video-box">
            <video-player
              class="safety-video-player"
              :options="options"
              :playsinline="true"
              @error="handleError"
              v-if="!videoError && video && video.url"></video-player>
            <div class="safety-video-error" v-else>
              <div class="video-error-box">
                <div class="icon-offline video-error-icon"></div>
                <div class="video-error-text">监控离线</div>
              </div>
            </div>
          </div>
          <grid-box class="safety-video-list">
            <div class="safety-video-pointer"></div>
            <swiper-container
              direction="vertical"
              centered-slides="true"
              :slides-per-view="prev"
              loop="true"
              init="false"
              speed="500"
              @slidechange="handleSildeChange"
              class="safety-video-swiper">
              <swiper-slide v-for="(item, i) in videoList" :key="i">
                <div class="safety-video-item" :class="{'safety-video-active': videoIndex == i}">{{ item.name }}</div>
              </swiper-slide>
            </swiper-container>
          </grid-box>
        </div>
      </div>
      <div class="safety-piece safety-events">
        <div class="screen-piece-title">
          <img src="../../screen/safety/image/sjzx-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">事件中心</div>
        </div>
        <div class="safety-events-table">
          <div class="safety-events-tr safety-events-head">
            <div class="safety-events-td">类型</div>
            <div class="safety-events-td">时间</div>
            <div class="safety-events-td">设备地点</div>
            <div class="safety-events-td">当前值</div>
            <div class="safety-events-td">状态</div>
          </div>
          <div class="safety-events-body" @scroll="handleEventScroll">
            <div class="safety-events-tr" :class="{'safety-events-margin': i > 0}" v-for="(event, i) in eventList" :key="i">
              <div class="safety-events-td">山体</div>
              <div class="safety-events-td">{{ event.time }}</div>
              <div class="safety-events-td">后山1号点位</div>
              <div class="safety-events-td">{{ event.b }}</div>
              <div class="safety-events-td">正常</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="safety-mid">
      <div class="safety-piece safety-data">
        <div class="safety-warning" v-if="safetyWarning && safetyWarning.content">
          <div class="icon-notice safety-warning-icon"></div>
          <div class="safety-warning-info">
            <div class="safety-warning-title">灾害预警：</div>
            <scroll-text class="safety-warning-text" :text="safetyWarning.content.desc"></scroll-text>
          </div>
        </div>
        <div class="safety-data-list">
          <div class="safety-data-item" v-for="(item, i) in safetyData" :key="i">
            <grid-box color="#1CD1A1" border="1px solid rgba(28, 209, 161, 0.5)" height="2px" class="safety-data-ctx">
              <div class="safety-data-title">{{ item.title }}</div>
              <div class="safety-data-num"><roll-number :num="item.value"></roll-number>{{ item.unit }}</div>
            </grid-box>
          </div>
        </div>
      </div>
      <div class="safety-piece safety-health">
        <div class="safety-health-title">寺院安全健康度</div>
        <div class="safety-health-value">
          <div class="health-value-icon health-value-before"></div>
          <div class="health-value-text">
            <span class="health-text-num" v-for="(t, i) in (health + '').split('')" :key="i">{{ t }}</span>
            <span class="health-text-num">%</span>
          </div>
          <div class="health-value-icon health-value-after"></div>
        </div>
      </div>
      <div class="safety-piece safety-types">
        <div class="safety-type-list">
          <div class="safety-type-item" :class="getSafetyClass(i)" @click="handleSafetyTypeClick(t)" v-for="(t, i) in safetyType" :key="i">
            <div class="safety-type-name">{{ t.name }}</div>
          </div>
          <div class="safety-type-item safety-type-value">
            <div class="safety-type-name">{{ safetyType[typeIndex].value }}</div>
          </div>
        </div>
        <div class="safety-notice-chart" id="safety_notice_chart"></div>
      </div>
      <div class="safety-piece emergency-plan">
        <div class="screen-piece-title">
          <img src="../../screen/safety/image/yjya-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">应急预案</div>
        </div>
        <div class="emergency-list">
          <div
            class="emergency-item"
            :class="{'emergency-active': emergencyIndex === i}"
            v-for="(item, i) in emergencyList"
            @click="showEmergencyPlay(item)"
            :key="i">
            <div class="emergency-text" v-for="(t, j) in item.name.split('')" :key="j">{{ t }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="safety-area">
      <div class="safety-piece">
        <div class="screen-piece-title">
          <img src="../../screen/safety/image/clbb-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">车辆报备</div>
        </div>
        <div class="safety-car-chart" id="safety_car_chart"></div>
        <div class="safety-car-table">
          <div class="car-table-tr car-table-head">
            <div class="car-table-td">车牌号</div>
            <div class="car-table-td">入场时间</div>
            <div class="car-table-td">门岗</div>
          </div>
          <carousel class="safety-car-carousel" :list="carList" :number="3">
            <template v-slot:item="{item}">
              <div class="car-table-tr">
                <div class="car-table-td">{{ item.carNumber }}</div>
                <div class="car-table-td">{{ item.carAccessTime }}</div>
                <div class="car-table-td">{{ gateList[item.position - 1] }}</div>
              </div>
            </template>
          </carousel>
        </div>
      </div>
      <div class="safety-piece public-opinion">
        <div class="screen-piece-title">
          <img src="../../screen/safety/image/wlyq-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">热点关注</div>
        </div>
        <div class="hot-data">
          <div class="hot-data-top">
            <div class="hot-top-icon"></div>
            <div class="hot-top-view">
              <div class="top-view-item" v-for="item in opinionTypeList" :key="item.key">
                <div class="top-view-title" :style="{color: item.color}">
                  <div class="view-title-dot" :style="{backgroundColor: item.color}"></div>
                  <div class="view-title-text">{{item.title}}</div>
                </div>
                <div class="top-view-num">{{item.num}}</div>
              </div>
            </div>
          </div>
          <opinion-data :list="opinionList" type="compact"></opinion-data>
        </div>
      </div>
      <div class="safety-piece safety-log">
        <router-link :to="{name: 'SafeLogs'}" class="screen-piece-title">
          <img src="../../screen/safety/image/rzlb-icon.png" class="piece-title-icon"/>
          <div class="piece-title-text">日志列表</div>
        </router-link>
        <carousel class="safety-log-carousel" :list="safetyLog" :number="3">
          <template v-slot:item="{item}">
            <div class="safety-log-tr">
              <div class="safety-log-td">{{ item.time }}</div>
              <div class="safety-log-td">{{ item.operate }}</div>
              <div class="safety-log-td">{{ item.user }}</div>
            </div>
          </template>
        </carousel>
        <grid-box class="safety-auth">
          <img src="../../screen/buddha/image/auth-icon.png" class="safety-auth-icon" />
          <div class="safety-auth-title">权限配置</div>
          <div class="safety-auth-btn" @click="goRoute({name: 'SafeAuth'})">点击查看</div>
        </grid-box>
      </div>
    </div>
  </div>
</template>

<script>
    import * as echarts from "echarts";
    import { videoPlayer } from 'vue-video-player'
    import carousel from "../../components/carousel";
    import gridBox from "../../components/grid-box";
    import rollNumber from "../../components/roll-number";
    import scrollText from "../../components/scroll-text";
    import tourists, { hours, xData } from "../../screen/safety/data/tourists";
    import notice from "../../screen/safety/data/notice";
    import car from "../../screen/safety/data/car";
    import {videoList, baseOption} from "../../screen/templeVideo";
    import {getTouristChartData} from "../../screen/tourist";
    import calendar from "../../components/calendar/calendar";
    import { register } from 'swiper/element/swiper-element';
    import { Autoplay, Scrollbar, Mousewheel } from 'swiper';
    import { getDate } from "../../common/js/tool";
    import opinionData from "./graph/opinion-data";
    import { opinionTypeList } from "../../screen/safety/data/opinion";
    register();

    export default {
        name: "Safety",
        components: {
            videoPlayer,
            gridBox,
            carousel,
            rollNumber,
            scrollText,
            calendar,
            opinionData
        },
        props: {
            data: Object,
            count: Object,
        },
        data() {
            return {
                touristsNumber: [
                    {title: '今日游客接待量', number: 0},
                    {title: '本月游客接待量', number: 0},
                ],
                touristsTabs: [
                    {value: 1, title: "小时", data: null},
                    {value: 2, title: "日", data: null},
                    {value: 3, title: "月", data: null},
                    {value: 4, title: "更多"},
                ],
                dateMap: {},
                weekdays: ['日', '一', '二', '三', '四', '五', '六'],
                touristsTab: 1,
                calendarShow: false,
                loading: false,
                month: null,
                activeIndex: 0,
                videoList,
                videoError: false,
                eventList: [
                    // {type: '山体', time: '2023-01-09 09:34', location: '后山1号点位', value: '12°', text: '正常', status: '正常'},
                    // {type: '山体', time: '2023-01-09 09:34', location: '后山1号点位', value: '12°', text: '正常', status: '正常'},
                    // {type: '山体', time: '2023-01-09 09:34', location: '后山1号点位', value: '12°', text: '正常', status: '正常'},
                    // {type: '山体', time: '2023-01-09 09:34', location: '后山1号点位', value: '12°', text: '正常', status: '正常'},
                    // {type: '山体', time: '2023-01-09 09:34', location: '后山1号点位', value: '12°', text: '正常', status: '正常'},
                ],
                safetyWarning: null,
                safetyData: [
                    {title: '异常事件处理次数', value: 0, unit: '次'},
                    {title: '异常事件平均处理时长', value: 0, unit: '分钟'},
                    {title: '异常事件处置完结率', value: 0, unit: '%'},
                    {title: '预警总次数', value: 0, unit: '次'},
                    {title: '安全监测设备数', value: 0, unit: '台'},
                    {title: '平安灵隐总天数', value: 0, unit: '天'},
                ],
                health: 100,
                typeIndex: 0,
                safetyType: [
                    {name: '烟雾', value: '正常', type: 1},
                    {name: '山体', value: '正常', type: 7},
                    {name: '牌匾', value: '正常', type: 6},
                    {name: '水压', value: '正常', type: 2},
                    {name: '电力', value: '正常', type: 3},
                    {name: '分贝', value: '正常'},
                ],
                emergencyList: [
                    {name: '山体', type: 7},
                    {name: '烟雾', type: 1},
                    {name: '牌匾', type: 6},
                    {name: '水压', type: 2},
                    {name: '电力', type: 3},
                    {name: '人流', type: 5},
                    {name: '自然灾害', type: 8}
                ],
                emergencyIndex: null,
                carList: [],
                words: [
                    {word: '大雄宝殿', style: {fontSize: '24px', fontWeight: 700, color: "#1CD1A1"}},
                    {word: '月德呈瑞', style: {fontSize: '24px', fontWeight: 700, color: "#1CD1A1"}},
                    {word: 'pray', style: {fontSize: '12px', color: "#FE5578"}},
                    {word: '飞来峰', style: {fontSize: '16px', color: "#59BCFB"}},
                    {word: '灵隐寺旅行攻略', style: {fontSize: '12px', color: "#FE5578"}},
                    {word: '愿所求皆所愿-灵隐寺', style: {fontSize: '24px', fontWeight: 700, color: "#1CD1A1"}},
                    {word: '祈福', style: {fontSize: '16px', color: "#59BCFB"}},
                    {word: '灵隐寺十八子', style: {fontSize: '12px', color: "#FE5578"}},
                    {word: '灵隐寺门票', style: {fontSize: '16px', color: "#59BCFB"}},
                    {word: '观世音菩萨', style: {fontSize: '12px', color: "#FE5578"}},
                    {word: 'Lingyin Temple', style: {fontSize: '16px', color: "#59BCFB"}},
                ],
                safetyLog: [
                    {time: '2023-01-08 15:32', operate: '审核', user: '觉喜'},
                    {time: '2023-01-06 09:12', operate: '审核', user: '妙旭'},
                    {time: '2023-01-05 11:46', operate: '审核', user: '慧详'},
                ],
                gateList: ['第一道岗', '第二道岗', '第三道岗'],
                options: Object.assign(baseOption, {
                    sources: [{
                        type: "application/x-mpegURL",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        src: this.video ? this.video.url : "" //url地址
                    }],
                }),
                prev: 5,
                eventPage: 1,
                eventPageSize: 10,
                eventLoading: false,
                eventMore: true,
                opinionList: [],
                opinionTypeList: [],
                analysis: [],
            }
        },
        computed: {
            video() {
                return this.videoList[this.videoIndex];
            },
            monthOffset() {
                let res = 0;
                if(this.month) {
                    const today = new Date();
                    const month = new Date(this.month);
                    res = (today.getFullYear() - month.getFullYear()) * 12 + today.getMonth() - month.getMonth();
                }
                return res;
            },
            videoIndex() {
                return this.activeIndex - this.prev;
            }
        },
        watch: {
            data() {
                this.setData();
            },
            count() {
                this.setTouristsCount();
            },
            video(val) {
                if(val && val.url) {
                    this.options.sources[0].src = val.url;
                }
                this.videoError = false;
            },
            touristsTab() {
                this.updateTouristsChart();
            }
        },
        created() {
            this.getPlanList();
        },
        mounted() {
            this.setData();
            this.setTouristsCount();
            this.startTypeCarousel();
            this.initSwiper();
        },
        beforeDestroy() {
            this.clearTimer('type');
        },
        methods: {
            showEmergencyPlay(item) {
                if(Object.prototype.hasOwnProperty.call(item, "id")) {
                    if(item.id > 0) {
                        this.$router.push({
                            name: "EntityDetail",
                            params: { type: "plan", id: item.id }
                        });
                    } else {
                        this.$emit("waiting");
                    }
                }
            },
            goRoute(route) {
                this.$router.push(route);
            },
            //获取所有应急预案id
            getPlanList() {
                this.$axios(`/admin/security-emergency-plan?pageSize=100`).then(res => {
                    const emergencyList = [...this.emergencyList];
                    const list = res.data;
                    emergencyList.forEach(item => {
                        item.id = 0; // 0表示未接入敬请期待
                        if(item.type) {
                            const plan = list.find(p => p.type == item.type);
                            if(plan) {
                                item.id = plan.id;
                            }
                        }
                    });
                    this.emergencyList = emergencyList;
                });
            },
            handleEventScroll(e) {
                const dom = e.target;
                if (dom.scrollTop + dom.offsetHeight >= dom.scrollHeight - 72) {
                    this.getEventList();
                }
            },
            handleSafetyTypeClick(t) {
                if(t.type) {
                    this.$emit("safety", t.type);
                } else {
                    this.$emit("waiting");
                }
            },
            initSwiper() {
                const swiperEl = document.querySelector('swiper-container');
                const params = {
                    modules: [Autoplay, Scrollbar, Mousewheel],
                    scrollbar: {draggable: true},
                    autoplay: {
                        delay: 20000
                    },
                    mousewheel: {
                        sensitivity : 0.1,
                    },
                };
                Object.assign(swiperEl, params);
                swiperEl.initialize();
            },
            handleSildeChange(e) {
                const [swiper] = e.detail;
                this.activeIndex = swiper.activeIndex;
            },
            changeTab(tab) {
                if(tab == 4) {
                    this.calendarShow = !this.calendarShow;
                } else {
                    this.touristsTab = tab;
                    this.calendarShow = false;
                }
            },
            handleMonth(month) {
                this.month = month;
                this.getMonthTouristsData();
            },
            getMonthTouristsData() {
                this.loading = true;
                const month = this.month;
                const date = new Date(month);
                const start = date.pattern("yyyy-MM-dd");
                date.setMonth(date.getMonth() + 1);
                date.setDate(date.getDate() - 1);
                const url = `/common/get-rl?start_time=${start}&end_time=${date.pattern("yyyy-MM-dd")}`;
                this.$axios(url, {noTempleFilter: true}).then(res => {
                    const dateMap = {};
                    res.forEach(item => {
                        dateMap[item.date] = item.count;
                    })
                    this.dateMap = dateMap;
                }).finally(() => this.loading = false);
            },
            goToday() {
                if(this.monthOffset == 0) return;
                this.$refs.touristsCalendar.setMonth(this.monthOffset);
            },
            operateMonth(num) {
                if(num > 0 && this.monthOffset == 0) return;
                this.calendarLine = 0;
                this.$refs.touristsCalendar.setMonth(num);
            },
            startTypeCarousel() {
                this.clearTimer('type');
                this.typeTimer = setInterval(() => {
                    this.typeIndex = this.typeIndex === 0 ? 5 : this.typeIndex - 1;
                }, 5000)
            },
            clearTimer(type) {
                const key = `${type}Timer`;
                if(this[key]) {
                    clearInterval(this[key]);
                    this[key] = null;
                }
            },
            getSafetyClass(i) {
                const current = this.typeIndex;
                const len = this.safetyType.length;
                let index = i - current;
                if(index < 0) {
                    index += len;
                }
                const list = [`safety-type-${index}`];
                if(i === current) {
                    list.push('safety-type-active')
                }
                return list;
            },
            handleError() {
                this.videoError = true;
            },
            handleActiveVideoChange(index) {
                const len = this.videoList.length - 1;
                index += 2;
                if(index > len) {
                    index = index % len - 1;
                }
                this.videoIndex = index;
            },
            getEventList() {
                const loading = this.eventLoading, more = this.eventMore;
                if(loading || !more) return;
                this.eventLoading = true;
                const page = this.eventPage, pageSize = this.eventPageSize;
                const url = `/admin/displacement?page=${page}&pageSize=${pageSize}&sort=-date`;
                this.$axios({
                    url,
                    noTempleFilter: true
                }).then(res => {
                    const list = res.data;
                    list.forEach(this.dealEventItem);
                    this.eventList = this.eventList.concat(list);
                    if (this.page >= res.page.pageCount) {
                        this.eventMore = false;
                    } else {
                        this.eventPage += 1;
                    }
                }).finally(() => this.eventLoading = false);
            },
            dealEventItem(event) {
                event.time = getDate(event.date).pattern("yyyy-MM-dd HH:mm");
            },
            setData() {
                if(this.data) {
                    this.setTouristsNumber();
                    this.safetyNoticeData();
                    this.setCarData();
                    this.setOpinionData();
                    this.setEventData();
                    this.setSafetyData();
                    this.safetyWarning = this.data.qxyj;
                }
            },
            setSafetyData() {
                const data = this.data;
                if(data.security_data) {
                    const safetyData = [...this.safetyData];
                    safetyData.forEach((item, i) => {
                        const val = data.security_data[i] || 0;
                        item.value = item.unit == "%" ? val * 100 : val;
                    });
                    this.safetyData = safetyData;
                }
            },
            setEventData() {
                if(this.data && this.data.displacement && !this.eventLoading) {
                    const list = this.data.displacement;
                    list.forEach(this.dealEventItem);
                    this.eventList = list;
                    this.eventPage = 2;
                    this.eventMore = true;
                }
            },
            setOpinionData() {
                const yq = this.data.newYq;
                const typeList = [...opinionTypeList];
                const count = yq && yq.count ? yq.count : [0,0,0];
                typeList.forEach((item, i) => item.num = count[i]);
                this.opinionTypeList = typeList;
                this.opinionList = yq.chart;
            },
            setCarData() {
                if(!this.carChart) {
                    this.carChart = echarts.init(document.getElementById('safety_car_chart'));
                }
                if(this.data) {
                    const data = this.data.tcjl;
                    if(data) {
                        const total = data.count[0] || 0;
                        const cur = data.count[1] || 0;
                        const start = car.series[2].endAngle;
                        const max = car.series[2].startAngle - start;
                        const per = total === 0 ? 0 : cur / total;
                        const formatter = "{value|" + cur + "/" + total + "}";
                        car.series[2].data[0].value = cur;
                        car.series[2].detail.formatter = formatter;
                        car.series[3].startAngle = start + Math.round(per * max);
                    }
                    this.carChart.setOption(car);
                }
            },
            safetyNoticeData() {
                if(!this.noticeChart) {
                    this.noticeChart = echarts.init(document.getElementById('safety_notice_chart'));
                }
                if(this.data && this.data.security_data) {
                    const list = this.data.security_data.slice(-5);
                    list.reverse();
                    notice.series[0].data.forEach((item, i) => {
                        item.value = list[i] || 0;
                    });
                }
                this.noticeChart.setOption(notice);
                const data = this.data.tcjl;
                if(data.record) {
                    this.carList = data.record;
                }
            },
            setTouristsCount() {
                const count = this.count;
                if(count) {
                    this.$set(this.touristsNumber[0], 'number', count.all * 1);
                }
            },
            setTouristsNumber() {
                const data = this.data.rusi;
                const number = this.touristsNumber;
                if(!this.touristsChart) {
                    this.touristsChart = echarts.init(document.getElementById('tourists_chart'));
                }
                let list = new Array(hours.length).fill(0);
                if(data) {
                    // this.$set(number[0], 'number', data.today.count * 1);
                    this.$set(number[1], 'number', data.mouth.count_all * 1);
                    if(data.todaylist) {
                        const hourData = data.todaylist[Object.keys(data.todaylist)[0]];
                        list = hours.map(h => {
                            const d = hourData.find(item => item.hour * 1 == h);
                            if(d) {
                                return d.addcount * 1;
                            } else {
                                return 0;
                            }
                        })
                        this.touristsTabs[0].data = {
                            list: xData,
                            data: list
                        }
                    }
                }
                if(this.data.rs) {
                    const touristData = getTouristChartData(this.data.rs);
                    this.touristsTabs[1].data = touristData.week;
                    this.touristsTabs[2].data = touristData.month;
                }
                tourists.series[0].data = list;
                this.touristsChart.setOption(tourists);
            },
            updateTouristsChart() {
                const tab = this.touristsTabs.find(t => t.value == this.touristsTab);
                if(tab && tab.data) {
                    tourists.xAxis.axisLabel.fontSize = tab.value == 2 ? 12 : 16;
                    tourists.xAxis.data = tab.data.list;
                    tourists.series[0].data = tab.data.data;
                    this.touristsChart.setOption(tourists);
                }
            }
        }
    }
</script>

<style scoped lang="less">
  @import "../../screen/screen";
  .screen {
    display: flex;
    padding: 110px 30px 30px;
  }
  .top-banner {
    height: 120px;
    background-image: url(../../screen/safety/image/banner.png);
  }
  .safety-area {
    flex-shrink: 0;
    padding: 24px 28px;
    width: 550px;
    background: url(../../screen/buddha/image/border.png) center / 100% 100% no-repeat;
  }
  .safety-mid {
    flex: 1;
    padding: 0 30px;
  }
  .tourists-num {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-left: 12px;
    height: 86px;
    background: url(../../screen/safety/image/safety-rec-box.png) center / 100% 100% no-repeat;
  }
  .tourists-num-icon {
    flex-shrink: 0;
    margin-top: 8px;
    width: 45px;
    height: 43px;
    background: url(../../screen/safety/image/safety-rec-icon.png) center / 100% 100% no-repeat;
  }
  .tourists-num-name {
    flex-shrink: 0;
    margin-left: 10px;
    padding-top: 6px;
    width: 4em;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 24px;
  }
  .tourists-num-box {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .tourists-num-letter {
    width: 52px;
    height: 52px;
    background: url(../../screen/buddha/image/number-box.png) center / 100% 100% no-repeat;
    font-weight: 700;
    font-size: 36px;
    line-height: 52px;
    text-align: center;
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
  .tourists-num-item {
    padding: 20px 0 0 27px;
    width: 188px;
    height: 84px;
    text-align: left;
    &:first-child {
      background: url(../../screen/buddha/image/aprrove-back.png) center / 100% 100% no-repeat;
      .tourists-num-text {
        color: #23FFFC;
      }
    }
    &:last-child {
      margin-left: 45px;
      background: url(../../screen/buddha/image/dispatch-back.png) center / 100% 100% no-repeat;
      .tourists-num-text {
        color: #23B9FF;
      }
    }
  }
  .tourists-num-title {
    font-size: 14px;
    line-height: 17px;
  }
  .tourists-num-text {
    margin-top: 4px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }
  .tourists-num-unit {
    margin-left: .5em;
    font-size: 14px;
    font-weight: normal;
    color: #fff;
  }
  .tourists-chart-box {
    position: relative;
    margin-top: 20px;
    height: 220px;
  }
  .tourists-chart {
    height: 100%;
  }
  .tourists-chart-tab {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
  }
  .tourists-tab-item {
    margin-left: 12px;
    width: 56px;
    height: 23px;
    background: url(../../screen/safety/image/time-type-box.png) center / 100% 100% no-repeat;
    color: #6791FF;
    font-size: 12px;
    line-height: 23px;
    text-align: center;
    cursor: pointer;
    &.tourists-tab-active {
      background-image: url(../../screen/safety/image/time-type-select.png);
      color: #1DF4C7;
    }
  }
  .tourists-calendar-box {
    position: absolute;
    top: 32px;
    left: 0;
    right: 0;
    padding: 0 16px 16px;
    background: #041853;
    border: 1px solid #23B9FF;
    z-index: 1;
  }
  .tourists-chart-calendar {
    background: transparent;
  }
  .tourists-calendar-month {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px 5px;
    font-size: 12px;
  }
  .tourists-calendar-operate {
    display: flex;
    align-items: center;
  }
  .tourists-operate-btn {
    width: 40px;
    height: 20px;
    background: rgba(35, 185, 255, 0.1);
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    &.operate-btn-active {
      background: rgba(35, 185, 255, 0.5);
      color: #fff;
    }
  }
  .tourists-calendar-day {
    padding: 3px 0;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.3);
    font-weight: 400;
    line-height: 1;
    &.is-today {
      color: #23FFFC;
    }
  }
  .tourists-day-num {
    margin-top: 2px;
    color: #23FFFC;
    height: 12px;
  }
  .tourists-data-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(35, 185, 255, 0.1);
  }
  .safety-video {
    margin-top: 10px;
  }
  .safety-temple-video {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    padding: 0 10px;
    height: 152px;
  }
  .safety-video-box {
    width: 269px;
    border: 1px solid #23B9FF;
    border-radius: 8px;
    overflow: hidden;
  }
  .safety-video-player {
    width: 100%;
    height: 100%;
  }
  .safety-video-list {
    position: relative;
    width: 185px;
  }
  .safety-video-swiper {
    position: relative;
    height: 100%;
    cursor: move;
  }
  .safety-video-pointer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto 0;
    height: 28px;
    background-image: linear-gradient(180deg, rgba(2, 12, 84, 0) 0%, rgba(35, 185, 255, 0.46) 49.48%, rgba(3, 13, 86, 0) 100%);
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 0;
      height: 0;
      border: 6px solid transparent;
    }
    &::before {
      content: "";
      left: 16px;
      border-left-color: #00F5FF;
      border-left-width: 8px;
    }
    &::after {
      content: "";
      right: 16px;
      border-right-color: #00F5FF;
      border-right-width: 8px;
    }
  }
  .safety-video-carousel {
    position: relative;
  }
  .safety-video-item {
    height: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: rgba(255,255,255,0.7);
    &.safety-video-active {
      color: #00F5FF;
    }
  }
  .safety-video-error {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #161616;
  }
  .video-error-box {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 36px;
    background-color: #B6B6B6;
    border-radius: 6px;
    color: #161616;
    font-size: 20px;
  }
  .video-error-icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;
  }
  .video-error-text {
    margin-left: 12px;
  }
  .safety-events {
    margin-top: 12px;
  }
  .safety-events-table {
    margin-top: 10px;
    padding: 0 6px;
  }
  .safety-events-body {
    height: 188px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 2px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #72F0FF;
      border-radius: 5px;
    }
    .safety-events-td {
      position: relative;
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        color: #1CD1A1;
      }
      &:first-of-type::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto 0;
        width: 3px;
        height: 16px;
        background-color: #1CD1A1;
        border-radius: 3px;
      }
    }
  }
  .safety-events-tr {
    display: flex;
    align-items: center;
    height: 36px;
    background-color: rgba(35, 185, 255, 0.09);
    font-size: 14px;
    color: rgba(255,255,255,0.8);
    &.safety-events-head {
      background-color: rgba(14,66,216,0.36);
      font-size: 16px;
      line-height: 24px;
    }
    &.safety-events-margin {
      margin-top: 2px;
    }
  }
  .safety-events-td {
    flex: 1;
    &:nth-of-type(2) {
      flex-shrink: 0;
      min-width: 120px;
    }
    &:nth-of-type(3) {
      flex-shrink: 0;
      min-width: 96px;
    }
    &:nth-of-type(4) {
      flex: none;
      width: 118px;
    }
  }
  .safety-data {
    position: relative;
    padding-top: 16px;
  }
  .safety-warning {
    display: flex;
    align-items: center;
    position: absolute;
    top: -12px;
    left: 48px;
    right: 0;
    color: #03A9FF;
    font-size: 14px;
    line-height: 21px;
  }
  .safety-warning-icon {
    width: 12px;
    height: 12px;
    font-size: 12px;
    line-height: 12px;
  }
  .safety-warning-info {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 4px;
    min-width: 0;
  }
  .safety-warning-text {
    flex: 1;
    min-width: 0;
  }
  .safety-data-list {
    display: flex;
    flex-wrap: wrap;
    margin: -6px -8px;
  }
  .safety-data-item {
    padding: 6px 8px;
    width: 33.33333333%;
  }
  .safety-data-ctx {
    padding-top: 8px;
    height: 75px;
    box-shadow: inset 0px -4px 20px rgba(28, 209, 161, 0.2), inset 0px 4px 20px rgba(28, 209, 161, 0.2);
  }
  .safety-data-title {
    font-size: 18px;
    line-height: 27px;
  }
  .safety-data-num {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #00F5FF;
  }
  .safety-health {
    margin-top: 12px;
  }
  .safety-health-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.175em;
  }
  .safety-health-value {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
  }
  .health-value-icon {
    display: flex;
    align-items: center;
    width: 168px;
    height: 30px;
    &.health-value-before {
      justify-content: flex-end;
      background: linear-gradient(270deg, #36EFE4 4.85%, rgba(54, 239, 228, 0) 100.08%) left center / 158px 3px no-repeat;
      &::before {
        height: 16px;
      }
      &::after {
        margin-left: 4px;
        height: 100%;
      }
    }
    &.health-value-after {
      justify-content: flex-start;
      background: linear-gradient(90deg, #36EFE4 4.85%, rgba(54, 239, 228, 0) 100.08%) right center / 158px 3px no-repeat;
      &::before {
        height: 100%;
      }
      &::after {
        margin-left: 4px;
        height: 16px;
      }
    }
    &::before,
    &::after {
      content: "";
      display: block;
      width: 3px;
      background-color: #2FEEE4;
    }
  }
  .health-value-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 236px;
  }
  .health-text-num {
    width: 42px;
    height: 54px;
    background: linear-gradient(0deg, rgba(74, 108, 234, 0.4) 0%, rgba(156, 171, 255, 0.4) 100%);
    border: 2px solid rgba(112, 142, 255, .8);
    border-radius: 4px;
    font-size: 36px;
    line-height: 54px;
    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }
  .safety-types {
    margin-top: 16px;
  }
  .safety-type-list {
    position: relative;
    margin: 0 auto;
    width: 665px;
    height: 304px;
    background: url(../../screen/safety/image/safety-base.png) center / 549px 194px no-repeat;
  }
  .safety-type-item {
    position: absolute;
    width: 162px;
    height: 191px;
    background: url(../../screen/safety/image/safety-type-back.png) center / contain no-repeat;
    transform-origin: top left;
    transition: all .3s;
    cursor: pointer;
    &.safety-type-0 {
      top: 50px;
      left: 0;
      transform: scale(0.91358);
      z-index: 2;
    }
    &.safety-type-1 {
      top: 114px;
      left: 142px;
      z-index: 3;
    }
    &.safety-type-2 {
      top: 114px;
      left: 362px;
      z-index: 3;
    }
    &.safety-type-3 {
      top: 48px;
      left: 517px;
      transform: scale(0.91358);
      z-index: 2;
    }
    &.safety-type-4 {
      top: 2px;
      left: 431px;
      z-index: 1;
      transform: scale(0.69753);
    }
    &.safety-type-5 {
      top: 2px;
      left: 122px;
      z-index: 1;
      transform: scale(0.69753);
    }
    &.safety-type-value {
      top: 0;
      left: 252px;
    }
    &.safety-type-active {
      background-image: url(../../screen/safety/image/safety-type-select.png);
    }
  }
  .safety-type-name {
    margin-top: 30px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }
  .safety-notice-chart {
    margin-top: -36px;
    height: 160px;
  }
  .emergency-plan {
    margin-top: 4px;
  }
  .emergency-list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .emergency-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 78px;
    height: 145px;
    background: url(../../screen/safety/image/emergency-item.png) center / 100% 100% no-repeat;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
    &.emergency-active {
      background-image: url(../../screen/safety/image/emergency-item-select.png);
      color: #FF4800;
    }
  }
  .safety-car-chart {
    margin-top: 18px;
    height: 146px;
  }
  .safety-car-table {
    margin-top: 8px;
    padding: 0 8px;
  }
  .car-table-tr {
    display: flex;
    align-items: center;
    height: 36px;
    background-color: rgba(126, 139, 249, 0.1);
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    &.car-table-head {
      background-color: rgba(56, 128, 211, 0.4);
      font-size: 16px;
      color: #00F5FF;
    }
  }
  .safety-car-carousel {
    margin-top: 2px;
    height: 111px;
    .car-table-tr {
      margin-top: 1px;
    }
  }
  .car-table-td {
    flex: 1;
  }
  .public-opinion {
    margin-top: 12px;
  }
  .public-opinion-data {
    display: flex;
    align-items: center;
    margin-top: 14px;
    padding-left: 30px;
    height: 187px;
  }
  .public-opinion-words {
    flex: 1;
    height: 100%;
  }
  .public-opinion-item {
    white-space: nowrap;
  }
  .public-opinion-chart {
    width: 172px;
    height: 100%;
  }
  .safety-log {
    margin-top: 8px;
    padding: 0 18px;
  }
  .safety-log-carousel {
    margin-top: 14px;
  }
  .safety-log-tr {
    display: flex;
    align-items: center;
    margin-top: 4px;
    height: 36px;
    border-radius: 36px;
    background-color: rgba(30, 162, 230, 0.15);
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  .safety-log-td {
    flex: 1;
  }
  .safety-auth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 40px;
    height: 74px;
  }
  .safety-auth-title {
    font-size: 20px;
    background-image: linear-gradient(180deg, #FFF0E8 0%, #23B9FF 52.6%, #FFECE0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .safety-auth-btn {
    width: 121px;
    height: 28px;
    background-color: #23B9FF;
    box-shadow: 0 2px 20px rgba(35, 185, 255, 0.56);
    border-radius: 4px;
    font-size: 16px;
    line-height: 28px;
    color: #01073B;
    text-shadow: 0 2px 4px rgba(0, 8, 58, 0.53)
  }
  .hot-data {
    margin-top: 12px;
    padding: 0 10px 12px;
    background: rgba(30, 162, 230, 0.09);
  }
  .hot-data-top {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
  .hot-top-icon {
    margin: 0 32px;
    width: 48px;
    height: 48px;
    background: url(../../screen/safety/image/opinion.png) center / contain no-repeat;
  }
  .hot-top-view {
    flex: 1;
    display: flex;
    min-width: 0;
  }
  .top-view-item {
    flex: 1;
    text-align: left;
  }
  .top-view-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
  }
  .view-title-dot {
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
  .view-title-text {
    margin-left: 4px;
  }
  .top-view-num {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
  .hot-data-list {
    height: 116px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 2px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #72F0FF;
      border-radius: 5px;
    }
  }
  .hot-data-avatar {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background: url(../../screen/safety/image/avatar.png) center / cover no-repeat;
  }
  .hot-data-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 8px;
    background: #163777;
    height: 56px;
    &:not(:first-of-type) {
      margin-top: 4px;
    }
  }
  .hot-data-info {
    flex: 1;
    margin-left: 8px;
    min-width: 0;
    text-align: left;
  }
  .hot-data-author {
    font-size: 14px;
    color: #00F5FF;
    font-weight: 500;
    line-height: 21px;
  }
  .hot-data-text {
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
  }
  .hot-data-type {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2px;
  }
</style>
