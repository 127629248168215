<template>
    <div class="flex-box screen">
        <div class="top-banner"></div>
        <div class="oa-left">
            <div class="screen-piece">
                <div class="screen-piece-title">
                    <img src="../../screen/oa/image/kqtj-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">考勤统计</div>
                </div>
                <div class="kq-tj">
                    <div class="kq-book">
                        <div class="kq-book-circle">
                            <div class="book-circle-item kq-cir-sz">
                                <div class="book-circle-box">
                                    <div class="book-circle-out">
                                        <span class="book-circle-dot"></span>
                                        <span class="book-circle-dot"></span>
                                        <span class="book-circle-dot"></span>
                                        <span class="book-circle-dot"></span>
                                    </div>
                                    <div class="book-circle-inside">
                                        <div class="book-circle-text">僧众</div>
                                    </div>
                                </div>
                                <div class="book-circle-num">
                                    <div class="book-num-text">在寺{{member.sz[0]}}</div>
                                    <div class="book-num-text">应到{{member.sz[1]}}</div>
                                </div>
                            </div>
                            <div class="book-circle-item kq-cir-zhg">
                                <div class="book-circle-box">
                                    <div class="book-circle-out">
                                        <span class="book-circle-dot"></span>
                                        <span class="book-circle-dot"></span>
                                        <span class="book-circle-dot"></span>
                                        <span class="book-circle-dot"></span>
                                    </div>
                                    <div class="book-circle-inside">
                                        <div class="book-circle-text">职工</div>
                                    </div>
                                </div>
                                <div class="book-circle-num">
                                    <div class="book-num-text">到岗{{member.zhg[0]}}</div>
                                    <div class="book-num-text">应到{{member.zhg[1]}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="kq-detail">
                            <div class="kq-detail-row">
                                <div class="kq-detail-item">
                                    <div class="kq-detail-ti">事假</div>
                                    <div class="kq-detail-num"><span>{{member.att[0]}}</span>人</div>
                                </div>
                                <div class="kq-detail-item">
                                    <div class="kq-detail-ti">病假</div>
                                    <div class="kq-detail-num"><span>{{member.att[1]}}</span>人</div>
                                </div>
                            </div>
                            <div class="kq-detail-row">
                                <div class="kq-detail-item">
                                    <div class="kq-detail-ti">公假</div>
                                    <div class="kq-detail-num"><span>{{member.att[2]}}</span>人</div>
                                </div>
                                <div class="kq-detail-item">
                                    <div class="kq-detail-ti">外派</div>
                                    <div class="kq-detail-num"><span>{{member.att[3]}}</span>人</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="kq-zg">
                        <div class="book-circle-item kq-cir-zg">
                            <div class="book-circle-box">
                                <div class="book-circle-out">
                                    <span class="book-circle-dot"></span>
                                    <span class="book-circle-dot"></span>
                                    <span class="book-circle-dot"></span>
                                    <span class="book-circle-dot"></span>
                                </div>
                                <div class="book-circle-inside">
                                    <div class="book-circle-text">志工</div>
                                </div>
                            </div>
                            <div class="book-circle-num">
                                <div class="book-num-text">签到{{zg.sign}}</div>
                            </div>
                        </div>
                        <div class="zg-num-chart">
                            <div class="num-chart-ti">年龄/人数</div>
                            <div class="num-chart-area">
                                <div class="num-chart-item" v-for="(a, i) in zg.ageList" :key="i">
                                    <div class="num-bar-box">
                                        <div class="num-item-bar" :class="[a.cla]" :style="{height: `${a.per}%`}">
                                            <div>{{a.num}}</div>
                                            <div>人</div>
                                        </div>
                                    </div>
                                    <div class="num-item-text">{{a.title}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="zg-gender">
                            <div class="zg-gender-title">性别</div>
                            <div class="zg-gender-chart" id="zg_gender_chart"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="screen-piece">
                <div class="screen-piece-title">
                    <img src="../../screen/oa/image/tzgg-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">通知公告</div>
                </div>
                <div class="oa-notice">
                    <div class="oa-notice-title">
                        <div class="oa-notice-th">时间</div>
                        <div class="oa-notice-th">内容</div>
                        <div class="oa-notice-th">部门</div>
                    </div>
                    <div class="oa-notice-list">
                        <div class="oa-notice-item" :class="[`oa-notice-${i % 2}`]" v-for="(n, i) in noticeList" :key="i">
                            <div class="oa-notice-td">{{n.time}}</div>
                            <div class="oa-notice-td">{{n.content}}</div>
                            <div class="oa-notice-td">{{n.author}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="oa-mid">
            <div class="screen-piece">
                <div class="screen-piece-title">
                    <img src="../../screen/oa/image/ywtj-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">业务统计</div>
                </div>
                <div class="oa-business">
                    <div class="oa-business-item" v-for="(b, i) in business" :key="i">
                        <div class="oa-business-num">{{b.value}}</div>
                        <div class="oa-business-ti">{{b.title}}</div>
                    </div>
                </div>
            </div>
            <div class="screen-piece">
                <div class="screen-piece-title title-line-long">
                    <img src="../../screen/oa/image/swtj-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">事务处理统计</div>
                </div>
                <div class="oa-deal">
                    <div class="oa-deal-legend">
                        <div class="deal-legend-item">
                            <div class="deal-legend-ti">本月</div>
                            <div class="deal-legend-bar"></div>
                        </div>
                        <div class="deal-legend-item">
                            <div class="deal-legend-ti">上月</div>
                            <div class="deal-legend-bar"></div>
                        </div>
                    </div>
                    <div class="oa-deal-list">
                        <div class="oa-deal-item" v-for="(d, i) in dealList" :key="i">
                            <div class="oa-deal-title">{{d.name}}</div>
                            <div class="oa-deal-bar">
                                <div class="deal-bar-last" :style="{width: `${d.lp}%`}"></div>
                                <div class="deal-bar-current" :style="{width: `${d.cp}%`}"></div>
                                <div class="oa-deal-rank">{{d.rank}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="screen-piece">
                <div class="screen-piece-title">
                    <img src="../../screen/oa/image/syrl-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">寺院日历</div>
                </div>
                <calendar :border="false" class="oa-calendar" lunar>
                    <tr class="tr oa-calendar-tr" slot="week">
                        <th class="td"><div class="oa-calendar-day oa-calendar-0">日</div></th>
                        <th class="td"><div class="oa-calendar-day oa-calendar-0">一</div></th>
                        <th class="td"><div class="oa-calendar-day oa-calendar-0">二</div></th>
                        <th class="td"><div class="oa-calendar-day oa-calendar-0">三</div></th>
                        <th class="td"><div class="oa-calendar-day oa-calendar-0">四</div></th>
                        <th class="td"><div class="oa-calendar-day oa-calendar-0">五</div></th>
                        <th class="td"><div class="oa-calendar-day oa-calendar-0">六</div></th>
                    </tr>
                    <template v-slot:month="{month}">
                        <div class="oa-calendar-month">{{new Date(month).getMonth() + 1}}月</div>
                    </template>
                    <template v-slot:full="{day, index}">
                        <div class="oa-calendar-day" :class="[`oa-calendar-${(index + 1) % 2}`, day.inMonth ? '' : 'oa-out-month']">
                            <div class="oa-calendar-date">{{day.day}}</div>
                            <div class="oa-calendar-lunar">{{schMap[day.dateStr] ? schMap[day.dateStr] : day.lunar.lnongDate}}</div>
                        </div>
                    </template>
                </calendar>
            </div>
        </div>
        <div class="oa-right">
            <div class="screen-piece">
                <div class="screen-piece-title">
                    <img src="../../screen/oa/image/jhtj-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">大型活动</div>
                </div>
                <div class="oa-plan">
                    <div class="oa-plan-head">
                        <div class="oa-plan-span">活动名称</div>
                        <div class="oa-plan-span">活动时间</div>
                        <div class="oa-plan-span">状态</div>
                    </div>
                    <div class="oa-plan-item" v-for="(p, i) in planList" :key="i">
                        <div class="oa-plan-span ellipsis">{{p.name}}</div>
                        <div class="oa-plan-span ellipsis">{{p.time}}</div>
                        <div class="oa-plan-span ellipsis">{{p.statusText}}</div>
                    </div>
                </div>
            </div>
            <div class="screen-piece">
                <div class="screen-piece-title">
                    <img src="../../screen/oa/image/zytj-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">资源统计</div>
                </div>
                <div class="oa-assets">
                    <div class="oa-room-list">
                        <div class="oa-room-item" :class="{'oa-room-select': i === ri}" @click="ri = i" v-for="(r, i) in roomList" :key="i">{{r.name}}</div>
                    </div>
                    <div class="oa-room-reserve">
                        <div class="room-reserve-week">
                            <div class="reserve-week-item">周一</div>
                            <div class="reserve-week-item">周二</div>
                            <div class="reserve-week-item">周三</div>
                            <div class="reserve-week-item">周四</div>
                            <div class="reserve-week-item">周五</div>
                            <div class="reserve-week-item">周六</div>
                            <div class="reserve-week-item">周日</div>
                        </div>
                        <div class="room-reserve-detail">
                            <div class="room-reserve-item">
                                <div class="room-reserve-time">上午</div>
                                <div class="reserve-day-list" v-if="roomList[ri]">
                                    <div class="reserve-day-item" :class="{'day-reserved': item.am == 1}" v-for="(item, i) in roomList[ri].reserve" :key="i">{{item.am == 1 ? '已预约' : '空闲'}}</div>
                                </div>
                            </div>
                            <div class="room-reserve-item">
                                <div class="room-reserve-time">下午</div>
                                <div class="reserve-day-list" v-if="roomList[ri]">
                                    <div class="reserve-day-item" :class="{'day-reserved': item.pm == 1}" v-for="(item, i) in roomList[ri].reserve" :key="i">{{item.pm == 1 ? '已预约' : '空闲'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="screen-piece">
                <div class="screen-piece-title">
                    <img src="../../screen/oa/image/clbb-icon.png" class="piece-title-icon"/>
                    <div class="piece-title-text">车辆报备</div>
                </div>
                <div class="oa-notice">
                    <div class="oa-notice-title">
                        <div class="oa-notice-th">时间</div>
                        <div class="oa-notice-th">车牌号</div>
                        <div class="oa-notice-th">门岗</div>
                    </div>
                    <div class="oa-notice-list">
                        <div class="oa-notice-item" :class="[`oa-notice-${i % 2}`]" v-for="(n, i) in carList" :key="i">
                            <div class="oa-notice-td">{{n.carAccessTime}}</div>
                            <div class="oa-notice-td">{{n.carNumber}}</div>
                            <div class="oa-notice-td">{{n.gate}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import calendar from "../../components/calendar/calendar";
    import zg from "../../screen/oa/zg";
    import {getDate} from "../../common/js/tool";

    export default {
        name: "OA",
        components: {
            calendar
        },
        props: {
            data: Object
        },
        data() {
            return {
                member: {
                    sz: [0,0],
                    zhg: [0,0],
                    att: [0,0,0,0]
                },
                zg: {
                    sign: 225,
                    ageList: [
                        {title: '20-30', num: 80, cla: 'num-bar-0'},
                        {title: '30-40', num: 56, cla: 'num-bar-1'},
                        {title: '40-50', num: 24, cla: 'num-bar-2'},
                        {title: '50-60', num: 52, cla: 'num-bar-0'},
                        {title: '60-70', num: 23, cla: 'num-bar-1'},
                        {title: '>70', num: 12, cla: 'num-bar-2'},
                    ]
                },
                noticeList: [],
                business: [
                    {title: '审批中', value: 0},
                    {title: '今日提交', value: 0},
                    {title: '今日完成', value: 0},
                    {title: '累计完成', value: 0},
                ],
                dealList: [
                    // {name: '接待', last: 16, current: 125, rank: '0/0'},
                    // {name: '上香', last: 88, current: 89, rank: '0/0'},
                    // {name: '用餐', last: 106, current: 72, rank: '0/0'},
                    // {name: '文宣', last: 39, current: 46, rank: '0/4'},
                    // {name: '会议室', last: 65, current: 25, rank: '0/0'},
                ],
                planList: [],
                ri: 0,
                roomList: [],
                schMap: {},
                carList: []
            }
        },
        watch: {
            data() {
                this.setData();
            }
        },
        created() {
            this.setData();
        },
        mounted() {
            this.initChart();
        },
        methods: {
            setData() {
                let data = this.data;
                if(data) {
                    this.setKqData();
                    this.setBusinessData();
                    this.setDealData();
                    this.setCarData();
                    this.setNoticeData();
                    this.setCalendar();
                    this.setRoomData();
                    const planList = this.data.dzhd;
                    if(planList) {
                        const now = new Date().getTime();
                        planList.forEach(item => {
                            let res = '';
                            const {start_time, end_time} = item;
                            if(start_time && end_time) {
                                const start = getDate(start_time), end = getDate(end_time);
                                const st = start.getTime(), et = end.getTime();
                                if(now < st) {
                                    res = '未开始';
                                } else if(now <= et) {
                                    res = '进行中'
                                } else {
                                    res = '已结束'
                                }
                                item.time = `${start.pattern("yyyy/M/d")}-${end.pattern("yyyy/M/d")}`
                            }
                            item.statusText = res;
                        })
                        this.planList = planList;
                    }
                }
            },
            setKqData() {
                let data = this.data;
                let tj = data.kqtjxz;
                let member = this.member;
                const sz = tj[0].count;
                const zhg = tj[1].count;
                if(sz) {
                    member.sz[0] =  sz[1] || 0;
                    member.sz[1] =  sz[0] || 0;
                }
                if(zhg) {
                    member.zhg[0] = zhg[1] || 0;
                    member.zhg[1] = zhg[0] || 0;
                }
                let att = [...data.qjData];
                // 外派人数
                att.push((sz[3] || 0) + (zhg[3] || 0));
                member.att = att;
                this.member = member;
                let zg = this.zg;
                let list = [...zg.ageList];
                list.sort((a, b) => b.num - a.num);
                let max = list[0].num;
                zg.ageList.forEach(item => {
                    item.per = Math.round(item.num / max * 10000) / 100;
                });
                this.$set(zg, "ageList", zg.ageList);
                this.$set(zg, "sign", data.zg.total);
            },
            setDealData() {
                const data = this.data;
                if(data.hhgz_ywtj && data.hhgz_ywtj.list) {
                    const dealList = data.hhgz_ywtj.list.map((item, index) => {
                        return {
                            name: item.name,
                            last: item.lm_count * 1 || 0,
                            current: item.count * 1 || 0,
                            rank: `${item.lm_rank}/${index + 1}`
                        };
                    });
                    const list = [...dealList];
                    list.sort((a, b) => b.current - a.current);
                    let cm = list[0].current;
                    list.sort((a, b) => b.last - a.last);
                    let lm = list[0].last;
                    if(lm > cm) {
                        cm = lm;
                    }
                    dealList.forEach(item => {
                        item.lp = Math.round(item.last / cm * 10000) / 100;
                        item.cp = Math.round(item.current / cm * 10000) / 100;
                    });
                    this.dealList = dealList;
                }
            },
            setBusinessData() {
                const business = [...this.business];
                const data = this.data;
                const sp = data.sp;
                if(data.sp) {
                    business[0].value = sp.wsp || 0;
                    business[3].value = sp.ysp || 0;
                }
                if(data.hhgz_ywtj && data.hhgz_ywtj.sp_count) {
                    const count = data.hhgz_ywtj.sp_count;
                    business[1].value = count[0] * 1 || 0;
                    business[2].value = count[1] * 1 || 0;
                }
                this.business = business;
            },
            setCarData() {
                const data = this.data.tcjl;
                if(data && data.record) {
                    const list = data.record.slice(0, 5);
                    list.forEach(item => {
                        item.gate = ['第一道岗', '第二道岗', '第三道岗'][item.position - 1];
                    })
                    this.carList = list;
                }
            },
            setNoticeData() {
                let list = [...this.data.tzgg];
                list.reverse();
                this.noticeList = list.slice(0, 5).map(item => {
                    let time = getDate(item.create_time).pattern("yyyy-M-d H:mm");
                    return {
                        time,
                        content: item.title,
                        author: item.author
                    }
                })
            },
            setCalendar() {
                let list = this.data.calData;
                let schMap = this.schMap;
                if(list && list.length > 0) {
                    list.forEach(sch => {
                        if(sch.status == 0) {
                            let start = new Date(sch.start_time * 1000).pattern("yyyy-MM-dd");
                            schMap[start] = sch.summary;
                        }
                    });
                }
            },
            setRoomData() {
                let list = this.data.zytj;
                // {name: '联灯阁', reserve: [{am: 1, pm: 0}, {am: 0, pm: 0}, {am: 1, pm: 1}, {am: 0, pm: 0}, {am: 0, pm: 1}, {am: 1, pm: 0}, {am: 0, pm: 0}]}
                let date = new Date();
                let day = date.getDay();
                date.setDate(date.getDate() - (day == 0 ? 6 : day - 1));
                let days = [];
                let i = 0;
                while (i < 7) {
                    days.push(date.pattern("yyyy-MM-dd"));
                    date.setDate(date.getDate() + 1);
                    i ++;
                }
                this.roomList = list.map(item => {
                    let rd = item.date;
                    let reserve = days.map(day => {
                        let temp = {am: 0, pm: 0};
                        if(rd == day) {
                            let start = getDate(item.begin_time);
                            let end = getDate(item.end_time);
                            if(start && end) {
                                let sh = start.getHours();
                                let eh = end.getHours();
                                if(eh >= 12) {
                                    temp.pm = 1;
                                }
                                if(sh < 12) {
                                    temp.am = 1;
                                }
                            }
                        }
                        return temp;
                    });
                    return {
                        name: item.name,
                        reserve,
                    }
                })
            },
            initChart() {
                const dom = document.getElementById('zg_gender_chart');
                this.zgChart = echarts.init(dom);
                this.zgChart.setOption(zg);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../screen/screen";
    .screen {
        padding: 90px 16px 16px;
    }
    .top-banner {
        background-image: url(../../screen/oa/image/banner.png);
    }
    .oa-left,
    .oa-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 20px;
        width: 614px;
        background: url(../../screen/oa/image/border.png) center no-repeat;
        background-size: 100% 100%;
    }
    .oa-mid {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 20px;
    }
    .kq-tj {
        padding: 32px 24px;
    }
    .kq-book {
        display: flex;
        align-items: center;
    }
    .kq-book-circle {
        display: flex;
    }
    .book-circle-item {
        width: 68px;
        &.kq-cir-sz {
            .book-circle-out {
                border-color: #37ffea;
            }
            .book-circle-dot {
                background-color: #37ffea;
            }
            .book-circle-inside {
                background-color: rgba(55,255,234,.1);
            }
            .book-circle-num {
                background-image: linear-gradient(0, rgba(55,255,234,.4), rgba(55,255,234,0));
                border-color: #37ffea;
            }
        }
        &.kq-cir-zhg {
            margin-left: 32px;
            .book-circle-out {
                border-color: #ff9c0c;
            }
            .book-circle-dot {
                background-color: #ff9c0c;
            }
            .book-circle-inside {
                background-color: rgba(255,156,12,.1);
            }
            .book-circle-num {
                background-image: linear-gradient(0, rgba(255,156,12,.4), rgba(255,156,12,0));
                border-color: #ffe57c;
            }
        }
        &.kq-cir-zg {
            .book-circle-out {
                border-color: #37d3ff;
            }
            .book-circle-dot {
                background-color: #37d3ff;
            }
            .book-circle-inside {
                background-color: rgba(55,211,255,.1);
            }
            .book-circle-num {
                background-image: linear-gradient(0, rgba(55,211,255,.4), rgba(55,211,255,0));
                border-color: #37d3ff;
            }
        }
    }
    .book-circle-box {
        position: relative;
        height: 68px;
    }
    .book-circle-out {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 100%;
        border: 1px solid;
        animation: circle linear 4s infinite;
    }
    .book-circle-dot {
        display: block;
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        &:first-child {
            top: -3px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        &:nth-of-type(2) {
            top: 0;
            right: -3px;
            bottom: 0;
            margin: auto 0;
        }
        &:nth-of-type(3) {
            left: 0;
            right: 0;
            bottom: -3px;
            margin: 0 auto;
        }
        &:nth-of-type(4) {
            top: 0;
            left: -3px;
            bottom: 0;
            margin: auto 0;
        }
    }
    .book-circle-inside {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 6px;
        left: 6px;
        right: 6px;
        bottom: 6px;
        border-radius: 100%;
    }
    .book-circle-text {
        font-size: 16px;
        font-weight: bold;
    }
    .book-circle-num {
        padding: 12px 0 4px;
        border-bottom: 1px solid;
        text-align: center;
        font-size: 12px;
    }
    .book-num-text {
        &:not(:first-child) {
            margin-top: 4px;
        }
    }
    .kq-detail {
        flex: 1;
        margin-left: 32px;
    }
    .kq-detail-row {
        display: flex;
        &:not(:first-child) {
            margin-top: 16px;
        }
    }
    .kq-detail-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 61px;
        background: url(../../screen/oa/image/kq-item-box.png) center no-repeat;
        background-size: 100% 100%;
        &:not(:first-child) {
            margin-left: 16px;
        }
    }
    .kq-detail-ti {
        font-size: 15px;
    }
    .kq-detail-num {
        margin-top: 8px;
        font-size: 12px;
        span {
            font-size: 18px;
            font-weight: bold;
        }
    }
    .kq-zg {
        display: flex;
        align-items: center;
        margin-top: 60px;
    }
    .zg-num-chart {
        margin-left: 40px;
        width: 206px;
    }
    .num-chart-area {
        display: flex;
        margin-top: 10px;
        font-size: 12px;
    }
    .num-chart-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:not(:first-child) {
            margin-left: 4px;
        }
    }
    .num-bar-box {
        position: relative;
        width: 18px;
        height: 142px;
        text-align: center;
    }
    .num-item-bar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 2px;
        &.num-bar-0 {
            background-image: linear-gradient(0,rgba(0,221,235,0),#008ad9);
        }
        &.num-bar-1 {
            background-image: linear-gradient(0,rgba(1,255,246,0),#01fff6);
        }
        &.num-bar-2 {
            background-image: linear-gradient(0,rgba(196,150,255,0),#c696ff);
        }
    }
    .num-item-text {
        margin-top: 12px;
        color: #b8d3f1;
    }
    .zg-gender {
        flex: 1;
        position: relative;
        height: 190px;
    }
    .zg-gender-title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        line-height: 190px;
        text-align: center;
        font-size: 16px;
    }
    .zg-gender-chart {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .oa-notice {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 36px;
        height: 330px;
        text-align: center;
        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url(../../screen/oa/image/table.png) center no-repeat;
            background-size: 100% 100%;
        }
    }
    .oa-notice-title {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        height: 55px;
        background-color: rgba(56,128,211,.5);
        color: #92fcfd;
        font-weight: bold;
    }
    .oa-notice-th {
        flex: 1;
    }
    .oa-notice-list {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .oa-notice-item {
        flex: 1;
        display: flex;
        align-items: center;
        &.oa-notice-0 {
            background-color: rgba(56,128,211,.25);
        }
        &.oa-notice-1 {
            background-color: rgba(56,128,211,.1);
        }
    }
    .oa-notice-td {
        flex: 1;
        font-weight: 600;
    }
    .oa-business {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        padding: 30px 20px;
    }
    .oa-business-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 126px;
        height: 106px;
        background: url(../../screen/oa/image/cylinder.png) center no-repeat;
        background-size: 100% 100%;
        &:not(:first-child) {
            margin-left: 24px;
        }
    }
    .oa-business-num {
        font-size: 32px;
        color: #88f5ff;
        font-weight: 600;
    }
    .oa-business-ti{
        margin-top: 40px;
        font-size: 16px;
    }
    .oa-deal-legend {
        display: flex;
        justify-content: flex-end;
    }
    .deal-legend-item {
        display: flex;
        align-items: center;
        font-size: 12px;
        &:first-child {
            .deal-legend-bar {
                background-image: linear-gradient(90deg, #021b54, #2fd8a8);
            }
        }
        &:last-child {
            margin-left: 18px;
            .deal-legend-bar {
                background-image: linear-gradient(90deg, #021e56, #ffb166);
            }
        }
    }
    .deal-legend-bar {
        margin-left: 10px;
        width: 66px;
        height: 12px;
        border-radius: 12px;
    }
    .oa-deal-list {
        padding: 16px 0;
    }
    .oa-deal-item {
        display: flex;
        align-items: center;
        margin-top: 20px;
        font-size: 12px;
    }
    .oa-deal-title {
        width: 48px;
    }
    .oa-deal-bar {
        flex: 1;
        position: relative;
        padding-right: 48px;
        height: 14px;
        border-radius: 14px;
        background-color: #858585;
    }
    .deal-bar-current,
    .deal-bar-last {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 14px;
    }
    .deal-bar-last{
        background-image: linear-gradient(90deg, #021e56, #ffb166);
    }
    .deal-bar-current {
        background-image: linear-gradient(90deg, #021b54, #2fd8a8);
    }
    .oa-deal-rank {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 24px;
    }
    .oa-calendar {
        margin-top: 36px;
        position: relative;
        padding: 0 12px;
        background-color: transparent;
        color: #f8f09e;
        font-weight: 600;
        font-size: 12px;
        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -4px;
            margin: 0 auto;
            width: 300px;
            border: 12px solid transparent;
            border-bottom: 4px solid #51b2e8;
        }
    }
    .oa-calendar-month {
        height: 40px;
        background-color: rgba(56,128,211,.5);
        font-size: 24px;
        line-height: 40px;
        color: #fff;
        text-align: center;
    }
    .oa-calendar-day {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 48px;
        &.oa-out-month {
            .oa-calendar-date {
                color: rgba(248,240,158,.4);
            }
            .oa-calendar-lunar {
                color: rgba(255,255,255,.4);
            }
        }
    }
    .oa-calendar-tr {
        .td {
            color: #92fcfd;
            font-size: 20px;
        }
    }
    .oa-calendar-0 {
        background-color: rgba(56,128,211,.25);
    }
    .oa-calendar-1 {
        background-color: rgba(56,128,211,.1);
    }
    .oa-calendar-lunar {
        margin-top: 10px;
        color: #fff;
    }
    .oa-plan {
        padding: 12px 16px;
    }
    .oa-plan-item,
    .oa-plan-head {
        display: flex;
        align-items: center;
    }
    .oa-plan-head {
        font-size: 15px;
    }
    .oa-plan-item {
        margin-top: 12px;
        height: 48px;
        background: url(../../screen/oa/image/plan-border.png) center no-repeat;
        background-size: 100% 100%;
    }
    .oa-plan-span {
        flex: 1;
        text-align: center;
        padding: 0 4px;
    }
    .oa-assets {
        margin-top: 18px;
    }
    .oa-room-item {
        display: inline-block;
        padding: 0 8px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
        &.oa-room-select {
            background: url(../../screen/oa/image/room-select.png) center no-repeat;
            background-size: 100% 100%;
        }
    }
    .oa-room-reserve {
        padding-left: 20px;
        font-size: 12px;
    }
    .room-reserve-week {
        display: flex;
        align-items: center;
        margin-left: 32px;
        padding: 8px 0;
    }
    .reserve-week-item {
        flex: 1;
        text-align: center;
    }
    .room-reserve-item {
        display: flex;
        align-items: center;
    }
    .room-reserve-time {
        width: 32px;
        text-align: center;
    }
    .reserve-day-list {
        flex: 1;
        display: flex;
    }
    .reserve-day-item {
        flex: 1;
        height: 74px;
        border: 1px solid #a78aff;
        background-image: linear-gradient(0, #211e6a, #c192fa);
        line-height: 72px;
        text-align: center;
        &.day-reserved {
            background-image: linear-gradient(0, #022563, #01fef5);
            border-color: #01fef5;
            line-height: 72px;
        }
    }
    @keyframes circle {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
